import { useEffect, useState } from "react"

import $ from 'jquery';
import ChargingStationConfig from "./chargingstations/ChargingStationConfig";
import DeviceConfigForm from "../../../../components/forms/deviceConfig/DeviceConfigForm";
import UnderConstruction from "../../../../components/UnderConstruction/UnderConstruction";
import { addNotification } from '../../../../utils/addNotification'

function DeviceConfig(props) {
    let [editState, setEditState] = useState(props.edit)
    try {
        useEffect(() => {
            try {
                if (editState == false) {
                    $('.deviceConfigContainer input').prop('disabled', true).addClass("disabledInput");
                    $('.deviceConfigContainer textArea').prop('disabled', true).addClass("disabledInput");
                    $('.deviceConfigContainer select').prop('disabled', true).addClass("disabledInput");
                }
                else {
                    $('.deviceConfigContainer input').prop('disabled', false).removeClass("disabledInput");
                    $('.deviceConfigContainer textArea').prop('disabled', false).removeClass("disabledInput")
                    $('.deviceConfigContainer select').prop('disabled', false).removeClass("disabledInput");
                }
            } catch (error) {
                addNotification(error);
            }
        }, [editState])
        if (props.device.deviceType === 'ChargingStation') {
            return (<ChargingStationConfig device={props.device} asset={props.asset} edit={props.edit} setAsset={props.setAsset} />)
        }
        else {
            return (
                <UnderConstruction />
            )
        }
    } catch (error) {
        addNotification(error);
    }
}

export default DeviceConfig