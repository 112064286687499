import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { API_SessionByDuration } from '../../../../api/device';
import HC_Drilldown from 'highcharts/modules/drilldown';
import HC_export_data from "highcharts/modules/export-data";
import HC_exporting from 'highcharts/modules/exporting'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from '../../../loader/Loader';
import highchartsMore from 'highcharts/highcharts-more'
import { addNotification } from '../../../../utils/addNotification';

const ChargeSessionsByDuration = forwardRef((props, ref) => {
    function secondToString(seconds) {
        try {
            var sec_num = parseInt(seconds, 10); // don't forget the second param
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            if (seconds < 10) { seconds = "0" + seconds; }
            return hours + 'H ' + minutes + 'M';
        } catch (error) {
            addNotification(error);
        }
    }
    let opt = {
        chart: {
            zoomType: 'xy',
            backgroundColor: '#e2e2e200',
            plotBorderWidth: 0,
            reflow: true,
            type: 'column',
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbolFill: "#989898",
                    theme: {
                        fill: "#e2e2e200",
                        padding: 5,
                        stroke: "none",
                    },
                    symbol: 'menu',
                    menuItems: [
                        "viewFullscreen",
                        "printChart", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG",
                        {
                            text: "Download CSV File",
                            onclick() {
                                let csv = this.getCSV();
                                const blob = new Blob([csv], { type: 'text/csv' });

                                // Creating an object for downloading url
                                const url = window.URL.createObjectURL(blob)

                                // Creating an anchor(a) tag of HTML
                                const a = document.createElement('a')

                                // Passing the blob downloading url 
                                a.setAttribute('href', url)

                                // Setting the anchor tag attribute for downloading
                                // and passing the download file name
                                a.setAttribute('download', 'download.csv');

                                // Performing a download with click
                                a.click()
                            }
                        },
                    ],
                }
            },
            y: -200
        },
        title: {
            align: 'center',
            text: ' '
        },
        subtitle: {
            align: 'center',
            text: 'Charge Sessions categorized by usage, with a drill down feature to the individual dates'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: 'Number of Sessions'
            }

        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    format: '{point.y:.1f}'
                }
            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormatter: function () {
                if (this.series.name === 'Usage Type') {
                    return `<span style='color: ` + this.color + ` >Total ` + this.name + ` charging sessions</span>: <b> ` + this.y + `</b>`
                }
                else {
                    return '<span style="color: ' + this.color + ' >Charging Session Duration at ' + this.name + '</span>: <b>' + secondToString(this.y) + ' seconds </b>'
                }
            }
        },

        series: [
            {
                name: 'Usage Type',
                colorByPoint: true,
                data: []
            }
        ],
        drilldown: {
            breadcrumbs: {
                position: {
                    align: 'right'
                }
            },
            series: [],
            labels: {
                enabled: false
            }
        },
        credits: {
            enabled: false,
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        floating: false,
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom',
                        x: 0,
                        y: 0
                    },
                    yAxis: [{
                        labels: {
                            align: 'right',
                            x: 0,
                            y: -6
                        },
                        showLastLabel: false
                    }, {
                        labels: {
                            align: 'left',
                            x: 0,
                            y: -6
                        },
                        showLastLabel: false
                    }, {
                        visible: false
                    }]
                }
            }]
        }
    };
    let [seriesData, setSeriesData] = useState(null)
    let [options, setOptions] = useState(null);
    let [isLoading, setIsLoading] = useState(true);
    let chartComponent = useRef();
    useImperativeHandle(
        ref,
        () => ({
            resizeChart() {
                try {
                    chartComponent.current.chart.reflow();
                } catch (error) {
                    addNotification(error);
                }
            }
        }),
    )
    useEffect(() => {
        try {
            if (props.asset?._id) {
                API_SessionByDuration({ chargingStation: props.asset._id }).then(d => {
                    try {
                        console.log(d);
                        let seriesData = [
                            {
                                name: 'Light',
                                y: Object.keys(d.usageByDuration.light).length,
                                drilldown: 'Light'
                            },
                            {
                                name: 'Moderate',
                                y: Object.keys(d.usageByDuration.moderate).length,
                                drilldown: 'Moderate'
                            },
                            {
                                name: 'Heavy',
                                y: Object.keys(d.usageByDuration.heavy).length,
                                drilldown: 'Heavy'
                            }
                        ]
                        let lightDrillDown = d.usageByDuration.light.map(e => {
                            let time = new Date(e[0] * 1000)
                            return [time.toLocaleDateString(), e[1]]
                        })
                        let moderateDrillDown = d.usageByDuration.moderate.map(e => {
                            let time = new Date(e[0] * 1000)
                            return [time.toLocaleDateString(), e[1]]
                        })
                        let heavyDrillDown = d.usageByDuration.heavy.map(e => {
                            let time = new Date(e[0] * 1000)
                            return [time.toLocaleDateString(), e[1]]
                        })
                        let drilldownSeriesData = [
                            {
                                name: 'Light',
                                id: 'Light',
                                type: "column",
                                stacking: 'normal',
                                colorByPoint: true,
                                data: lightDrillDown,
                            },
                            {
                                name: 'Moderate',
                                id: 'Moderate',
                                type: "column",
                                stacking: 'normal',
                                colorByPoint: true,
                                data: moderateDrillDown,
                            },
                            {
                                name: 'Heavy',
                                id: 'Heavy',
                                type: "column",
                                stacking: 'normal',
                                colorByPoint: true,
                                data: heavyDrillDown,
                            }
                        ]
                        let _opt = opt;
                        _opt.series[0].data = seriesData;
                        _opt.drilldown.series = drilldownSeriesData;
                        setOptions(_opt);
                    } catch (error) {
                        addNotification(error);
                    }
                }).catch(error => { addNotification(error); });
            }
        } catch (error) {
            addNotification(error);
        }
    }, [props.asset])
    HC_exporting(Highcharts);
    HC_export_data(Highcharts);
    if (!Highcharts.Chart.prototype.addSeriesAsDrilldown) {
        HC_Drilldown(Highcharts);
    }
    return (
        <div id="barChart" className='dashboardChartContainer'>
            {
                (options) ? <HighchartsReact highcharts={highchartsMore(Highcharts)} ref={chartComponent} options={options} containerProps={{ className: 'chart', style: { height: props.height } }} />
                    : <Loader />
            }
        </div>
    )
});

export default ChargeSessionsByDuration;