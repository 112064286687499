import RGL, { WidthProvider } from 'react-grid-layout';
import { useEffect, useRef, useState } from "react";

import $ from 'jquery'
import { API_GetDeviceMetrics } from '../../../../../api/device';
import ChargeSessionsByDuration from '../../../../../components/charts/chargingStation/Dashboard/ChargeSessionByDuration';
import HourlyUsageStats from "../../../../../components/charts/chargingStation/HourlyUsageStats";
import Loader from '../../../../../components/loader/Loader';
import MonthByMonthRevenue from '../../../../../components/charts/chargingStation/Dashboard/MonthByMonthRevenue';
import _ from "lodash";
import { addNotification } from '../../../../../utils/addNotification';

const GridLayout = WidthProvider(RGL);

function ChargingStationDashboard(props) {
    const hourlyConsumptionRef = useRef();
    const stationUsageRef = useRef();
    const monthlyRevenueRef = useRef();
    props = {
        className: "layout",
        items: 6,
        rowHeight: 85,
        onLayoutChange: function () { },
        cols: 12,
        ...props
    }
    let generateLayout = () => {
        try {
            const p = props
            return _.map(new Array(p.items), function (item, i) {
                const y = _.result(p, "y") || Math.ceil(Math.random() * 4) + 1;
                let layout = {
                    x: (i * 2) % 12,
                    y: 0,
                    w: 6,
                    h: 4,
                    i: i.toString()
                };
                return layout
            });
        } catch (error) {
            addNotification(error);
        }
    }
    function secondToString(seconds) {
        try {
            var sec_num = parseInt(seconds, 10); // don't forget the second param
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            if (seconds < 10) { seconds = "0" + seconds; }
            return hours + 'H ' + minutes + 'M';
        } catch (error) {
            addNotification(error);
        }
    }
    function calculateAverage(array) {
        try {
            var total = 0;
            var count = 0;

            array.forEach(function (item, index) {
                total += item;
                count++;
            });

            return total / count;
        } catch (error) {
            addNotification(error);
        }
    }
    const [layout, setLayout] = useState(generateLayout());
    const [metrics, setMetrics] = useState({ averageChargeTime: 0, totalEnergyConsumed: 0, totalChargeSessions: 0, utilizationRates: 0 });
    let onLayoutChange = (layout) => {
        try {
            props.onLayoutChange(layout);
        } catch (error) {
            addNotification(error);
        }
    }
    let handleResize = () => {
        try {
            stationUsageRef.current.resizeChart();
            monthlyRevenueRef.current.resizeChart();
            hourlyConsumptionRef.current.resizeChart();
        } catch (error) {
            addNotification(error);
        }
    }
    useEffect(() => {
        try {
            let today = new Date();
            var lastWeek = new Date();
            var weekInMilliseconds = 30 * 24 * 60 * 60 * 1000;
            lastWeek.setTime(lastWeek.getTime() - weekInMilliseconds);

            let day = today.getDate();
            let month = today.getMonth() + 1;
            let year = today.getFullYear();
            let _lastWeekDay = lastWeek.getDate();
            let _lastWeekMonth = lastWeek.getMonth() + 1;
            let _lastWeekYear = lastWeek.getFullYear();
            let obj = {
                chargingStation: props?.asset?._id,
            }
            API_GetDeviceMetrics(obj).then(d => {
                try {
                    setMetrics(d)
                } catch (error) {
                    addNotification(error);
                }
            }).catch(error => {
                addNotification(error);
            });
        } catch (error) {
            addNotification(error);
        }
    }, [])
    if (props.device && props.asset) {
        return (
            <div className='detailAnalyticsContainer'>
                <div id="dashboardMetrics" className="dashboardMetricsContainer">
                    <div className="metric">
                        <i className="fa-solid fa-bullseye" style={{ color: "green" }}></i>
                        <div>
                            <span id="bl" style={{ color: "green" }}>{(metrics?.totalEnergyConsumed) || 'Unavailable'}</span>
                            <h1>Total Consumption (kW)</h1>
                        </div>
                    </div>
                    <div className="metric">
                        <i className="fa-solid fa-money-check-dollar" style={{ color: "green" }}></i>
                        <div>
                            <span id="rul" style={{ color: "green" }}>₹{((metrics?.totalRevenue || 0)).toFixed(2)}</span>
                            <h1>Total Revenue(₹)</h1>
                        </div>
                    </div>
                    <div className="metric">
                        <i className="fa-solid fa-bullseye" style={{ color: "green" }}></i>
                        <div>
                            <span id="bl" style={{ color: "green", fontSize: '15px' }}>{props?.asset?.statusNotification || 'Unavailable'}</span>
                            <h1>Station Status</h1>
                        </div>
                    </div>
                    <div className="metric">
                        <i className="fa-solid fa-bolt"></i>
                        <div>
                            <span id="bl" style={{ color: "#e08357" }}>{calculateAverage(Object.values(metrics?.utilizationRates || 0)).toFixed(2)}</span>
                            <h1>Utilization Rate(%)</h1>
                        </div>
                    </div>
                    <div className="metric">
                        <i className="fa-solid fa-network-wired" style={{ color: "#1783bb" }}></i>
                        <div>
                            <span id="bl" style={{ color: "#1783bb" }}>{metrics?.totalChargeSessions || 0}</span>
                            <h1>Charge Sessions</h1>
                        </div>
                    </div>
                    <div className="metric">
                        <i className="fa-solid fa-clock"></i>
                        <div>
                            <span id="rul" style={{ color: "#e08357" }}>{secondToString(metrics?.averageChargeTime || 0)}</span>
                            <h1>Average Charge Time</h1>
                        </div>
                    </div>
                </div>
                <div className='dashboardMetricCharts'>
                    <GridLayout
                        id="chartGrid"
                        className="layout"
                        // layout={layout}
                        cols={props.cols}
                        rowHeight={props.rowHeight}
                        onLayoutChange={onLayoutChange}
                        onResize={handleResize}
                        isDraggable={false}
                        isResizable={false}
                        margin={[-20, 80]}
                    >
                        <div id="divStationStatus" key="1" data-grid={{ x: 0, y: 0, w: 5, h: 3 }} className="analytic">
                            <ChargeSessionsByDuration asset={props?.asset} ref={stationUsageRef} height={"inherit"} />
                        </div>
                        <div id="divStationStatus" key="2" data-grid={{ x: 6, y: 0, w: 5, h: 3 }} className="analytic">
                            <MonthByMonthRevenue asset={props?.asset} ref={monthlyRevenueRef} height={"inherit"} />
                        </div>
                        {/* <div id="divStationStatus" key="3" data-grid={{ x: 0, y: 4, w: 11, h: 2 }} className="analytic">
                            <HourlyUsageStats asset={props.asset} ref={hourlyConsumptionRef} height={"inherit"} />
                        </div> */}
                    </GridLayout>
                </div>
            </div>
        )
    }
    else {
        return (
            <Loader />
        )
    }
}

export default ChargingStationDashboard;