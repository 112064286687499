import { API_Create, API_GetAllDevice } from '../../../../api/device';
import { Link, useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react';

import $ from 'jquery';
import { API_CreateDevice } from '../../../../api/device';
import CreateButton from '../../../../components/modal/CreateButton';
import CreateModal from '../../../../components/modal/CreateModal'
import Loader from '../../../../components/loader/Loader';
import NoData from '../../../../components/nodata/NoData';
import SearchInput from '../../../../components/searchInput/SearchInput';
import SelectInput from '../../../../components/selectMenu/SelectMenu';
import { addNotification } from '../../../../utils/addNotification';

function DeviceList(props) {
    let [devices, setDevices] = useState(null);
    let [deviceList, setDeviceList] = useState([]);
    let history = useHistory();
    let handleDeviceDetails = (obj) => {
        try {
            history.push({
                pathname: props.match.url + '/' + obj._id,
                state: obj,
            })
        } catch (error) {
            addNotification(error);
        }
    }
    let isFormValid = (formArray) => {
        try {
            let nullCounter = 0;
            formArray.forEach((e, j) => {
                let obj = $('[name="' + e.name + '"]');
                if (obj.length > 0) {
                    obj.each((i, elm) => {
                        let elmName = e.name;
                        let tag = $('[name="' + elmName + '"]').prop('tagName');
                        if ($('#label_' + elmName).filter(':visible').length) {
                            let message = '';
                            if (e.value === null || e.value === "" || e.value === "None") {
                                message = $('[name="' + elmName + '"]').attr('data-error');
                                if (tag === 'SELECT') {
                                    $('[name="' + elmName + '"] option:first').text(message);
                                    $('[name="' + elmName + '"]').addClass('inputError');
                                }
                                else {
                                    $('[name="' + elmName + '"]').attr('placeholder', message).addClass('inputError')
                                }
                                nullCounter++
                            }
                            else {
                                message = $('[name="' + elmName + '"]').attr('data-norm');
                                if (tag === 'SELECT') {
                                    $('[name="' + elmName + '"] option:first').text(message);
                                    $('[name="' + elmName + '"]').removeClass('inputError');
                                }
                                else {
                                    $('[name="' + elmName + '"]').attr('placeholder', message).removeClass('inputError')
                                }
                            }
                        }
                    });
                }
            })
            if (nullCounter > 0) {
                return false;
            }
            else {
                return true;
            }
        } catch (error) {
            addNotification(error);
        }
    }
    let createDevice = async () => {
        try {
            let deviceForm = $('.createModal form').serializeArray();
            if (isFormValid(deviceForm)) {
                $('#formCreateDevice').trigger("reset");
                var formJson = {};
                for (var i = 0; i < deviceForm.length; i++) {
                    formJson[deviceForm[i].name] = deviceForm[i].value;
                }
                formJson["connectionString"] = "ws://fuel.wavefuel.in:3080/ocpp/v1.6J/" + formJson["providerName"] + "/" + formJson["boxSerialNumber"];
                if (formJson['lat'] || formJson['lon']) formJson.location = { lat: formJson['lat'], lon: formJson['lon'] }
                let res = await API_CreateDevice(formJson)
                document.getElementById("formCreateDevice").reset();
                if (res.status === 200) {
                    getDeviceList();
                    $('.modalBackground').hide();
                }
                else if (res.status === 400) {
                    if (res.err && res.err?.code === 11000) {
                        let fieldNames = Object.keys(res.err.keyValue);
                        fieldNames.forEach((e, i) => {
                            $('#' + e).attr('placeholder', 'This field needs to be unique, please renter.').addClass('inputError');
                        })
                    }
                    else {
                        $('input').val('').attr('placeholder', res?.message).addClass('inputError')
                        $('select').val('').attr('placeholder', res?.message).addClass('inputError')
                        $('textarea').val('').attr('placeholder', res?.message).addClass('inputError')
                    }
                }
                else {
                    $('input').val('').attr('placeholder', 'Invalid Entry').addClass('inputError')
                    $('select').val('').attr('placeholder', 'Invalid Entry').addClass('inputError')
                    $('textarea').val('').attr('placeholder', 'Invalid Entry').addClass('inputError')
                    console.log(res)
                };
            }
        }
        catch (err) {
            $('input').val('').attr('placeholder', err?.message).addClass('inputError')
            $('select').val('').attr('placeholder', err?.message).addClass('inputError')
            $('textarea').val('').attr('placeholder', err?.message).addClass('inputError')
            addNotification(err);
        }
    }
    let getDeviceList = async () => {
        try {
            API_GetAllDevice().then(resp => {
                let _devices = resp?.assets?.map(e => { return e.device });
                _devices = _devices.filter(e => { return e !== null });
                _devices?.sort((a, b) => { return a.deviceId - b.deviceId });
                setDevices(_devices);
            }).catch(err => {
                console.log(err);
            })
        } catch (error) {
            addNotification(error);
        }
    }
    let renderDeviceList = (d) => {
        try {
            let deviceList = d.map(e => {
                let iclassname;
                let cAt = new Date(e.createdAt);
                let uAt = new Date(e.updatedAt);
                if (e.deviceType === 'ChargingStation') iclassname = "fa-solid fa-charging-station"
                else if (e.deviceType === 'Vehicle') iclassname = "fa-solid fa-car"
                else if (e.deviceType === 'Sensor') iclassname = "fa-solid fa-microchip"
                return (
                    <div id={e._id} key={e._id} className="listRow" onClick={() => handleDeviceDetails(e)}>
                        <i className={iclassname}></i>
                        <p>{e.deviceName}</p>
                        <p>{e.deviceType}</p>
                        <p>{e.providerName}</p>
                        <p className={e.deviceStatus}><i style={{ marginRight: "7px" }} className="fa-solid fa-circle-dot"></i>{e.deviceStatus}</p>
                        <p>{cAt.toLocaleDateString() + ' ' + cAt.toLocaleTimeString()}</p>
                        <p>{uAt.toLocaleDateString() + ' ' + uAt.toLocaleTimeString()}</p>
                    </div>
                )
            });
            setDeviceList(deviceList);
        } catch (error) {
            addNotification(error);
        }
    }
    let searchFilter = (item, query) => {
        try {
            return (item.deviceName.toLowerCase().includes(query.toLowerCase()) || item.deviceType.toLowerCase().includes(query.toLowerCase()) || item.providerName.toLowerCase().includes(query.toLowerCase()) || item.deviceStatus.toLowerCase().includes(query.toLowerCase()))
        } catch (error) {
            addNotification(error);
        }
    }
    let handleListView = () => {
        try {
            if (devices) {
                if (devices.length > 0) {
                    return (
                        <div className="listTable">
                            <div className="listTableHeader">
                                <i className=""></i>
                                <p>Name</p>
                                <p>Device Type</p>
                                <p>Provider</p>
                                <p>Status</p>
                                <p>Created At</p>
                                <p>Updated At</p>
                            </div>
                            <div className="listTableRows">
                                {deviceList}
                            </div>
                        </div>
                    )
                }
                else {
                    return (
                        <NoData />
                    )
                }
            }
            else {
                return (
                    <Loader />
                )
            }
        } catch (error) {
            addNotification(error);
        }
    }
    useEffect(() => {
        try {
            getDeviceList();
        } catch (error) {
            addNotification(error);
        }
    }, [])
    useEffect(() => {
        try {
            if (devices) {
                renderDeviceList(devices);
            }
        } catch (error) {
            addNotification(error);
        }
    }, [devices])
    return (
        <div id="divSettings" className="accountContainer">
            <div id="divManageDevices">
                <div className="sectionHeader">
                    <div style={{ width: "80%" }}>
                        <h1>Manage Devices</h1>
                        <SearchInput id={"deviceSearch"} placeholder={"Search Devices"} filter={searchFilter} data={devices} searchResult={e => { renderDeviceList(e) }} />
                    </div>
                    <div style={{ width: "20%", height: "83px", marginLeft: "15px" }}>
                        <CreateButton text="Create Device" />
                        {/* <select id='manageViewSelector' name="manageViewSelector">
                            <option value="None">Filter By Type</option>
                            <option value="Devices">Devices</option>
                            <option value="ChargingStations">Charging Stations</option>
                        </select> */}
                        <SelectInput defaultValue={'Filter By Type'} data={devices} searchResult={e => { renderDeviceList(e) }} optionsList={[{ name: 'All Devices', val: '', iconClass: 'fa-solid fa-microchip' }, { name: 'Charging Stations', val: 'ChargingStation', iconClass: 'fa-solid fa-charging-station' }, { name: 'Sensors', val: 'sensor', iconClass: 'fa-solid fa-microchip' }, { name: 'Vehicles', val: 'vehicles', iconClass: 'fa-solid fa-car' }, { name: 'Custom Filter', val: 'Custom', iconClass: 'fa-solid fa-circle-plus', disabled: true }]} />
                    </div>
                </div>
                <div id="divDeviceContainer" className="sectionContent">
                    {handleListView()}
                </div>
            </div>
            <CreateModal type="Device" submit={() => { createDevice() }} />
        </div>
    )
}

export default DeviceList;