import { Route, Switch } from "react-router-dom"

import ManageDevice from "../devices/ManageDevice"
import ManageProjects from './ManageProjects'
import ProjectsList from './ProjectsList'

function Projects(props) {
    return (
        <Switch>
            <Route exact path={props.match.url +  '/'} component={ProjectsList} />
            <Route path={props.match.url + "/:projectsId"} component={ManageProjects} />
            <Route path={props.match.url + "/:projectId/devices/:deviceId"} component={ManageDevice} />
        </Switch>
    )
}

export default Projects