import { useEffect, useState } from "react"
import $, { data } from "jquery"
import { API_GetAllUsernames } from "../../api/account"

function autocomplete(inp, accountIdList) {
    let arr = Object.keys(accountIdList)
    let count = 0
    /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
    var currentFocus
    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", function (e) {
        var a,
            b,
            c,
            d,
            e,
            f,
            i,
            val = this.value
        /*close any already open lists of autocompleted values*/
        closeAllLists()
        if (!val) {
            return false
        }
        $("#divExpiryDateInput").css("visibility", "hidden")
        currentFocus = -1
        /*create a DIV element that will contain the items (values):*/
        a = document.createElement("DIV")
        a.setAttribute("id", this.id + "autocomplete-list")
        a.setAttribute("class", "autocomplete-items")
        /*append the DIV element as a child of the autocomplete container:*/
        this.parentNode.appendChild(a)
        /*for each item in the array...*/
        for (i = 0; i < arr.length; i++) {
            /*check if the item starts with the same letters as the text field value:*/
            if (arr[i].toLowerCase().includes(val.toLowerCase())) {
                count+=1
                /*create a DIV element for each matching element:*/
                b = document.createElement("DIV")
                b.setAttribute("class", "autocomplete-item-field")
                c = document.createElement("DIV")
                c.setAttribute("class", "autocomplete-item-field-icon fa-solid fa-circle-user")
                d = document.createElement("DIV")
                d.setAttribute("class", "autocomplete-item-field-value")
                e = document.createElement("DIV")
                e.setAttribute("class", "autocomplete-item-field-value-phoneNumber")
                f = document.createElement("DIV")
                f.setAttribute("class", "autocomplete-item-field-value-username")
                let text = arr[i].split(" - ")
                e.innerHTML += text[1]
                f.innerHTML += text[0]
                /*insert a input field that will hold the current array item's value:*/
                b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>"
                /*execute a function when someone clicks on the item value (DIV element):*/
                b.addEventListener("click", function (e) {
                    /*insert the value for the autocomplete text field:*/
                    inp.value = this.getElementsByTagName("input")[0].value
                    $("#hiddenIdTagAccountId").val(accountIdList[inp.value].accountId)
                    $("#idTag").val(accountIdList[inp.value].phoneNumber)
                    /*close the list of autocompleted values,
                (or any other open lists of autocompleted values:*/
                    closeAllLists()
                })
                a.appendChild(b)
                b.appendChild(c)
                b.appendChild(d)
                d.appendChild(e)
                d.appendChild(f)
            }
        }
        
        if(count==0){
            closeAllLists()
        }
    })
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function (e) {
        var x = document.getElementById(this.id + "autocomplete-list")
        if (x) x = x.getElementsByTagName("div")
        if (e.keyCode == 40) {
            /*If the arrow DOWN key is pressed,
          increase the currentFocus variable:*/
            currentFocus++
            /*and and make the current item more visible:*/
            addActive(x)
        } else if (e.keyCode == 38) {
            //up
            /*If the arrow UP key is pressed,
          decrease the currentFocus variable:*/
            currentFocus--
            /*and and make the current item more visible:*/
            addActive(x)
        } else if (e.keyCode == 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault()
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                if (x) x[currentFocus].click()
            }
        }
    })
    function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) return false
        /*start by removing the "active" class on all items:*/
        removeActive(x)
        if (currentFocus >= x.length) currentFocus = 0
        if (currentFocus < 0) currentFocus = x.length - 1
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active")
    }
    function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active")
        }
    }
    function closeAllLists(elmnt) {
        /*close all autocomplete lists in the document,
      except the one passed as an argument:*/
        var x = document.getElementsByClassName("autocomplete-items")
        for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i])
            }
        }
        $("#divExpiryDateInput").css("visibility", "visible")
        count = 0
    }
    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
        closeAllLists(e.target)
    })
}

function AuthModal(props) {
    const [accounts, setAccounts] = useState(null);
    let accountIdList = {}
    let obj = {
        organization: JSON.parse(
            localStorage.getItem("organizationId")
        ),
    }

    let getAccounts = async () => {
        let accounts = await API_GetAllUsernames(obj)
        accounts = accounts.accounts.filter((e) => {
            return e !== null
        })
        accounts.sort((a, b) => {
            return a._id - b._id
        })
        setAccounts(accounts);
    } 

    if (accounts) {
        for (let _account of accounts) {
            if(_account.lighthouseUserId){
                let accountField = ""
                if (_account?.lighthouseUserId?.username)
                    accountField += _account?.lighthouseUserId?.username
                if (_account?.phoneNumber) {
                    accountField += " - "
                    accountField += _account?.phoneNumber
                }
                //add rfid, vehicle numberplate
                accountIdList[accountField] = {
                    accountId: _account?.lighthouseUserId?.accountId,
                    username: _account?.lighthouseUserId?.username,
                    phoneNumber: _account?.lighthouseUserId?.phoneNumber,
                }
            }
            else if (_account.username && _account?.phoneNumber) {
                let accountField = ""
                if (_account?.username) accountField += _account?.username
                if (_account?.phoneNumber) {
                    accountField += " - "
                    accountField += _account?.phoneNumber
                }
                //add rfid, vehicle numberplate
                accountIdList[accountField] = {
                    accountId: _account?.accountId,
                    username: _account?.username,
                    phoneNumber: _account?.phoneNumber,
                }
            }
        }
        if (Object.keys(accountIdList).length > 0) {
            autocomplete(document.getElementById("idTag"), accountIdList)
        }
    }
    useEffect(() => {
        getAccounts()
        $("#idTag").on("input", function () {
            let accountInput = $("#idTag").val()
            if (accountInput == "") {
                $("#account-list").hide()
            } else {
                $("#account-list").show()
            }
        })
        $("#account-list").hide()
    }, [])
    return (
        <form id="formCreateAuth" className="createForm">
            <i
                onClick={() => {
                    $(".modalBackground").hide()
                }}
                className="fas fa-times"
            ></i>
            <h1>Add an Identification Id</h1>
            <div id="divIdTagInput" className="formInputs">
                <label id="label_idTag" htmlFor="idTag">
                    Identification Id
                </label>
                <div className="autocomplete">
                    <input
                        id="idTag"
                        name="idTag"
                        type="text"
                        placeholder="Input an Id Tag"
                        data-error="IdTag is a required Field"
                        data-norm="Input an Id Tag"
                        autoComplete="off"
                    />
                </div>
            </div>
            <div id="divExpiryDateInput" className="formInputs">
                <label id="label_expiryDate" htmlFor="expiryDate">
                    Expiry Date
                </label>
                <input
                    id="expiryDate"
                    name="expiryDate"
                    type="datetime-local"
                    placeholder="Input an Expiry Date for the IdTag"
                    data-error="ExpiryDate is a required Field"
                    data-norm="Input an Expiry Date for the IdTag"
                />
            </div>
            <div id="hiddenIdTagAccount" className="formInputs">
                <input id="hiddenIdTagAccountId" name="account" type="text" />
            </div>
        </form>
    )
}

export default AuthModal
