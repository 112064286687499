import './Loader.scss';

function Loader() {
    return (
        <div className='loader'>
            <div className='loadingContent'>
                <div className="loaderVector">
                    <svg>
                        <defs>
                            <filter id="goo">
                                <feGaussianBlur in="SourceGraphic" stdDeviation="2" result="blur" />
                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 5 -2" result="gooey" />
                                <feComposite in="SourceGraphic" in2="gooey" operator="atop" />
                            </filter>
                        </defs>
                    </svg>
                </div>
                <div className='loaderText'>
                    <p>
                        Our Premium Plan is Faster...
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Loader