import { addNotification } from '../../utils/addNotification'

export function API_AuthenticateAccount(obj, next) {
    try {
        if (typeof window !== 'undefined') {
            localStorage.setItem('auth', JSON.stringify(obj.accessToken));
            localStorage.setItem('profile', JSON.stringify(obj.profile));
            localStorage.setItem('organizationId', JSON.stringify(obj.organizationId));
            localStorage.setItem('accountId', obj.profile.accountId);
            next();
        }
    }  catch (error) {
        console.log(error);
    }
};

export function API_IsAuthenticatedAccount() {
    try {
        if (typeof window == 'undefined') {
            return false;
        }
        if (localStorage.getItem('auth')) {
            let currentTime = new Date().getTime();
            let expirationTime = Math.floor(localStorage.getItem('authExpiration'))
            if (expirationTime < currentTime) {
                return false;
            }
            else {
                return JSON.parse(localStorage.getItem('auth'));
            }
        }
        else {
            return false;
        }
    } catch (error) {
        addNotification(error);
    }
};

export function API_SignoutAccount(next) {
    try {
        localStorage.clear('auth')
        next();
    } catch (error) {
        addNotification(error);
    }
}
