import {
    API_GetChargingStationSessions,
    API_GetChargingStationTransactions,
    API_GetHistoricalLogs,
    API_TriggerRemoteAction,
} from "../../../../../api/device"
import {
    API_GetAllUsernames,
    API_GetPrivateUsernames,
    API_LighthouseAccount,
    API_SpotlightAccount,
    API_SpotlightAccounts,
} from "../../../../../api/account"
import { useEffect, useRef, useState } from "react"

import $ from "jquery"
import { API_GetOrganizationAccounts } from "../../../../../api/org"
import CreateModal from "../../../../../components/modal/CreateModal"
import Loader from "../../../../../components/loader/Loader"
import Modal from "../../../../../components/modal/Modal"
import { addNotification } from "../../../../../utils/addNotification"

function autocomplete(inp, accountIdList) {
    try {
        let arr = Object.keys(accountIdList)
        /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
        var currentFocus
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a,
                b,
                c,
                d,
                e,
                f,
                i,
                val = this.value
            /*close any already open lists of autocompleted values*/
            closeAllLists()
            if (!val) {
                return false
            }
            currentFocus = -1
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV")
            a.setAttribute("id", this.id + "autocomplete-list")
            a.setAttribute("class", "autocomplete-items-remote")
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a)
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].toLowerCase().includes(val.toLowerCase())) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV")
                    b.setAttribute("class", "autocomplete-item-field-remote")
                    c = document.createElement("DIV")
                    c.setAttribute("class", "autocomplete-item-field-icon-remote fa-solid fa-circle-user")
                    d = document.createElement("DIV")
                    d.setAttribute("class", "autocomplete-item-field-value-remote")
                    e = document.createElement("DIV")
                    e.setAttribute("class", "autocomplete-item-field-value-phoneNumber-remote")
                    f = document.createElement("DIV")
                    f.setAttribute("class", "autocomplete-item-field-value-username-remote")
                    let text = arr[i].split(" - ")
                    e.innerHTML += text[1]
                    f.innerHTML += text[0]
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML +=
                        "<input type='hidden' value='" + arr[i] + "'>"
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value
                        $("#hiddenIdTagAccountId").val(accountIdList[inp.value].accountId)
                        $("#idTag").val(accountIdList[inp.value].phoneNumber)
                        /*close the list of autocompleted values,
                (or any other open lists of autocompleted values:*/
                        closeAllLists()
                    })
                    a.appendChild(b)
                    b.appendChild(c)
                    b.appendChild(d)
                    d.appendChild(e)
                    d.appendChild(f)
                }
            }
        })
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            var x = document.getElementById(this.id + "autocomplete-list")
            if (x) x = x.getElementsByTagName("div")
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
          increase the currentFocus variable:*/
                currentFocus++
                /*and and make the current item more visible:*/
                addActive(x)
            } else if (e.keyCode == 38) {
                //up
                /*If the arrow UP key is pressed,
          decrease the currentFocus variable:*/
                currentFocus--
                /*and and make the current item more visible:*/
                addActive(x)
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault()
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (x) x[currentFocus].click()
                }
            }
        })
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false
            /*start by removing the "active" class on all items:*/
            removeActive(x)
            if (currentFocus >= x.length) currentFocus = 0
            if (currentFocus < 0) currentFocus = x.length - 1
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active-remote")
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active-remote")
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
      except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items-remote")
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i])
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target)
        })
    } catch (err) {
        console.log("RemoteController Error = ", err)
    }
}

function RemoteController(props) {
    let now = new Date().toUTCString()
    let socket = useRef()
    let [initialRender, setInitialRender] = useState()
    let [transactionList, setTransactionList] = useState(null)
    let [logs, setLogs] = useState([
        now +
        " --------- $ Remote Log Transaction Viewer (Connection Initiated...)",
    ])
    let [activeSession, setActiveSession] = useState(null)
    let [remoteTriggerState, setRemoteTriggerState] = useState(null)
    let [inputFields, setInputFields] = useState(null)
    let [logsDOM, setLogsDOM] = useState([
        <p> {now} ---------- $ Retrieving Historical Logs</p>,
    ])
    let socketReconnectionCounter = useRef()
    socketReconnectionCounter.current = 1
    let handleLogger = (data, type) => {
        try {
            let _logs
            let cl = (e) => {
                try {
                    if (e.includes("Received")) return "reciept"
                    else if (e.includes("Confirmation")) return "conf"
                    else {
                        return ""
                    }
                } catch (error) {
                    addNotification(error)
                }
            }
            if (type === "historical") {
                let _logsDom = logsDOM
                _logs = data.split(/\n/)
                _logs = _logs.slice(_logs.length - 100, _logs.length - 1)
                _logsDom = _logs.map((e, i) => {
                    return (
                        <p key={i} className={cl(e)}>
                            {e}
                        </p>
                    )
                })
                setLogsDOM(_logsDom)
                setInitialRender(true)
            } else {
                if (data.includes(",\"MeterValues\",")) { // FIX ME LATER! It only updates meter values when chart is open, and misses the values when chart is closed. If not refreshed, the chart shown will be wrong. 
                    setActiveSession((prevSession) => {
                        let changedSession = { ...prevSession };
                        if (prevSession) {
                            var startIndex = data.indexOf('[');

                            // Extract the substring starting from the '[' character
                            var json = JSON.parse(data.substring(startIndex));
                            let meterValue = json[3].meterValue[0].sampledValue;
                            console.log(prevSession);
                            if (json[3].transactionId === changedSession.initiationDate) {
                                changedSession.meterValues.push(meterValue);
                            }
                            return changedSession;
                        }
                    })
                }
                let currentTs = new Date().toUTCString()
                let _data = currentTs + " --------- $ " + data
                // _logs = logs;
                // _logs.push(_data)
                // let _logsDom = _logs.map((e, i) => {
                //     return (
                //         <p key={i} className={cl(e)}>{e}</p>
                //     )
                // });
                let newEntryDOM = [
                    <p key={logsDOM.length + 1} className={cl(_data)}>
                        {_data}
                    </p>,
                ]
                logsDOM.push(newEntryDOM)
                let newlogsDOM = logsDOM.map((e) => {
                    return e
                })
                setLogsDOM(newlogsDOM)
            }
            setLogs(_logs)
            if ($(".logViewContainer").length > 0) {
                $(".logViewContainer").animate(
                    { scrollTop: $(".logViewContainer")[0].scrollHeight },
                    "slow"
                )
            }
        } catch (error) {
            addNotification(error)
        }
    }
    let triggerRemoteAction = (action) => {
        try {
            if (
                action === "RemoteStartTransaction" ||
                action === "RemoteStopTransaction" ||
                action === "ChangeConfiguration" ||
                action === "SendLocalAuthList" ||
                action === "StatusNotification" ||
                action === "MeterValues"
            ) {
                setRemoteTriggerState(action)
            } else if (action === "GetConfiguration") {
                setRemoteTriggerState(null)
                API_TriggerRemoteAction(
                    props?.asset?.boxSerialNumber,
                    "GetConfiguration",
                    {}
                )
                    .then((resp) => { })
                    .catch((err) => {
                        addNotification(err)
                    })
            } else if (action === "ClearCache") {
                setRemoteTriggerState(null)
                API_TriggerRemoteAction(
                    props?.asset?.boxSerialNumber,
                    "ClearCache",
                    {}
                )
                    .then((resp) => { })
                    .catch((err) => {
                        addNotification(err)
                    })
            } else if (action === "GetLocalListVersion") {
                setRemoteTriggerState(null)
                API_TriggerRemoteAction(
                    props?.asset?.boxSerialNumber,
                    "GetLocalListVersion",
                    {}
                )
                    .then((resp) => { })
                    .catch((err) => {
                        addNotification(err)
                    })
            } else {
                setRemoteTriggerState(null)
                API_TriggerRemoteAction(
                    props?.asset?.boxSerialNumber,
                    "TriggerMessage",
                    { requestedMessage: action, connectorId: 0 }
                )
                    .then((resp) => { })
                    .catch((err) => {
                        addNotification(err)
                    })
            }
        } catch (error) {
            addNotification(error)
        }
    }
    let handleSessionDetails = (e) => {
        try {
            $(".modalBackground").css({ display: "flex" })
            setActiveSession(e)
            console.log(e)
        } catch (error) {
            addNotification(error)
        }
    }
    let renderTransactionList = () => {
        try {
            API_GetChargingStationTransactions(props?.asset?._id)
                .then((resp) => {
                    try {
                        let txList = resp.transactions.map((e, i) => {
                            return (
                                <div id="1" key={i} className="listRow">
                                    <p>{e._id}</p>
                                    <p>{e.transactionAction}</p>
                                    <p>{e.idTag}</p>
                                    <p>{e.initiationDate}</p>
                                    <p>{e.expiryDate}</p>
                                    <p>{e.status}</p>
                                    <p>{e.payload}</p>
                                    <p>{e.confPayload}</p>
                                </div>
                            )
                        })
                        setTransactionList(txList)
                    } catch (error) {
                        addNotification(error)
                    }
                })
                .catch((err) => {
                    addNotification(err)
                })
        } catch (error) {
            addNotification(error)
        }
    }
    let renderSessionList = () => {
        try {
            API_GetChargingStationSessions(props?.asset?._id).then(resp => {
                try {
                    ;
                    let txList = resp.sessions.map((e, i) => {
                        let endDate;
                        let startDate = new Date(e.startTransaction.createdAt);
                        if (e?.stopTransaction?.createdAt) {
                            let date = new Date(e.stopTransaction.createdAt);
                            endDate = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
                        }
                        else {
                            endDate = 'NULL';
                        }
                        let iconColor;
                        let firstMeterValue = e?.meterValues.slice(0, 1);
                        let energy = e?.meterValues.slice(-1);
                        let amount = Number(e.amount + (e?.metaData?.project?.reservationFee?.price || 0)).toFixed(2);
                        if (energy.length > 0) {
                            energy = energy[0].find(f => { return (f.measurand === 'Energy.Active.Import.Register' || f.unit === "Wh") });
                            firstMeterValue = firstMeterValue[0].find(f => { return (f.measurand === 'Energy.Active.Import.Register' || f.unit === "Wh") });
                            energy = Number((energy.value / 1000) - (firstMeterValue.value / 1000)).toFixed(2) + ' kwh';
                        }
                        else energy = null;
                        let startPayload = null, stopPayload = null;
                        if (e?.startTransaction) startPayload = JSON.parse(e?.startTransaction.payload);
                        if (e?.stopTransaction) stopPayload = JSON.parse(e?.stopTransaction?.payload);
                        if (e.status === 'open') iconColor = "Active"
                        else if (e.status === 'closed' && (stopPayload?.reason === 'Remote' || stopPayload?.reason === 'Local')) iconColor = "Closed"
                        else if (e.status === 'closed' && (stopPayload?.reason !== 'Remote' || stopPayload?.reason !== 'Local')) {
                            iconColor = "Inactive";
                        }
                        else iconColor = "Inactive"
                        return (
                            <div id={e._id} key={i} className="listRow" onClick={() => handleSessionDetails(e)}>
                                <i className={"fa-solid fa-circle-dot " + iconColor}></i>
                                <p>{e.initiationDate}</p>
                                <p>{e.idTag}</p>
                                <p>{startDate.toLocaleDateString() + ' ' + startDate.toLocaleTimeString()}</p>
                                <p>{endDate}</p>
                                <p>{amount}</p>
                                <p>{energy}</p>
                            </div>
                        )
                    })
                    setTransactionList(txList);
                } catch (error) {
                    addNotification(error);
                }
            }).catch(err => {
                addNotification(err);
            });
        } catch (error) {
            addNotification(error)
        }
    }
    let renderInputFields = async () => {
        try {
            let inputFieldDOM
            let obj = {
                organization: JSON.parse(
                    localStorage.getItem("organizationId")
                ),
            }
            if (remoteTriggerState === "RemoteStartTransaction") {
                let accountIdList = {}
                if (props?.asset?.accessCategory === "Public") {
                    let accounts = await API_GetAllUsernames(obj)
                    accounts = accounts.accounts.filter((e) => {
                        return e !== null
                    })
                    accounts.sort((a, b) => {
                        return a._id - b._id
                    })
                    for (let _account of accounts) {
                        
                        if(_account.lighthouseUserId){
                            let accountField = ""
                            if (_account?.lighthouseUserId?.username)
                                accountField += _account?.lighthouseUserId?.username
                            if (_account?.phoneNumber) {
                                accountField += " - "
                                accountField += _account?.phoneNumber
                            }
                            //add rfid, vehicle numberplate
                            accountIdList[accountField] = {
                                accountId: _account?.lighthouseUserId?.accountId,
                                username: _account?.lighthouseUserId?.username,
                                phoneNumber: _account?.lighthouseUserId?.phoneNumber,
                            }
                        }
                        else if (_account.username && _account?.phoneNumber) {
                            let accountField = ""
                            if (_account?.username) accountField += _account?.username
                            if (_account?.phoneNumber) {
                                accountField += " - "
                                accountField += _account?.phoneNumber
                            }
                            //add rfid, vehicle numberplate
                            accountIdList[accountField] = {
                                accountId: _account?.accountId,
                                username: _account?.username,
                                phoneNumber: _account?.phoneNumber,
                            }
                        }
                    }
                } else {
                    let accountIds = props.asset.authList.map(e => e.account)
                    let usernames = await API_GetPrivateUsernames(accountIds)
                    for (let _account of usernames.accounts){
                        if(_account.spotlightUserId.username){
                            let account = ""
                            if (_account.spotlightUserId.username) {
                                account = _account.spotlightUserId.username + " - "
                            }
                            if (_account?.spotlightUserId.phoneNumber) {
                                account += _account?.spotlightUserId.phoneNumber
                            }
                            accountIdList[account] = {
                                accountId: (_account._id).toString(),
                                username: _account?.spotlightUserId.username,
                                phoneNumber: _account?.spotlightUserId.phoneNumber,
                            }
                        }
                        else if(_account.lighthouseUserId){
                            let account = ""
                            if (_account.lighthouseUserId.username) {
                                account = _account.lighthouseUserId.username + " - "
                            }
                            if (_account?.lighthouseUserId.phoneNumber) {
                                account += _account?.lighthouseUserId.phoneNumber
                            }
                            accountIdList[account] = {
                                accountId: (_account._id).toString(),
                                username: _account?.lighthouseUserId.username,
                                phoneNumber: _account?.lighthouseUserId.phoneNumber,
                            }
                        }
                    }
                }
                inputFieldDOM = [
                    <div className="buttonInputFields">
                        <div
                            id="divConnectorNumberInput"
                            className="formInputs"
                        >
                            <label
                                id="label_ConnectorNumber"
                                htmlFor="connectorNumber"
                            >
                                Connector Number
                            </label>
                            <input
                                id="connectorNumber"
                                name="connectorNumber"
                                type="number"
                                min={0}
                                max={3}
                                placeholder="Input the Connector Number"
                                data-error="Number is a required Field"
                                data-norm="Input the Connector Number"
                            />
                        </div>
                        <div id="divIdTagInput" className="formInputs">
                            <label id="label_idTag" htmlFor="idTag">
                                Id Tag
                            </label>
                            <div className="autocomplete-remote">
                                <input
                                    id="idTag"
                                    name="idTag"
                                    placeholder="Input the IdTag"
                                    data-error="Number is a required Field"
                                    data-norm="Input the Connector Number"
                                    autoComplete="off"
                                />
                            </div>
                        </div>
                        <div className="createButtons">
                            <button
                                type="button"
                                onClick={() => {
                                    let cin = $("#connectorNumber").val()
                                    API_TriggerRemoteAction(
                                        props?.asset?.boxSerialNumber,
                                        "RemoteStartTransaction",
                                        {
                                            connectorId: cin,
                                            idTag: $("#idTag").val(),
                                        }
                                    )
                                        .then((resp) => { })
                                        .catch((err) => {
                                            addNotification(err)
                                        })
                                }}
                                className="primaryButton"
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    let value = $("#idTag").val()
                                    if (accountIdList[value] != undefined) {
                                        $("#idTag").val(accountIdList[value])
                                    } else {
                                        $("#idTag").val("")
                                    }
                                    setInputFields(null)
                                    setRemoteTriggerState(null)
                                }}
                                className="secondaryButton"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>,
                ]
                setInputFields(inputFieldDOM)
                console.log("accountIdList=", accountIdList)
                autocomplete(document.getElementById("idTag"), accountIdList)
            } else if (remoteTriggerState === "RemoteStopTransaction") {
                inputFieldDOM = [
                    <div className="buttonInputFields">
                        <div id="divTransactionIdInput" className="formInputs">
                            <label
                                id="label_transactionId"
                                htmlFor="transactionId"
                            >
                                Transaction Id
                            </label>
                            <input
                                id="transactionId"
                                name="transactionId"
                                type="number"
                                placeholder="Input the Transaction Id"
                                data-error="Number is a required Field"
                                data-norm="Input the Connector Number"
                            />
                        </div>
                        <div className="createButtons">
                            <button
                                type="button"
                                onClick={() => {
                                    API_TriggerRemoteAction(
                                        props?.asset?.boxSerialNumber,
                                        "RemoteStopTransaction",
                                        {
                                            transactionId:
                                                $("#transactionId").val(),
                                        }
                                    )
                                        .then((resp) => {
                                            try {
                                                setInputFields(null)
                                                setRemoteTriggerState(null)
                                            } catch (error) {
                                                addNotification(error)
                                            }
                                        })
                                        .catch((err) => {
                                            addNotification(err)
                                        })
                                }}
                                className="primaryButton"
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setInputFields(null)
                                    setRemoteTriggerState(null)
                                }}
                                className="secondaryButton"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>,
                ]
            } else if (remoteTriggerState === "ChangeConfiguration") {
                inputFieldDOM = [
                    <div className="buttonInputFields">
                        <div id="divKeyInput" className="formInputs">
                            <label id="label_key" htmlFor="key">
                                Key
                            </label>
                            <input
                                id="key"
                                name="key"
                                type="string"
                                placeholder="Input the Configuration Key"
                                data-error="Number is a required Field"
                                data-norm="Input the Connector Number"
                            />
                        </div>
                        <div id="divValueInput" className="formInputs">
                            <label id="label_value" htmlFor="value">
                                Value
                            </label>
                            <input
                                id="value"
                                name="value"
                                type="string"
                                placeholder="Input the Configuration Value"
                                data-error="Number is a required Field"
                                data-norm="Input the Connector Number"
                            />
                        </div>
                        <div className="createButtons">
                            <button
                                type="button"
                                onClick={() => {
                                    API_TriggerRemoteAction(
                                        props?.asset?.boxSerialNumber,
                                        "ChangeConfiguration",
                                        {
                                            key: $("#divKeyInput input").val(),
                                            value: $(
                                                "#divValueInput input"
                                            ).val(),
                                        }
                                    )
                                        .then((resp) => {
                                            try {
                                                setInputFields(null)
                                                setRemoteTriggerState(null)
                                            } catch (error) {
                                                addNotification(error)
                                            }
                                        })
                                        .catch((err) => {
                                            addNotification(err)
                                        })
                                }}
                                className="primaryButton"
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setInputFields(null)
                                    setRemoteTriggerState(null)
                                }}
                                className="secondaryButton"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>,
                ]
            } else if (remoteTriggerState === "SendLocalAuthList") {
                inputFieldDOM = [
                    <div className="buttonInputFields">
                        <div id="divListVersionInput" className="formInputs">
                            <label id="label_ListVersion" htmlFor="listVersion">
                                List Version
                            </label>
                            <input
                                id="listVersion"
                                name="listVersion"
                                type="string"
                                placeholder="Input the List Version"
                                data-error="Number is a required Field"
                                data-norm="Input the Connector Number"
                            />
                        </div>
                        <div id="divUpdateTypeInput" className="formInputs">
                            <label id="label_updateType" htmlFor="updateType">
                                Update Type
                            </label>
                            <input
                                id="updateType"
                                name="updateType"
                                type="string"
                                placeholder="Input the Update Type"
                                data-error="Number is a required Field"
                                data-norm="Input the Connector Number"
                            />
                        </div>
                        <div
                            id="divLocalAuthorizationListInput"
                            className="formInputs"
                        >
                            <label
                                id="label_localAuthorizationList"
                                htmlFor="localAuthorizationList"
                            >
                                Local Auth List
                            </label>
                            <input
                                id="localAuthorizationList"
                                name="localAuthorizationList"
                                type="string"
                                placeholder="Input the Local Auth List"
                                data-error="Number is a required Field"
                                data-norm="Input the Connector Number"
                            />
                        </div>
                        <div className="createButtons">
                            <button
                                type="button"
                                onClick={() => {
                                    API_TriggerRemoteAction(
                                        props?.asset?.boxSerialNumber,
                                        "SendLocalList",
                                        {
                                            listVersion: $(
                                                "#divListVersionInput input"
                                            ).val(),
                                            updateType: $(
                                                "#divUpdateTypeInput input"
                                            ).val(),
                                            localAuthorizationList: $(
                                                "#divLocalAuthorizationListInput input"
                                            ).val(),
                                        }
                                    )
                                        .then((resp) => {
                                            try {
                                                setInputFields(null)
                                                setRemoteTriggerState(null)
                                            } catch (error) {
                                                addNotification(error)
                                            }
                                        })
                                        .catch((err) => {
                                            addNotification(err)
                                        })
                                }}
                                className="primaryButton"
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setInputFields(null)
                                    setRemoteTriggerState(null)
                                }}
                                className="secondaryButton"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>,
                ]
            } else if (remoteTriggerState === "StatusNotification") {
                inputFieldDOM = [
                    <div className="buttonInputFields">
                        <div
                            id="divConnectorNumberInput"
                            className="formInputs"
                        >
                            <label
                                id="label_ConnectorNumber"
                                htmlFor="connectorNumber"
                            >
                                Connector Number
                            </label>
                            <input
                                id="connectorNumber"
                                name="connectorNumber"
                                type="number"
                                min={0}
                                max={3}
                                placeholder="Input the Connector Number"
                                data-error="Number is a required Field"
                                data-norm="Input the Connector Number"
                            />
                        </div>
                        <div className="createButtons">
                            <button
                                type="button"
                                onClick={() => {
                                    API_TriggerRemoteAction(
                                        props?.asset?.boxSerialNumber,
                                        "TriggerMessage",
                                        {
                                            requestedMessage:
                                                "StatusNotification",
                                            connectorId: $(
                                                "#divConnectorNumberInput input"
                                            ).val(),
                                        }
                                    )
                                        .then((resp) => {
                                            try {
                                                setInputFields(null)
                                                setRemoteTriggerState(null)
                                            } catch (error) {
                                                addNotification(error)
                                            }
                                        })
                                        .catch((err) => {
                                            addNotification(err)
                                        })
                                }}
                                className="primaryButton"
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setInputFields(null)
                                    setRemoteTriggerState(null)
                                }}
                                className="secondaryButton"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>,
                ]
            } else if (remoteTriggerState === "MeterValues") {
                inputFieldDOM = [
                    <div className="buttonInputFields">
                        <div
                            id="divConnectorNumberInput"
                            className="formInputs"
                        >
                            <label
                                id="label_ConnectorNumber"
                                htmlFor="connectorNumber"
                            >
                                Connector Number
                            </label>
                            <input
                                id="connectorNumber"
                                name="connectorNumber"
                                type="number"
                                min={0}
                                max={3}
                                placeholder="Input the Connector Number"
                                data-error="Number is a required Field"
                                data-norm="Input the Connector Number"
                            />
                        </div>
                        <div className="createButtons">
                            <button
                                type="button"
                                onClick={() => {
                                    API_TriggerRemoteAction(
                                        props?.asset?.boxSerialNumber,
                                        "TriggerMessage",
                                        {
                                            requestedMessage: "MeterValues",
                                            connectorId: $(
                                                "#divConnectorNumberInput input"
                                            ).val(),
                                        }
                                    )
                                        .then((resp) => {
                                            try {
                                                setInputFields(null)
                                                setRemoteTriggerState(null)
                                            } catch (error) {
                                                addNotification(error)
                                            }
                                        })
                                        .catch((err) => {
                                            addNotification(err)
                                        })
                                }}
                                className="primaryButton"
                            >
                                Submit
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    setInputFields(null)
                                    setRemoteTriggerState(null)
                                }}
                                className="secondaryButton"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>,
                ]
            } else {
                ; <div className="buttonInputFields"></div>
            }
            setInputFields(inputFieldDOM)
        } catch (error) {
            addNotification(error)
        }
    }
    function connectLogger() {
        try {
            let connetionString = process.env.REACT_APP_FUEL_SERVER_WS_URL +
                props.device.providerName +
                "/" +
                props?.asset?.boxSerialNumber;
            const ws = new WebSocket(
                connetionString,
                ["logger"]
            )
            ws.onopen = () => {
                try {
                    handleLogger("Remote Socket Connection Status: Open")
                } catch (error) {
                    addNotification(error)
                }
            }
            ws.onmessage = (message) => {
                try {
                    let data = message.data
                    handleLogger(data)
                } catch (error) {
                    addNotification(error)
                }
            }
            ws.onclose = () => {
                try {
                    handleLogger("Remote Socket Connection Status: Closed")
                    handleLogger("Refresh the page to reconnect...")
                } catch (error) {
                    addNotification({
                        message:
                            "WebSocket connection closed: Connection establishment: net::ERR_CONNECTION_CLOSED",
                    })
                }
            }
            return ws
        } catch (error) {
            addNotification(error)
        }
    }
    useEffect(() => {
        try {
            API_GetHistoricalLogs(
                props?.device?.providerName,
                props?.asset?.boxSerialNumber
            )
                .then((resp) => {
                    try {
                        handleLogger(resp.message, "historical")
                    } catch (error) {
                        addNotification(error)
                    }
                })
                .catch((err) => {
                    addNotification(err)
                })
            renderSessionList()
            return () => {
                try {
                    //if(socket.current) socket.current.close();
                    socket.current?.close()
                } catch (error) {
                    addNotification(error)
                }
            }
        } catch (error) {
            addNotification(error)
        }
    }, [])

    useEffect(() => {
        try {
            if (initialRender && props.asset) {
                let ws = connectLogger()
                socket.current = ws
            }
        } catch (error) {
            addNotification(error)
        }
    }, [initialRender])
    useEffect(() => {
        try {
            if (remoteTriggerState) {
                $(".buttonInputFieldsParent").animate({ opacity: 0 })
                $(".buttonInputFieldsParent").animate(
                    { height: "80px" },
                    500,
                    () => {
                        renderInputFields()
                        $(".buttonInputFieldsParent").animate({ opacity: 1 })
                    }
                )
            } else {
                $(".buttonInputFieldsParent").animate(
                    { opacity: 0 },
                    500,
                    () => {
                        $(".buttonInputFieldsParent").animate({ height: "0px" })
                        renderInputFields()
                    }
                )
            }
        } catch (error) {
            addNotification(error)
        }
    }, [remoteTriggerState])
    useEffect(() => {
        try {
            if (socketReconnectionCounter > 5) {
                socket.current.close()
                handleLogger(
                    "Remote Socket Connection was Closed, please refresh the view..."
                )
            }
        } catch (error) {
            addNotification(error)
        }
    }, [socketReconnectionCounter])
    if (props.device && props.asset) {
        return (
            <div>
                <div className="remoteTriggers">
                    <h1>Trigger Controls</h1>
                    <div>
                        <button
                            onClick={() => {
                                triggerRemoteAction("BootNotification")
                            }}
                        >
                            BootNotification
                        </button>
                        <button
                            onClick={() => {
                                triggerRemoteAction(
                                    "DiagnosticsStatusNotification"
                                )
                            }}
                        >
                            DiagnosticsStatusNotification
                        </button>
                        <button
                            onClick={() => {
                                triggerRemoteAction(
                                    "FirmwareStatusNotification"
                                )
                            }}
                        >
                            FirmwareStatusNotification
                        </button>
                        <button
                            onClick={() => {
                                triggerRemoteAction("Heartbeat")
                            }}
                        >
                            Heartbeat
                        </button>
                        <button
                            onClick={() => {
                                triggerRemoteAction("MeterValues")
                            }}
                        >
                            MeterValues
                        </button>
                        <button
                            onClick={() => {
                                triggerRemoteAction("StatusNotification")
                            }}
                        >
                            StatusNotification
                        </button>
                        <button
                            onClick={() => {
                                triggerRemoteAction("GetConfiguration")
                            }}
                        >
                            GetConfiguration
                        </button>
                        <button
                            onClick={() => {
                                triggerRemoteAction("ChangeConfiguration")
                            }}
                        >
                            ChangeConfiguration
                        </button>
                        <button
                            onClick={() => {
                                triggerRemoteAction("ClearCache")
                            }}
                        >
                            ClearCache
                        </button>
                        <button
                            onClick={() => {
                                triggerRemoteAction("GetLocalListVersion")
                            }}
                        >
                            GetLocalListVersion
                        </button>
                        <button
                            onClick={() => {
                                triggerRemoteAction("RemoteStartTransaction")
                            }}
                        >
                            RemoteStart
                        </button>
                        <button
                            onClick={() => {
                                triggerRemoteAction("RemoteStopTransaction")
                            }}
                        >
                            RemoteStop
                        </button>
                        <button
                            onClick={() => {
                                triggerRemoteAction("SendLocalAuthList")
                            }}
                        >
                            SendLocalAuthList
                        </button>
                    </div>
                    <div className="buttonInputFieldsParent">{inputFields}</div>
                </div>
                <div className="logViewContainer">
                    <h1>Real Time Device Logs</h1>
                    {initialRender ? (
                        logsDOM
                    ) : (
                        <div
                            style={{
                                position: "relative",
                                height: "360px",
                                margin: "auto",
                            }}
                        >
                            <Loader />
                        </div>
                    )}
                </div>
                <div id="divSessionContainer" className="inputContent">
                    <div className="listTable">
                        <div className="listTableHeader">
                            <i className=""></i>
                            <p>TransactionId</p>
                            <p>ID Tag</p>
                            <p>Start Date</p>
                            <p>Stop Date</p>
                            <p>Amount</p>
                            <p>Energy Delivered</p>
                        </div>
                        <div className="listTableRows">{transactionList}</div>
                    </div>
                </div>
                <Modal type="SessionDetails" data={activeSession} />
            </div>
        )
    } else {
        return <Loader />
    }
}

export default RemoteController
