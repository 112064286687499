import { API_GetHourlyData, API_GetProjectHourlyData } from '../../../../api/project';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import $ from 'jquery'
import HC_export_data from "highcharts/modules/export-data";
import HC_exporting from 'highcharts/modules/exporting'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from '../../../loader/Loader';
import { Rnd } from 'react-rnd'
import { addNotification } from '../../../../utils/addNotification';

const UserChargeByHourOfDay = forwardRef((props, ref) => {
    let chartComponent = useRef();
    let [seriesData, setSeriesData] = useState(null)
    let [options, setOptions] = useState(null);
    useImperativeHandle(
        ref,
        () => ({
            resizeChart() {
                try {
                    chartComponent.current.chart.reflow();
                } catch (error) {
                    addNotification(error);
                }
            }
        }),
    )
    const opt = {
        chart: {
            polar: true,
            reflow: true,
            backgroundColor: '#e2e2e200',
            height: props.height,
            width: props.width,
            marginTop: 50
        },
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
        annotations: {
            labels: {
                format: "{value}",
            },
        },
        title: {
            text: 'Charge By Hour Of Day',
            floating: true,
            style: { fontSize: "12px" },
        },
        subtitle: {
            text: 'Consumption pattern by hour for this User.',
            floating: true,
            style: { fontSize: "10px", color: "gray" },
            y: 30,
        },
        pane: {
            startAngle: 0,
            endAngle: 360
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            tickInterval: 1,
            min: 1,
            max: 25,
            labels: {
                format: '{value}'
            }
        },

        yAxis: {
            min: 0
        },
        tooltip: {
            enabled: false
        },
        plotOptions: {
            series: {
                pointStart: 12,
                pointInterval: 1
            },
            column: {
                pointPadding: 0,
                groupPadding: 0
            }
        },

        series: [{
            type: 'column',
            name: 'Public',
            data: [],
            //pointPlacement: 'between',
            color: "#8aee8a"
        }]
    };
    useEffect(() => {
        try {
            setOptions(null);
            if (props.project?._id) {
                if (props.activeUser) {
                    let obj = { idTag: props.activeUser.idTag }
                    API_GetHourlyData(obj).then(d => {
                        console.log(d);
                        let seriesData = d.weights;
                        let _opt = opt;
                        _opt.series[0].data = seriesData;
                        setOptions(_opt);
                    }).catch(err => {
                        addNotification(err);
                    });
                }
                else {
                    let obj = { project: props.project._id }
                    API_GetProjectHourlyData(obj).then(d => {
                        console.log(d);
                        let seriesData = d.weights;
                        let _opt = opt;
                        _opt.series[0].data = seriesData;
                        setOptions(_opt);
                    }).catch(err => {
                        addNotification(err);
                    });
                }
            }
        } catch (error) {
            addNotification(error);
        }
    }, [props.project, props.activeUser])
    HC_exporting(Highcharts);
    HC_export_data(Highcharts);
    return (
        <div id="radialChart" className="dashboardChartContainer">
            {options ? <HighchartsReact id="chart" highcharts={Highcharts} ref={chartComponent} options={options} containerProps={{ className: 'chart', style: { height: props.height, width: '300px' } }} />
                :
                <Loader />}
        </div>
    )
});
export default UserChargeByHourOfDay