import { useEffect, useState } from "react"

import $ from 'jquery';
import MapConfigInput from "../../../../components/mapConfigInput/MapConfigInput";
import ProjectConfigForm from "../../../../components/forms/projectConfig/ProjectConfigForm";
import { addNotification } from "../../../../utils/addNotification";

function ProjectConfig(props) {
    let [editState, setEditState] = useState(props.edit)
    useEffect(() => {try {
        if (editState == false) {
            $('.deviceConfigContainer input').prop('disabled', true).addClass("disabledInput");
            $('.deviceConfigContainer textArea').prop('disabled', true).addClass("disabledInput");
            $('.deviceConfigContainer select').prop('disabled', true).addClass("disabledInput");
        }
        else {
            $('.deviceConfigContainer input').prop('disabled', false).removeClass("disabledInput");
            $('.deviceConfigContainer textArea').prop('disabled', false).removeClass("disabledInput")
            $('.deviceConfigContainer select').prop('disabled', false).removeClass("disabledInput");
        }
    } catch(error){
        addNotification(error);
    }}, [editState])
    return (
        <div className="deviceConfigContainer">
            <div className="configContainer">
                <div className="formContainer">
                    <ProjectConfigForm project={props.project} />
                </div>
                <div className="secondaryFormContainer">
                    <MapConfigInput location={{lat: props.project?.location?.lat, lon: props.project?.location?.lon}} data={{project: props.project, devices: props.devices}} status={props.project.projectStatus.toLowerCase()} />
                    {/* <IdTagInput asset={asset} removeTag={removeAuthList} /> */}
                    {/* <ConnectorModule asset={props.asset} removeField={removeConnector} /> */}
                </div>
            </div>
        </div>
    )
}

export default ProjectConfig