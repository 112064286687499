import { useRef } from "react";
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import $ from 'jquery'
import PlaygroundChart from "../../../../components/charts/PlaygroundChart";
import { addNotification } from "../../../../utils/addNotification";

const UploadBox = styled.div`
    margin-top: 0px;
    text-align: center;
    color: 
    `;

function Playground() {
    let lineAreaRef = useRef();
    let handleUpload = async function (e, state) {
        try {
            if (state == "drop") {
                var eventMod = e;
            }
            else {
                e.preventDefault();
                var eventMod = e.target.files;
            }
            let fileForm = new FormData();
            fileForm.append('file', eventMod[0]);
            console.log(eventMod);
            let url = process.env.REACT_APP_SERVER_API_URL + '/upload/playground/custom';
            let resp = fetch(url, {
                mode: 'cors',
                method: 'POST',
                body: fileForm,
            }).then(res => res.json()).then((resp) => { return resp }).catch(error => { addNotification(error); });
        } catch (error) {
            addNotification(error);
        }
    }
    const { acceptedFiles,
        getRootProps,
        isDragActive,
        getInputProps,
        open,
    } = useDropzone({
        accept: '.xls, .xlsx, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv',
        maxFiles: 1,
        onDrop: (e) => {
            handleUpload(e, "drop");
        },
        onDragEnter: () => { $('.uploadBox').css({ border: "3px dashed #cbbb00" }) },
        onDragLeave: () => { $('.uploadBox').css({ border: "3px dashed #02113b" }) },
    });
    return (
        <div id="divSettings" className="accountContainer">
            <div id="divUploadContent" className="playgroundContent">
                <div id="divSettingsHeader" className="sectionHeader">
                    <h1>Test the Algorithm</h1>
                    <p>Upload a data set, with the specified format to get a live prediction of future instances of the data. You will need to select the specified columns and their relation to each other, so the algorithm can accurately predict future data. </p>
                    <div className="playgroundGuidelines">
                        <p>Guidelines to follow for accurate results:</p>
                        <ol>
                            <li>Atleast 3000 rows of data is required</li>
                            <li>Columns/Variables should be mathematically corellated</li>
                            <li>Randomized and Junk data will provide Junk results</li>
                        </ol>
                        <p>Use Example DataSets:</p>
                        <div className="playgroundEg">
                            <i className="fas fa-file-excel"></i>
                            <span>CEP 1C_BEFORE PREVENTIVE</span>
                        </div>
                        <div className="playgroundEg">
                            <i className="fas fa-file-excel"></i>
                            <span>CEP 1C_AFTER PREVENTIVE</span>
                        </div>
                    </div>
                </div>
                <p style={{ textAlign: "center" }}> or </p>
                <div id="divUploadInput" className="uploadInput">
                    <UploadBox {...getRootProps({ isDragActive, className: "uploadBox" })} >
                        {/* Input names are defined in a dictionary, if you make any changes to the name, make sure to change it in Upload/Dictionary */}
                        <input {...getInputProps()} name="toolbarMediaUpload" onChange={(e) => { handleUpload(e) }} />
                        <i className="fas fa-file-upload"></i>
                        <p className="uploadText">Drop File Here</p>
                    </UploadBox>
                    {/* <button className="uploadToolText" onClick={open}>Browse Folder</button> */}
                </div>
                <button> Submit Data </button>
            </div>
            <div id="divResultChart" className="playgroundChart">
                <PlaygroundChart ref={lineAreaRef} />
            </div>
        </div>
    )
}

export default Playground;