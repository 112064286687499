import $ from 'jquery'
import { addNotification } from '../../utils/addNotification'

function Alert(props) {
    try {
        if (props.isActive === true) {
            return (
                <div id="divAlertContent" className="accountAlert">
                    <p>{props.alertContent}</p>
                    <i id="iAlertClose" onClick={() => { $('#divAlertContent').hide() }} className="fas fa-times"></i>
                </div>
            )
        }
        else {
            return (
                <div></div>
            )
        }
    } catch (error) {
        addNotification(error);
    }
}

export default Alert;