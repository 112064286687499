import { Link, Route, Switch, useHistory } from 'react-router-dom'

import $ from 'jquery';

function Signup(props) {
    return (
        <div className="slideForm signupForm">
            <h1>Create an Account</h1>
            {/* <p>Enter your organization and license details, we will generate an admin account for your organization.</p> */}
            <form id="formSignupInputs" style={{ marginTop: "20px" }}>
                <div className="formInputs">
                    <label id="label_signup_organizationName" htmlFor="organizationName">Organization Name</label>
                    <input id="organizationName" name="organizationName" placeholder="eg: 1234-56" data-norm="Organization Name" data-error="Invalid Organization Name" />
                </div>
                <div className="formInputs">
                    <label id="label_signup_organizationType" htmlFor="organizationType">Organization Type</label>
                    <select id='organizationType' name="organizationType" data-error="Choose an Organization Type" data-norm="Organization Type">
                        <option value="None">Choose an Org Type</option>
                        <option value="Sole_Proprietorship">Sole Proprietorship</option>
                        <option value="LLC">Limited Liability Company</option>
                        <option value="C_Corporation"> C-Corporation</option>
                        <option value="S_Corporation">S-Corporation</option>
                        <option value="Non_Profit">Non-Profit</option>
                        <option value="Cooperative">Cooperative</option>
                    </select>
                </div>
                <div className="formInputs">
                    <label id="label_signup_serviceType" htmlFor="serviceType">Service Type</label>
                    <select id='serviceType' name="serviceType" data-error="Choose a Service Type" data-norm="Service Type">
                        <option value="Vehicle Manufacturers">Vehicle Manufacturers</option>
                        <option value="Battery Manufacturers">Battery Manufacturers</option>
                        <option value="Residential Association">Residential Association</option>
                        <option value="Private Resident">Private Resident</option>
                        <option value="Station Operator">Station Operator</option>
                        <option value="Logistic Provider">Logistic Provider</option>
                        <option value="Commercial Service Provider">Commercial Service Provider</option>
                        <option value="Construction and Infrastructure Provider">Construction and Infrastructure Provider</option>
                    </select>
                </div>
                <div className="formInputs">
                    <label id="label_signup_username" htmlFor="phoneNumber">Full Name</label>
                    <input id="username" name="username" placeholder="eg: 000-000-0000" data-norm="User Name" data-error="User Name is Invalid" />
                </div>
                <div className="formInputs">
                    <label id="label_signup_jobTitle" htmlFor="jobTitle">Job Title</label>
                    <input id="jobTitle" name="jobTitle" placeholder="eg: 000-000-0000" data-norm="Job Title" data-error="Job Title is Invalid" />
                </div>
                <div className="formInputs">
                    <label id="label_signup_phoneNumber" htmlFor="phoneNumber">Phone Number</label>
                    <input id="phoneNumber" type="tel" name="phoneNumber" placeholder="eg: 000-000-0000" pattern="[0-9]{3}-[0-9]{3}-[0-9]{3}" data-norm="Phone Number" data-error="Phone Number is Invalid" />
                </div>
                <div className="formInputs">
                    <label id="label_signup_email" htmlFor="email">Email</label>
                    <input id="email" type="email" name="email" placeholder="eg: example@example.com" data-error="Email is Invalid" data-norm="Email" />
                </div>
                <div className="formInputs">
                    <label id="label_signup_password" htmlFor="Password">Password</label>
                    <input id="password" type="password" name="password" placeholder="" data-error="Password is Invalid" data-norm="Password" />
                </div>
                <div className="formInputs">
                    <label id="label_signup_confirmPassword" htmlFor="confirmPassword">Confirm Password</label>
                    <input id="confirmPassword" type="password" name="confirmPassword" placeholder="" data-error="Password does not match" data-norm="Confirm Password" />
                </div>
                <div className="formButtons">
                    <div>
                        <button onClick={() => { props.submit() }} id="btnSignupSubmit" type="button" className="primaryButton" >Signup</button>
                        {/* onClick={() => { props.submit() }} */}
                        <button id="btnSignupRedirect" type="button" className="secondaryButton" onClick={() => { props.setForm('inactive')}}>Cancel</button>
                        {/* onClick={() => { props.setForm('inactive') }} */}
                    </div>
                    <div><p>Already have an Account?</p><span onClick={() => { props.setForm('Login') }}>Login Here.</span></div>
                </div>
            </form>
        </div>
    )
}

export default Signup;