import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import HC_export_data from "highcharts/modules/export-data";
import HC_exporting from 'highcharts/modules/exporting'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from '../../loader/Loader';
import { addNotification } from '../../../utils/addNotification'
import highchartsMore from 'highcharts/highcharts-more'

const SessionMeterValues = forwardRef((props, ref) => {
    let [seriesData, setSeriesData] = useState({ current: null, energy: null, power: null, voltage: null, temperature: null })
    let [options, setOptions] = useState(null);
    let chartComponent = useRef();
    let opt = {
        chart: {
            zoomType: 'xy',
            backgroundColor: '#e2e2e200',
            plotBorderWidth: 0,
            reflow: true
        },
        legend: {
            enabled: props.legend
        },
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbolFill: "#989898",
                    theme: {
                        fill: "#e2e2e200",
                        padding: 5,
                        stroke: "none",
                    },
                    symbol: 'menu',
                    menuItems: [
                        "viewFullscreen",
                        "printChart", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG",
                        {
                            text: "Download CSV File",
                            onclick() {
                                let csv = this.getCSV();
                                const blob = new Blob([csv], { type: 'text/csv' });

                                // Creating an object for downloading url
                                const url = window.URL.createObjectURL(blob)

                                // Creating an anchor(a) tag of HTML
                                const a = document.createElement('a')

                                // Passing the blob downloading url 
                                a.setAttribute('href', url)

                                // Setting the anchor tag attribute for downloading
                                // and passing the download file name
                                a.setAttribute('download', 'download.csv');

                                // Performing a download with click
                                a.click()
                            }
                        },
                    ],
                    x: 15,
                    y: -15
                }
            }
        },
        annotations: {
            labels: {
                format: "{value}",
            },
        },
        title: {
            text: ' ',
            align: 'center',
        },
        subtitle: {
            text: 'Meter Values over the period of the Session, broken down by Energy, Power, Current, Voltage, Temperature',
            floating: false,
            align: 'center',
            style: { fontSize: "10px", color: "gray" },
        },
        xAxis: {
            type: 'linear',
            title: {
                text: 'Time Cycles'
            },
        },
        yAxis: {
            gridLineWidth: 1,
            type: 'linear',
            title: {
                text: 'Sensor Values'
            },
        },
        legend: {
            layout: 'vertical',
            align: 'left',
            x: 80,
            verticalAlign: 'top',
            y: 55,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                'rgba(255,255,255,0.25)'
        },
        tooltip: {
            crosshairs: true,
            shared: true,
        },
        series: [
            {
                name: 'Energy',
                type: 'area',
                dashStyle: 'solid',
                data: seriesData.energySeries,
                tooltip: {
                    valueSuffix: ' kWh'
                },
                marker: {
                    enabled: false
                }
            },
            {
                name: 'Power',
                type: 'area',
                dashStyle: 'solid',
                data: seriesData.powerSeries,
                tooltip: {
                    valueSuffix: ' kW'
                },
                marker: {
                    enabled: false
                }
            },
            {
                name: 'Current',
                type: 'area',
                dashStyle: 'solid',
                data: seriesData.currentSeries,
                tooltip: {
                    valueSuffix: ' A'
                },
                marker: {
                    enabled: false
                }
            },
            {
                name: 'Voltage',
                type: 'area',
                dashStyle: 'solid',
                data: seriesData.voltageSeries,
                tooltip: {
                    valueSuffix: ' V'
                },
                marker: {
                    enabled: false
                }
            },
            {
                name: 'Temperature',
                type: 'area',
                dashStyle: 'solid',
                data: seriesData.tempSeries,
                tooltip: {
                    valueSuffix: ' °C'
                },
                marker: {
                    enabled: false
                }
            }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        floating: false,
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom',
                        x: 0,
                        y: 0
                    },
                    yAxis: [{
                        labels: {
                            align: 'right',
                            x: 0,
                            y: -6
                        },
                        showLastLabel: false
                    }, {
                        labels: {
                            align: 'left',
                            x: 0,
                            y: -6
                        },
                        showLastLabel: false
                    }, {
                        visible: false
                    }]
                }
            }]
        }
    };
    useImperativeHandle(
        ref,
        () => ({
            resizeChart() {
                try {
                    chartComponent.current.chart.reflow();
                } catch (error) {
                    addNotification(error);
                }
            }
        }),
    )
    useEffect(() => {
        try {
            if (props.session) {
                // optionsUpdated.xAxis.plotLines = [{
                //     color: '#FF0000', // Red
                //     width: 5,
                //     value: tl,
                //     label: {
                //         text: 'RUL',
                //         textAlign: 'center',
                //         verticalAlign: 'middle'
                //     }
                // }];
                // optionsUpdated.xAxis.plotBands = [
                //     {
                //         color: '#f0f4ff',
                //         from: 0,
                //         to: resp.data[24]['data'][0],
                //         label: {
                //             text: 'Current Sensor Data',
                //             textAlign: 'center'
                //         }
                //     },
                //     {
                //         color: '#ffe6e6',
                //         from: resp.data[24]['data'][0],
                //         to: tl,
                //         label: {
                //             text: 'Predicted Sensor Data',
                //             textAlign: 'center'
                //         }
                //     }
                // ]
                let meterValues = props.session.meterValues;
                let energySeries = meterValues.map(e => {
                    let d = e.find(f => { return (f.measurand === 'Energy.Active.Import.Register' || f.unit === "Wh") })
                    if (d) return Number(d.value / 1000);
                    else return 0
                })
                let powerSeries = meterValues.map(e => {
                    let d = e.find(f => { return (f.measurand === 'Power.Active.Import' || f.unit === "kW") })
                    if (d) return Number(d.value);
                    else return 0
                })
                let currentSeries = meterValues.map(e => {
                    let d = e.find(f => { return (f.measurand === 'Current.Import' || f.unit === "A") })
                    if (d) return Number(d.value);
                    else return 0
                })
                let voltageSeries = meterValues.map(e => {
                    let d = e.find(f => { return (f.measurand === 'Voltage' || f.unit === "V") })
                    if (d) return Number(d.value);
                    else return 0
                })
                let tempSeries = meterValues.map(e => {
                    let d = e.find(f => { return (f.measurand === 'Temperature' || f.unit === "Celsius") })
                    if (d) return Number(d.value);
                    else return 0
                })
                setSeriesData({ current: currentSeries, energy: energySeries, power: powerSeries, voltage: voltageSeries, temperature: tempSeries });
                let _opt = opt;
                _opt.series[0].data = energySeries;
                _opt.series[1].data = powerSeries;
                _opt.series[2].data = currentSeries;
                _opt.series[3].data = voltageSeries;
                _opt.series[4].data = tempSeries;
                setOptions(_opt);
                if(chartComponent.current){
                    chartComponent.current.chart.reflow();
                }
            }
        } catch (error) {
            addNotification(error);
        }
    }, [props.session])
    HC_exporting(Highcharts);
    HC_export_data(Highcharts);
    return (
        <div id="lineAreaChart" className='dashboardChartContainer'>
            {
                (options) ? <HighchartsReact highcharts={highchartsMore(Highcharts)} ref={chartComponent} options={options} containerProps={{ className: 'chart', style: { height: props.height } }} />
                    : <Loader />
            }
        </div>
    )
});
export default SessionMeterValues