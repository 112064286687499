import { useEffect, useRef, useState } from "react";

import $ from 'jquery'
import { API_UpdateDevice } from '../../../../api/device';
import ChargingStationDashboard from "./chargingstations/ChargingStationDashboard";
import LegacyCharts from '../../../../components/legacyCharts/LegacyCharts'
import RUL_Chart from "../../../../components/charts/RUL_Chart";
import UnderConstruction from "../../../../components/UnderConstruction/UnderConstruction";
import { addNotification } from '../../../../utils/addNotification'

function DeviceDashboard(props) {
    try {
        if (props?.device?.deviceType === 'ChargingStation') {
            return (
                <ChargingStationDashboard device={props.device} asset={props.asset} edit={props.editable} />
            )
        }
        else {
            return (
                <UnderConstruction />
            )
        }
    } catch (error) {
        addNotification(error);
    }
}

export default DeviceDashboard;