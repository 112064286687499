import { addNotification } from "../utils/addNotification";

export async function API_CreateDevice(formData) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/device/create/';
        formData.account = JSON.parse(localStorage.getItem('profile')).accountId;
        formData.organizationId = JSON.parse(localStorage.getItem('organizationId'));
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(formData),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetAllDevice() {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/device/get/all?orgId=' + JSON.parse(localStorage.getItem('organizationId'));
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            }
        }).then(res => res.json()).then(resp => {
            try {
                if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                    localStorage.clear();
                    window.location.replace('/');
                }
                return resp
            } catch (error) {
                addNotification(error);
            }
        })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetDevice(id) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/device/get/one?id=' + id;
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            }
        }).then(res => res.json()).then(resp => {
            try {
                if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                    localStorage.clear();
                    window.location.replace('/');
                }
                return resp
            } catch (error) {
                addNotification(error);
            }
        })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetChargingStationTransactions(assetId) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/device/asset/chargingstations/transactions/get/recent?assetId=' + assetId;
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            }
        }).then(res => res.json()).then(resp => {
            try {
                if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                    localStorage.clear();
                    window.location.replace('/');
                }
                return resp
            } catch (error) {
                addNotification(error);
            }
        })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetChargingStationSessions(assetId) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/device/asset/chargingstations/sessions/get/recent?assetId=' + assetId;
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            }
        }).then(res => res.json()).then(resp => {
            try {
                if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                    localStorage.clear();
                    window.location.replace('/');
                }
                return resp
            } catch (error) {
                addNotification(error);
            }
        })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}


export async function API_UpdateDevice(id, formData) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/device/update?id=' + id;
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(formData),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_TriggerRemoteAction(assetId, action, form) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/device/asset/chargingstation/triggerMessage';
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify({
                action: action,
                assetId: assetId,
                ...form
            }),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_UpdateDeviceAuth(formData) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/device/update/auth';
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(formData),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_AddConnector(formData) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/device/update/connector';
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(formData),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}


export async function API_GetDeviceData(id) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/device/get/data?deviceId=' + id;
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'GET',
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        }).then(res => res.json()).then(resp => {
            try {
                if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                    localStorage.clear();
                    window.location.replace('/');
                }
                return resp
            } catch (error) {
                addNotification(error);
            }
        })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetLegacyDeviceData() {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/device/get/data/legacy';
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'GET',
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        }).then(res => res.json()).then(resp => {
            try {
                if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                    localStorage.clear();
                    window.location.replace('/');
                }
                return resp
            } catch (error) {
                addNotification(error);
            }
        })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetOverviewData() {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/device/get/overview/data';
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'GET',
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        }).then(res => res.json()).then(resp => {
            try {
                if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                    localStorage.clear();
                    window.location.replace('/');
                }
                return resp
            } catch (error) {
                addNotification(error);
            }
        })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetHistoricalLogs(providerName, serialNumber) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/device/asset/chargingstation/historicalLogs?logRoute=' + providerName + '/' + serialNumber;
        let res = await fetch(url, {
            mode: "cors",
            method: 'GET',
            headers: {
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
        }).then(res => res.json()).then(resp => {
            try {
                if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                    localStorage.clear();
                    window.location.replace('/');
                }
                return resp
            } catch (error) {
                addNotification(error);
            }
        })
            .catch((err) => {
                addNotification(err);
            })
        return res;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetDeviceMetrics(obj) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/sessions/statistics';
        let form = {
            session: obj,
        }
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(form),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_RecentChargeSessions(obj) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/sessions/amounts';
        let form = {
            session: obj,
            limit: 8
        }
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(form),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_SessionByDuration(obj) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/sessions/usageByDuration';
        let form = {
            session: obj,
        }
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(form),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_MonthByMonthRevenue(obj) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/sessions/monthByMonthRevenue';
        let form = {
            session: obj,
        }
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(form),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_UserChargeByDay(obj) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/sessions/getSessionsByDay';
        let form = {
            session: obj,
        }
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(form),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetHourlyData(obj) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/sessions/getHourlyData';
        let form = {
            session: obj,
        }
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(form),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetUserMetrics(obj) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/sessions/getUserMetrics';
        let form = {
            session: obj,
        }
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(form),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}