import { API_AddConnector, API_UpdateConnector } from "../../../../../api/device";
import { useEffect, useState } from "react"

import $ from 'jquery';
import ChargingStationConfigForm from "../../../../../components/forms/deviceConfig/ChargingStationConfigForm";
import ConnectorModule from "../../../../../components/forms/deviceConfig/ConnectorModule";
import CreateModal from "../../../../../components/modal/CreateModal";
import DeviceConfigForm from "../../../../../components/forms/deviceConfig/DeviceConfigForm";
import IdTagInput from "../../../../../components/forms/deviceConfig/IdTagInput";
import Loader from "../../../../../components/loader/Loader";
import MapConfigInput from "../../../../../components/mapConfigInput/MapConfigInput";
import { addNotification } from "../../../../../utils/addNotification";

function ChargingStationConfig(props) {
    const [createType, setCreateType] = useState(['ConnectorCreate', null])
    const [modal, setModal] = useState(null);
    let editConnector = (e) => {
        try {
            $('.modalBackground').css({ display: "flex" })
            $('.createForm #connectorNumber').val(e.connectorNumber);
            $('.createForm #outputWattCategory').val(e.outputWattCategory);
            $('.createForm #connectorType').val(e.connectorType);
            setCreateType(['ConnectorUpdate', e]);
        } catch (error) {
            addNotification(error);
        }
    }
    let updateConnector = (e) => {
        try {
            let connectorForm = $('.createModal form').serializeArray();
            var formJson = {};
            for (var i = 0; i < connectorForm.length; i++) {
                formJson[connectorForm[i].name] = connectorForm[i].value;
            }
            formJson['deviceId'] = props?.asset._id;
            formJson['action'] = 'update';
            formJson['id'] = e._id;
            API_AddConnector(formJson).then(res => {
                try {
                    if (res.status === 200) {
                        props.setAsset(res.doc);
                        $('.modalBackground').hide();
                        setCreateType(['ConnectorCreate', null]);
                    }
                    else {
                        console.log(res)
                    };
                } catch (error) {
                    addNotification(error);
                }
            }).catch(error => { addNotification(error); })
        }
        catch (err) {
            console.log(err);
        }
    }
    let addConnector = () => {
        try {
            let connectorForm = $('.createModal form').serializeArray();
            var formJson = {};
            for (var i = 0; i < connectorForm.length; i++) {
                formJson[connectorForm[i].name] = connectorForm[i].value;
            }
            formJson['deviceId'] = props?.asset._id;
            API_AddConnector(formJson).then(res => {
                try {
                    document.getElementById("formCreateConnector").reset();
                    if (res.status === 200) {
                        props.setAsset(res.doc);
                        $('.modalBackground').hide();
                    }
                    else {
                        console.log(res)
                    };
                } catch (error) {
                    addNotification(error);
                }
            }).catch(error => { addNotification(error); })
        }
        catch (err) {
            console.log(err);
        }
    }
    let removeConnector = (e) => {
        try {
            let formJson = {};
            formJson['deviceId'] = props?.asset._id;
            formJson['action'] = 'delete';
            formJson['connectorNumber'] = e.connectorNumber;
            formJson['id'] = e._id;
            API_AddConnector(formJson).then(res => {
                try {
                    if (res.status === 200) {
                        props.setAsset(res.doc);
                        $('.modalBackground').hide();
                    }
                    else {
                        console.log(res)
                    };
                } catch (error) {
                    addNotification(error);
                }
            }).catch(error => { addNotification(error); })
        }
        catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        try {
            if (createType[0] === 'ConnectorCreate') setModal(< CreateModal type={'Connector'} submit={addConnector} />)
            else setModal(< CreateModal type={'Connector'} submit={() => { updateConnector(createType[1]) }} />)
        } catch (error) {
            addNotification(error?.message)
        }
    }, [createType])
    if (props.device && props.asset) {
        return (
            <div className="deviceConfigContainer">
                <div className="configContainer">
                    <div className="formContainer">
                        <DeviceConfigForm device={props.device} />
                        <ChargingStationConfigForm asset={props?.asset} />
                    </div>
                    <div className="secondaryFormContainer">
                        <MapConfigInput data={{ project: null, devices: [props.device], assets: [props?.asset] }} location={{ lat: props.device.location?.lat || 0, lon: props.device.location?.lon || 0 }} status={props?.asset?.statusNotification.toLowerCase()} />
                        {/* <IdTagInput asset={asset} removeTag={removeAuthList} /> */}
                        <ConnectorModule asset={props?.asset} removeField={removeConnector} editField={editConnector} />
                    </div>
                    {modal}
                </div>
            </div>
        )
    }
    else {
        return (
            <Loader />
        )
    }
}

export default ChargingStationConfig