import './UnderConstruction.scss';

function UnderConstruction() {
  return (
    <div className="uc_loading">
      <div className='uc_loader'>
        <div class="box box-1">
          <div class="side-left"></div>
          <div class="side-right"></div>
          <div class="side-top"></div>
        </div>
        <div class="box box-2">
          <div class="side-left"></div>
          <div class="side-right"></div>
          <div class="side-top"></div>
        </div>
        <div class="box box-3">
          <div class="side-left"></div>
          <div class="side-right"></div>
          <div class="side-top"></div>
        </div>
        <div class="box box-4">
          <div class="side-left"></div>
          <div class="side-right"></div>
          <div class="side-top"></div>
        </div>
      </div>
      <div className='uc_loaderText'>
        <p>
          Work in Progress...
        </p>
      </div>
    </div>
  )
}

export default UnderConstruction;