import { addNotification } from "../utils/addNotification";

export async function API_GetAccount(token, accountId) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/account/read/' + accountId;
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
        }).then(res => res.json()).then(resp => {
            try {
                if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                    localStorage.clear();
                    window.location.replace('/');
                }
                return resp
            } catch (error) {
                addNotification(error);
            }
        }).catch(err => { addNotification(err); })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_Signin(formData) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/auth/signin/';
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        })
            .then(res => res.json()).then(resp => {
                return resp
            })
            .catch((err) => {
                console.log(err);
            })
        return resp;
    } catch (error) {
        console.log(error);
    }
}

export async function API_Signup(formData) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/auth/signup/owner';
        let resp = fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        })
            .then(res => res.json()).then(resp => {
                return resp
            })
            .catch((err) => {
                console.log(err);
            })
        return resp;
    } catch (error) {
        console.log(error);
    }
}


export async function API_UpdateProfile(formData) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/user/set/profile';
        let resp = fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(formData),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_InviteUser(formData) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/user/invite';
        let resp = fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(formData),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_SpotlightAccounts() {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/user/get/spotlightAccounts';
        let body = {orgId: JSON.parse(localStorage.getItem('organizationId'))}
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(body),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_SpotlightAccount(accountId) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/user/get/spotlightAccount';
        let body = {accountId: accountId}
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(body),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_LighthouseAccount(accountId) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/user/get/lighthouseAccount';
        let body = {accountId: accountId}
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(body),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetAllUsernames() {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/user/get/allUsernames';
        let body = {orgId: JSON.parse(localStorage.getItem('organizationId'))}
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(body),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetPrivateUsernames(accountIds) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/user/get/privateUsernames';
        let body = {accountIds: accountIds}
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(body),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}