import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import $ from 'jquery'
import { API_GetRevenueBreakDownByDevice } from '../../../../api/project';
import HC_export_data from "highcharts/modules/export-data";
import HC_exporting from 'highcharts/modules/exporting'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from '../../../loader/Loader';
import { Rnd } from 'react-rnd'
import { addNotification } from '../../../../utils/addNotification'
import highchartsMore from 'highcharts/highcharts-more'

const RevenueBreakdownByDevice = forwardRef((props, ref) => {
    let [seriesData, setSeriesData] = useState(null)
    let [options, setOptions] = useState(null);
    let [isLoading, setIsLoading] = useState(true);
    let chartComponent = useRef();
    useImperativeHandle(
        ref,
        () => ({
            resizeChart() {
                try {
                    chartComponent.current.chart.reflow();
                } catch (error) {
                    addNotification(error);
                }
            }
        }),
    )
    const opt = {
        chart: {
            type: 'bubble',
            reflow: true,
            backgroundColor: '#e2e2e200',
            height: props.height,
        },
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
        annotations: {
            labels: {
                format: "{value}",
            },
        },
        legend: {
            enabled: false
        },
        title: {
            text: 'Revenue Breakdown By Device',
            floating: true,
            style: { fontSize: "12px" },
        },
        subtitle: {
            text: 'Breakdown of Revenue by Device within the Project',
            floating: true,
            style: { fontSize: "10px", color: "gray" },
            y: 30,
        },
        xAxis: {
            gridLineWidth: 1,
            title: {
                text: 'Revenue'
            },
            labels: {
                format: '₹{value}'
            },
            plotLines: [{
                color: 'black',
                dashStyle: 'dot',
                width: 2,
                value: 2000,
                label: {
                    rotation: 0,
                    y: 15,
                    style: {
                        fontStyle: 'italic'
                    },
                },
                zIndex: 3
            }],
        },
        yAxis: {
            startOnTick: false,
            endOnTick: false,
            title: {
                text: 'Number of Sessions'
            },
            labels: {
                format: '{value}'
            },
            maxPadding: 0.2,
            plotLines: [{
                color: 'black',
                dashStyle: 'dot',
                width: 2,
                value: 30,
                label: {
                    align: 'right',
                    style: {
                        fontStyle: 'italic'
                    },
                    x: -10
                },
                zIndex: 3
            }],
        },
        tooltip: {
            useHTML: true,
            headerFormat: '<table>',
            pointFormat: '<tr><th colspan="2"><h3>{point.device}</h3></th></tr>' +
                '<tr><th>Revenue (Monthly):</th><td>₹{point.x}</td></tr>' +
                '<tr><th>Number of Sessions (Monthly):</th><td>{point.y}</td></tr>' +
                '<tr><th>Utilization Rate (Monthly):</th><td>{point.z}%</td></tr>',
            footerFormat: '</table>',
            followPointer: true
        },
        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                }
            }
        },
        series: [{
            data: []
        }]
    };
    useEffect(() => {
        try {
            if (props.project?._id) {
                let obj = { project: props.project._id }
                API_GetRevenueBreakDownByDevice(obj).then(d => {
                    try {
                        // d.forEach(e => {
                        //     console.log(e)
                        // })
                        let _opt = opt;
                        _opt.series[0].data = d.result;
                        setOptions(_opt);
                    } catch (error) {
                        addNotification(error);
                    }
                }).catch(error => { addNotification(error); });
                //     API_SessionByDuration({ chargingStation: props.asset._id }).then(d => {
                //         console.log(d);
                //         let seriesData = [
                //             {
                //                 name: 'Light',
                //                 y: Object.keys(d.usageByDuration.light).length,
                //                 drilldown: 'Light'
                //             },
                //             {
                //                 name: 'Moderate',
                //                 y: Object.keys(d.usageByDuration.moderate).length,
                //                 drilldown: 'Moderate'
                //             },
                //             {
                //                 name: 'Heavy',
                //                 y: Object.keys(d.usageByDuration.heavy).length,
                //                 drilldown: 'Heavy'
                //             }
                //         ]
                //         let lightDrillDown = d.usageByDuration.light.map(e => {
                //             let time = new Date(e[0] * 1000)
                //             return [time.toLocaleDateString(), e[1]]
                //         })
                //         let moderateDrillDown = d.usageByDuration.moderate.map(e => {
                //             let time = new Date(e[0] * 1000)
                //             return [time.toLocaleDateString(), e[1]]
                //         })
                //         let heavyDrillDown = d.usageByDuration.heavy.map(e => {
                //             let time = new Date(e[0] * 1000)
                //             return [time.toLocaleDateString(), e[1]]
                //         })
                //         let drilldownSeriesData = [
                //             {
                //                 name: 'Light',
                //                 id: 'Light',
                //                 type: "column",
                //                 stacking: 'normal',
                //                 colorByPoint: true,
                //                 data: lightDrillDown,
                //             },
                //             {
                //                 name: 'Moderate',
                //                 id: 'Moderate',
                //                 type: "column",
                //                 stacking: 'normal',
                //                 colorByPoint: true,
                //                 data: moderateDrillDown,
                //             },
                //             {
                //                 name: 'Heavy',
                //                 id: 'Heavy',
                //                 type: "column",
                //                 stacking: 'normal',
                //                 colorByPoint: true,
                //                 data: heavyDrillDown,
                //             }
                //         ]
                //         let _opt = opt;
                //         _opt.series[0].data = seriesData;
                //         _opt.drilldown.series = drilldownSeriesData;
                //         setOptions(_opt);
                //     }).catch(err => {
                //         console.log(err);
                //     });
            }
        } catch (error) {
            addNotification(error);
        }
    }, [props.project])
    HC_exporting(Highcharts);
    HC_export_data(Highcharts);
    return (
        <div id="barChart" className='dashboardChartContainer'>
            {
                (options) ? <HighchartsReact highcharts={highchartsMore(Highcharts)} ref={chartComponent} options={options} containerProps={{ className: 'chart', style: { height: props.height } }} />
                    : <Loader />
            }
        </div>
    )
});
export default RevenueBreakdownByDevice