let callback;

export function setCallBack(method) {
    callback = method;
}

export function addNotification(message) {
    try {
        callback(message);
    } catch (error) {
        localStorage.clear();
        window.location.replace('/');
    }
}

