import { useEffect, useState } from "react"

import $ from 'jquery';
import CreateButton from "../../modal/CreateButton"
import { addNotification } from '../../../utils/addNotification'

function IdTagInput(props) {
    let [authList, setAuthList] = useState(null)
    let [asset, setAsset] = useState(props.asset)
    useEffect(() => {
        renderAuthRows();
    }, [asset])
    let renderAuthRows = () => {
        try {
            let authList;
            if (props.asset && props.asset.authList) {
                authList = props.asset.authList.map((e, i) => {
                    return (
                        <div id={e._id} key={i} className="listRow">
                            <i className="fa-solid fa-user-xmark" onClick={() => { props.removeTag(e) }}></i>
                            <p>{e.idTag}</p>
                            <p>{e.expiryDate}</p>
                        </div>
                    )
                })
            }
            else {
                authList = (
                    <div className="defaultEmptyRow">
                        Device Has no Authentication Ids
                    </div>
                )
            }
            setAuthList(authList);
            return authList;
        } catch (error) {
            addNotification(error);
        }
    }
    return (
        <div id="idTagForm" className="authList">
            <h1>Authentication List</h1>
            <div id="divIdTagContainer" className="sectionContent inputContent">
                <div className="listTable">
                    <div className="listTableHeader">
                        <p>idTag</p>
                        <p>expiryDate</p>
                    </div>
                    <div className="listTableRows">
                        {authList}
                    </div>
                </div>
                <CreateButton text="Add Authentication Id" />
            </div>
        </div>
    )
}

export default IdTagInput