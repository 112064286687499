import { useEffect, useState } from "react";
import $ from 'jquery'
import DeviceConfig from '../devices/DeviceConfig'
import { useHistory } from "react-router";
import { API_GetDevice, API_UpdateDevice } from "../../../../api/device";
import { addNotification } from '../../../../utils/addNotification'

function ControlPanel() {
    let history = useHistory();
    let [contentState, setContentState] = useState(null)
    let [editable, setEditable] = useState(false)
    let [device, setDevice] = useState(history.location.state)
    try {
        let handleEditButton = () => {
            try {
                $('#editDeviceDetails').animate({ width: 0, opacity: 0 }, () => {
                    $('#editDeviceDetails').hide();
                    $('#divEditButtons').show().animate({ opacity: 1 });
                    $('#divEditButtons button').animate({ width: "75px" })
                });
                setEditable(true);
                $('.deviceContentContainer input').prop('disabled', false).removeClass("disabledInput");
                $('.deviceContentContainer textArea').prop('disabled', false).removeClass("disabledInput")
            } catch (error) {
                addNotification(error);
            }
        }
        let handleSaveButton = () => {
            try {
                let updatedDevice = [];
                $('.deviceContentContainer input').each(function () {
                    updatedDevice.push({ 'name': $(this).attr("name"), 'value': $(this).val() });
                });
                $('.deviceContentContainer textArea').each(function () {
                    updatedDevice.push({ 'name': $(this).attr("name"), 'value': $(this).val() });
                });
                var formJson = {};
                for (var i = 0; i < updatedDevice.length; i++) {
                    formJson[updatedDevice[i].name] = updatedDevice[i].value;
                }
                // API_UpdateDevice(device.id, formJson).then((device) => {
                //     setDevice(device.device);
                //     handleCancelButton();
                // })
            } catch (error) {
                addNotification(error);
            }
        }
        let handleCancelButton = () => {
            try {
                $('#divEditButtons').animate({ opacity: 0 }, () => {
                    $('#divEditButtons').hide();
                    $('#editDeviceDetails').show().animate({ opacity: 1, width: "150px" });
                });
                setEditable(false);
                $('.deviceContentContainer input').prop('disabled', true).addClass("disabledInput");
                $('.deviceContentContainer textArea').prop('disabled', true).addClass("disabledInput")
            } catch (error) {
                addNotification(error);
            }
        }

        let deviceContent = () => {
            return (
                <div></div>
            )

        }

        return (
            <div id="divDeviceContainer" className="deviceContainer" >
                <div className="deviceHeader">
                    <h1>Control Panel</h1>
                    <div id="divManageButtons" className="manageDeviceButtons">
                        <button id="editDeviceDetails" className="primaryButton" onClick={() => { handleEditButton() }}>Edit Device</button>
                        <div id="divEditButtons" className="editButtons">
                            <button id="saveDeviceDetails" style={{ width: "0%" }} className="primaryButton" onClick={() => { handleSaveButton() }}><i className="fas fa-save"></i></button>
                            <button id="cancelDeviceDetails" style={{ width: "0%" }} className="secondaryButton" onClick={() => { handleCancelButton() }}><i className="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>
                <div className="deviceMenu">
                    <div id="deviceMenuTestAlgo" className="activeMenuItem_Click" data-menu-state="Dashboard">
                        <h1>Dashboard</h1>
                        <hr></hr>
                    </div>
                    <div id="deviceMenuConfig" data-menu-state="DeviceConfig">
                        <h1>Device Configuration</h1>
                        <hr></hr>
                    </div>
                    <div id="deviceMenuFailureFormula" data-menu-state="FailureFormula">
                        <h1>Failure Formula</h1>
                        <hr></hr>
                    </div>
                    <div id="deviceMenuRepairData">
                        <h1>Repair Data</h1>
                        <hr></hr>
                    </div>
                    <div id="deviceMenuAlertConfig">
                        <h1>Alert Configuration</h1>
                        <hr></hr>
                    </div>
                </div>
                <div className="deviceContentContainer">
                    {deviceContent()}
                </div>
            </div>
        )
    } catch (error) {
        addNotification(error);
    }
}

export default ControlPanel