import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { API_ProjectRecentChargeSessions } from '../../../../api/project';
import HC_export_data from "highcharts/modules/export-data";
import HC_exporting from 'highcharts/modules/exporting'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from '../../../loader/Loader';
import { addNotification } from '../../../../utils/addNotification'
import highchartsMore from 'highcharts/highcharts-more';

const RecentChargeSessions = forwardRef((props, ref) => {
    let [seriesData, setSeriesData] = useState({ column: [], line: [] })
    let chartComponent = useRef();
    let now = new Date();
    let [options, setOptions] = useState(null);
    let opt = {
        chart: {
            zoomType: 'xy',
            backgroundColor: '#e2e2e200',
            plotBorderWidth: 0,
            reflow: true
        },
        time: {
            timezoneOffset: now.getTimezoneOffset(),
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbolFill: "#989898",
                    theme: {
                        fill: "#e2e2e200",
                        padding: 5,
                        stroke: "none",
                    },
                    symbol: 'menu',
                    menuItems: [
                        "viewFullscreen",
                        "printChart", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG",
                        {
                            text: "Download CSV File",
                            onclick() {
                                let csv = this.getCSV();
                                const blob = new Blob([csv], { type: 'text/csv' });

                                // Creating an object for downloading url
                                const url = window.URL.createObjectURL(blob)

                                // Creating an anchor(a) tag of HTML
                                const a = document.createElement('a')

                                // Passing the blob downloading url 
                                a.setAttribute('href', url)

                                // Setting the anchor tag attribute for downloading
                                // and passing the download file name
                                a.setAttribute('download', 'download.csv');

                                // Performing a download with click
                                a.click()
                            }
                        },
                    ],
                    x: 15,
                    y: -15
                }
            }
        },
        title: {
            text: ' ',
            align: 'center',
        },
        subtitle: {
            text: 'Recent Ten Charge Sessions for this device, with the total session fee, power consumption, and duration.',
            floating: false,
            align: 'center',
            style: { fontSize: "10px", color: "gray" },
        },
        credits: {
            enabled: false
        },
        xAxis: [{
            crosshair: true,
            labels: {
                enabled: false
            },
        }],
        yAxis: [{ // Primary yAxis
            gridLineWidth: 1,
            labels: {
                format: '{value}',
                style: {
                    color: '#8fc48d'
                }
            },
            title: {
                text: 'Session Fee (₹)',
                style: {
                    color: '#8fc48d'
                }
            },
        }, { // Secondary yAxis
            gridLineWidth: 1,
            title: {
                text: 'KiloWatts',
                style: {
                    color: '#008000'
                }
            },
            labels: {
                format: '{value} (kWh)',
                style: {
                    color: '#008000'
                }
            },
            opposite: true

        }],
        tooltip: {
            shared: true,
            // formatter: function() {
            //     return 'The value for <b>' + this.x + '</b> is <b>' + this.y + '</b>, in series '+ this.series.name;
            // }
        },
        legend: {
            // layout: 'vertical',
            // align: 'left',
            // x: 80,
            // verticalAlign: 'top',
            // y: 55,
            floating: false,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                'rgba(255,255,255,0.25)'
        },
        series: [
            {
                name: 'Charge Sessions',
                type: 'column',
                data: seriesData.column,
                tooltip: {
                    valuePrefix: '₹'
                },
                marker: {
                    enabled: false
                },
                color: '#8fc48d',
                // width: '50px',
                // pointWidth: 50,
                grouping: false,
            },
            {
                name: 'Energy Consumption',
                type: 'spline',
                yAxis: 1,
                data: seriesData.line,
                tooltip: {
                    valueSuffix: ' kWh'
                },
                color: '#008000',
                dashStyle: 'shortdot',
                zIndex: 0,

            }],
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        floating: false,
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom',
                        x: 0,
                        y: 0
                    },
                    yAxis: [{
                        labels: {
                            align: 'right',
                            x: 0,
                            y: -6
                        },
                        showLastLabel: false
                    }, {
                        labels: {
                            align: 'left',
                            x: 0,
                            y: -6
                        },
                        showLastLabel: false
                    }, {
                        visible: false
                    }]
                }
            }]
        }
    };
    useImperativeHandle(
        ref,
        () => ({
            resizeChart() {
                try {
                    chartComponent.current.chart.reflow();
                } catch (error) {
                    addNotification(error);
                }
            }
        }),
    )
    useEffect(() => {
        try {
            if (props.project._id) {
                API_ProjectRecentChargeSessions({ project: props.project._id, limit: 10 }).then(d => {
                    try {
                        function sortFunction(a, b) {
                            if (a[0] === b[0]) {
                                return 0;
                            }
                            else {
                                return (a[0] < b[0]) ? -1 : 1;
                            }
                        }
                        let columnSeriesData = d.amounts.map(e => {
                            let time = new Date(e.endSessionTime * 1000);
                            time.toLocaleString();
                            return [time.toLocaleString(), e.amount]
                            //return [{x: time.toLocaleString(), y: e.amount, duration: e.duration }]
                        });
                        let lineSeriesData = d.amounts.map(e => {
                            let time = new Date(e.endSessionTime * 1000);
                            return [time.toLocaleString(), e.energy]
                            //return [{x: time.toLocaleString(), y: e.energy, duration: e.duration }]
                        });
                        columnSeriesData = columnSeriesData.sort(sortFunction).slice(0,10);
                        lineSeriesData = lineSeriesData.sort(sortFunction).slice(0,10);
                        setSeriesData({ column: columnSeriesData, line: lineSeriesData });
                        let _opt = opt;
                        _opt.series[0].data = columnSeriesData;
                        _opt.series[1].data = lineSeriesData;
                        setOptions(_opt);
                    } catch (error) {
                        addNotification(error);
                    }
                }).catch(error => { addNotification(error); });
            }
        } catch (error) {
            addNotification(error);
        }
    }, [props.project._id])
    HC_exporting(Highcharts);
    HC_export_data(Highcharts);
    return (
        <div id="lineAreaChart" className='dashboardChartContainer'>
            {
                (options) ? <HighchartsReact highcharts={highchartsMore(Highcharts)} ref={chartComponent} options={options} containerProps={{ className: 'chart', style: { height: props.height } }} />
                    : <div></div>
            }
        </div>
    )
});

export default RecentChargeSessions;