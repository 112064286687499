import '../Forms.css';

import {
    AutoField,
    AutoFields,
    AutoForm,
    ErrorsField,
    LongTextField,
    SubmitField
} from 'uniforms-semantic';
import { useEffect, useState } from 'react';

import $ from 'jquery';
import AddressSearchBar from '../../mapConfigInput/AddressSearchBar';
import { chargingStationSchema } from './ChargingStationConfigSchema';
import { addNotification } from '../../../utils/addNotification';

function ChargingStationConfigForm(props) {
    let [schema, setSchema] = useState(chargingStationSchema(props.asset));
    let setFields = () => {try {
        let schemaKeys = Object.keys(schema.getField().properties);
        return schemaKeys.map((e, i) => {
            let isReadOnly = schema.schema.properties[e].readOnly ? true : false
            if(props.asset[e]) {
                if(schema.schema.properties[e].format === 'date-time'){
                    let c = Date.parse(props.device[e]);
                    return (<AutoField key={i} name={e} value={new Date(c)} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly}/>)
                }
                else if (e === 'address') {
                    return (<AddressSearchBar name={e} label={'Address'} value={props.device[e]}/>)
                }
                return(<AutoField key={i} name={e} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly}/>)
            }
            else {
                return(<AutoField key={i} name={e} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly}/>)
            }
        })
    } catch (error) {
        addNotification(error);
    }}
    useEffect(() => {
        $('#charginStationConfigForm input[type="submit"]').remove();
    }, [])
    return (
        <div className='manageForm'>
            <AutoForm id="charginStationConfigForm" schema={schema}>
                {setFields()}
            </AutoForm>
        </div>
    )
}

export default ChargingStationConfigForm