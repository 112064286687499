import '../Forms.css';

import {
    AutoField,
    AutoFields,
    AutoForm,
    ErrorsField,
    LongTextField,
    SubmitField
} from 'uniforms-semantic';
import { useEffect, useState } from 'react';

import $ from 'jquery';
import AddressSearchBar from '../../mapConfigInput/AddressSearchBar';
import { projectSchema } from './ProjectConfigSchema';
import { addNotification } from '../../../utils/addNotification';

function ProjectConfigForm(props) {
    let [schema, setSchema] = useState(projectSchema(props.project));
    let setFields = () => {
        try {
            let schemaKeys = Object.keys(schema.getField().properties);
            return schemaKeys.map((e, i, arr) => {
                let isReadOnly = schema.schema.properties[e].readOnly ? true : false
                if (props.project[e]) {
                    if (schema.schema.properties[e].format === 'date-time') {
                        let c = Date.parse(props.project[e]);
                        return (<AutoField name={e} key={i} value={new Date(c)} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly} />)
                    }
                    else if (e === 'projectDescription') {
                        return (<LongTextField name={e} key={i} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly} />)
                    }
                    else if (e === 'address') {
                        return (<AddressSearchBar name={e} label={'Address'} inputvalue={props.project[e]} />)
                    }
                    else {
                        return (<AutoField name={e} key={i} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly} />)
                    }
                }
                // else if (e === 'address' || e === 'lat' || e === 'lon' || e === 'location') {
                //     return null;
                // }
                else {
                    if (schema.schema.properties[e].format === 'date-time') {
                        return (<AutoField name={e} key={i} value={new Date()} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly} />)
                    }
                    else if (e === 'projectDescription') {
                        return (<LongTextField name={e} key={i} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly} />)
                    }
                    else if (e === 'address') {
                        return (<AddressSearchBar name={e} label={'Address'} />)
                    }
                    else {
                        return (<AutoField name={e} key={i} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly} />)
                    }
                }
            })
        } catch (error) {
            addNotification(error);
        }
    }
    useEffect(() => {
        try { $('#projectConfigForm input[type="submit"]').remove(); } catch (error) {
            addNotification(error);
        }
    }, [])
    useEffect(() => {
        try { if (props.project) setSchema(projectSchema(props.project)) } catch (error) {
            addNotification(error);
        }
    }, [props.project])
    return (
        <div className='manageForm'>
            <AutoForm id="projectConfigForm" schema={schema} >
                {setFields()}
            </AutoForm>
        </div>
    )
}

export default ProjectConfigForm