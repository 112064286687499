import { useEffect, useState } from "react"

import $ from 'jquery';
import CreateButton from "../../modal/CreateButton"
import { addNotification } from "../../../utils/addNotification";

function ConnectorModule(props) {
    let [connectorList, setConnectorList] = useState(null)
    let renderConnectorRows = () => {
        try {
            let connectorList;
            if (props.asset && props.asset.connectors) {
                connectorList = props.asset.connectors.map((e, i) => {
                    return (
                        <div id={e._id} key={i} className="listRow">
                            <i className="fa-solid fa-pen" onClick={() => { props.editField(e); }}></i>
                            <i className="fa-solid fa-circle-xmark" style={{ left: "15px" }} onClick={() => { props.removeField(e); }}></i>
                            <p>{e.connectorNumber}</p>
                            <p className={(e.chargingStatus === 'Unavailable' || e.chargingStatus === 'Faulted') ? 'Inactive' : 'Active'}><i style={{ marginRight: "7px" }} className="fa-solid fa-circle-dot"></i>{e.chargingStatus}</p>
                            <p>{e.outputWattCategory}</p>
                            <p>{e.connectorType}</p>
                        </div>
                    )
                })
            }
            else {
                connectorList = (
                    <div className="defaultEmptyRow">
                        Device Has No Saved Connectors
                    </div>
                )
            }
            setConnectorList(connectorList);
            return connectorList;
        } catch (error) {
            addNotification(error);
        }
    }
    useEffect(() => {
        renderConnectorRows();
    }, [props.asset])
    return (
        <div id="ConnectorList" className="authList">
            <h1>Connectors</h1>
            <div id="divConnectorContainer" className="sectionContent inputContent">
                <div className="listTable">
                    <div className="listTableHeader">
                        <i style={{ width: '20%' }}></i>
                        <p>#</p>
                        <p>Status</p>
                        <p>Capacity</p>
                        <p>Type</p>
                    </div>
                    <div className="listTableRows">
                        {connectorList}
                    </div>
                </div>
                <CreateButton text="Add Connector" />
            </div>
        </div>
    )
}

export default ConnectorModule