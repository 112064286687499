import './MapConfigInput.css';

import React, { useEffect, useRef, useState } from "react";

import $ from 'jquery';
import { addNotification } from '../../utils/addNotification'
import mapboxgl from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"

mapboxgl.accessToken = process.env.REACT_APP_MAP_API_KEY;
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line

function MapConfigInput(props) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const [location, setLocation] = useState([78.4740610, 17.360589]);
  const [stations, setStations] = useState(null);
  let handleLocation = () => {
    try {
      if (props.location) {
        if (props.location.lon && props.location.lat) setLocation([props.location.lon, props.location.lat]);
      }
    } catch (error) {
      addNotification(error);
    }
  }
  function addChargingStations(map) {
    try {
      map.current.addSource("chargersSource", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [
          ]
        }
      });
      map.current.addLayer({
        "id": "chargersLayer",
        "type": "symbol",
        "source": "chargersSource",
        "layout": {
          'icon-image': [
            'match',
            ['get', 'chargeStatus'],
            'unavailable', 'chargepoint-unavailable',
            'inactive', 'chargepoint-inactive',
            'faulted', 'chargepoint-inactive',
            'available', 'chargepoint-active',
            'charging', 'chargepoint-inuse',
            'preparing', 'chargepoint-inuse',
            'finishing', 'chargepoint-inuse',
            'suspendedevse', 'chargepoint-inuse',
            'suspendedev', 'chargepoint-inuse',
            'chargepoint-unavailable',
          ],
          "icon-allow-overlap": true,
          "icon-size": 0.5,
          "visibility": "visible",
          "symbol-sort-key": 3,
          "symbol-z-order": 'auto',
        },
        "paint": {
          "icon-color": '#345e3f',
        }
      }, "road-label-navigation");
    } catch (error) {
      addNotification(error);
    }
  }
  let renderLatLon = () => {
    try {
      if (location[0] && location[1]) {
        return (
          <div>
            Longitude: {location[0].toFixed(4)} | Latitude: {location[1].toFixed(4)}
          </div>
        )
      }
      else {
        return (
          <div>
            This device has no designated location set.
          </div>
        )
      }
    } catch (error) {
      addNotification(error);
    }
  }
  useEffect(() => {
    try {
      handleLocation();
      if (map.current) return;
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mrpurple/ckkir186f14lx17nx1s1qk47z',
        center: location,
        maxPitch: 70,
      });
      map.current.on("load", () => {
        try {
          addChargingStations(map);
        } catch (error) {
          addNotification(error);
        }
      });
    } catch (error) {
      addNotification(error);
    }
  }, []);
  useEffect(() => {
    try {
      if (stations) {
        if (map.current._loaded) map.current.getSource('chargersSource').setData(stations);
        else {
          map.current.on("load", () => {
            try {
              map.current.getSource('chargersSource').setData(stations);
            } catch (error) {
              addNotification(error);
            }
          });
        }
      }
    } catch (error) {
      addNotification(error);
    }
  }, [stations])
  useEffect(() => {
    try {
      let chargerStations = {
        type: "FeatureCollection",
        features: props.data.devices.map((e, i) => {
          let chargeStatus;
          if (e.deviceStatus === 'Active') chargeStatus = e.assetId?.statusNotification.toLowerCase()
          else if (e.deviceStatus === 'Inactive') chargeStatus = 'unavailable'
          else {
            chargeStatus = 'faulted'
          }
          return {
            id: i,
            type: 'Feature',
            properties: {
              chargeStatus: chargeStatus || 'unavailable',
            },
            geometry: {
              type: 'Point',
              coordinates: [e?.location?.lon || 0, e?.location?.lat || 0],//storeLocation
            }
          }
        })
      }
      setStations(chargerStations);
      if (map.current.getSource('chargersSource') && map.current.isSourceLoaded('chargersSource')) {
        map.current.getSource('chargersSource').setData(chargerStations);
      }
      map.current.once('moveend', function () {
        // map.current.setMinZoom(10);
      });
      var bounds = new mapboxgl.LngLatBounds();

      chargerStations.features.forEach(function (feature) {
        bounds.extend(feature.geometry.coordinates);
      });
      map.current.flyTo({
        center: [props.location.lon || 78.4740610, props.location.lat || 17.360589], zoom: 15, pitch: 50,
        duration: 2500,
        easing: function (t) {
          return t;
        },
        essential: true
      })
      if (!bounds.isEmpty()) map.current.fitBounds(bounds, { padding: 100 })
    } catch (error) {
      addNotification(error);
    }
  }, [props.data])
  return (
    <div className="configInputMap">
      <div className="parentMapContainer" >
        <h1>Device Locations</h1>
        <div className="latlonBar">
          {renderLatLon()}
        </div>
        <div ref={mapContainer} className="mapContainer">
        </div>
      </div>
    </div>
  );
}

export default MapConfigInput;