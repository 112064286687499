import './OverviewMap.css';

import $, { easing } from 'jquery';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import { addNotification } from '../../utils/addNotification';

mapboxgl.accessToken = process.env.REACT_APP_MAP_API_KEY;
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line

const OverviewMap = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        resetMapClick() {
            try {
                handleMapClick()
            } catch (error) {
                addNotification(error);
            }
        }
    }));
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [location, setLocation] = useState([78.4740610, 17.360589]);
    const [stations, setStations] = useState(null);
    const [inventory, setInventory] = useState(props.inventory);
    const [projects, setProjects] = useState(props.projects);
    let renderStations = () => {
        try {
            if (props.inventory && props.projects) {
                let inv = props.inventory.filter(e => e.asset !== null);
                let stationsList = inv.map((e, i) => {
                    if (e.asset && e.device) {
                        let loc, chargingStatus;
                        if (e.device.location) {
                            if (e.device.deviceStatus === 'Active') chargingStatus = e.asset.statusNotification
                            else if (e.device.deviceStatus === 'Inactive') chargingStatus = 'unavailable'
                            else {
                                chargingStatus = 'faulted'
                            }
                            loc = e.device.location;
                            return {
                                id: i+1,
                                type: 'Feature',
                                properties: {
                                    chargeStatus: chargingStatus.toLowerCase() || 'unavailable', //e.asset.chargingStatus.toLowerCase(),
                                    chargeType: e.asset.outputType,
                                    id: e.device._id,
                                    name: e.device.deviceName,
                                    location: e.device.address,
                                    capacity: e.asset.outputWattCategory + ' kWh',
                                },
                                geometry: {
                                    type: 'Point',
                                    coordinates: [loc.lon, loc.lat]
                                }
                            }
                        }
                    }
                })
                let stationsListMod = stationsList.filter(e => e !== null)
                let modProjects = projects.map((e, i) => {
                    if (!e.location)
                        e.location = [78.4740610, 17.360589]
                    let count = {
                        'available': 0,
                        'used': 0
                    }
                    for (let ele of e.devices) {
                        if (ele.assetId.statusNotification.toLowerCase() === 'available')
                            count.available++;
                        else if (ele.assetId.statusNotification.toLowerCase() !== 'unavailable')
                            count.used++;
                    }
                    return {
                        id: i+1,
                        type: 'Feature',
                        properties: {
                            chargeStatus: count.available !== 0 ? 'available' : count.used !== 0 ? 'charging' : 'unavailable',
                            id: e._id,
                            project: e,
                        },
                        geometry: {
                            type: 'Point',
                            coordinates: [e.location.lon, e.location.lat]
                        }
                    }
                })
                setStations(stationsListMod);
                setProjects(modProjects)
            }
        } catch (error) {
            addNotification(error);
        }
    }
    function addCharginStations(map) {
        try {
            map.current.addSource("chargersSource", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [
                    ]
                }
            });
            map.current.addSource("projectsSource", {
                type: "geojson",
                data: {
                    type: "FeatureCollection",
                    features: [
                    ]
                }
            });
            map.current.addLayer({
                "id": "chargersLayer",
                "type": "symbol",
                "source": "chargersSource",
                "layout": {
                    'icon-image': [
                        'match',
                        ['get', 'chargeStatus'],
                        'unavailable', 'chargepoint-unavailable',
                        'inactive', 'chargepoint-inactive',
                        'faulted', 'chargepoint-inactive',
                        'available', 'chargepoint-active',
                        'charging', 'chargepoint-inuse',
                        'preparing', 'chargepoint-inuse',
                        'finishing', 'chargepoint-inuse',
                        'suspendedevse', 'chargepoint-inuse',
                        'suspendedev', 'chargepoint-inuse',
                        'chargepoint-unavailable'
                    ],
                    "icon-allow-overlap": true,
                    "icon-size": 0.6,
                    "visibility": "none",
                    "symbol-sort-key": 3,
                    "symbol-z-order": 'auto',
                },
                "paint": {
                    "icon-color": '#345e3f',
                }
            }, "road-label-navigation");

            map.current.addLayer({
                "id": "projectsLayer",
                "type": "symbol",
                "source": "projectsSource",
                "layout": {
                    'icon-image': [
                        'match',
                        ['get', 'chargeStatus'],
                        'unavailable', 'chargepoint-unavailable',
                        'inactive', 'chargepoint-inactive',
                        'faulted', 'chargepoint-inactive',
                        'available', 'chargepoint-active',
                        'charging', 'chargepoint-inuse',
                        'preparing', 'chargepoint-inuse',
                        'finishing', 'chargepoint-inuse',
                        'suspendedevse', 'chargepoint-inuse',
                        'suspendedev', 'chargepoint-inuse',
                        'chargepoint-unavailable'
                    ],
                    "icon-allow-overlap": true,
                    "icon-size": 0.6,
                    "visibility": "visible",
                    "symbol-sort-key": 3,
                    "symbol-z-order": 'auto',
                },
                "paint": {
                    "icon-color": '#345e3f',
                }
            }, "road-label-navigation");
        } catch (error) {
            addNotification(error);
        }
    }
    function handleMapClick(e) {
        try {
            if (e) {
                let zoom = map.current.getZoom();
                console.log(e);
                // Set `bbox` as 5px reactangle area around clicked point.
                // const bbox = [
                //     [e.point.x + zoom, e.point.y + zoom],
                //     [e.point.x + zoom, e.point.y + zoom]
                // ];
                // Find features intersecting the bounding box.
                const selectedFeatures = map.current.queryRenderedFeatures(e.point, {
                    layers: ['chargersLayer', 'projectsLayer']
                });
                let distance = 999999;
                let feature = null;
                for (let i in selectedFeatures) {
                    let dis = e.lngLat.distanceTo({ lng: selectedFeatures[i].geometry.coordinates[0], lat: selectedFeatures[i].geometry.coordinates[1] })
                    if (dis < distance) {
                        distance = dis;
                        feature = selectedFeatures[i]
                    }
                }
                if (feature) {
                    map.current.setLayoutProperty(selectedFeatures[0].layer.id, 'icon-image',
                        [
                            'match',
                            ['get', 'chargeStatus'],
                            'unavailable',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-unavailable'
                            ],
                            'inactive',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-inactive'
                            ],
                            'faulted',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-inactive'
                            ],
                            'available',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-active'
                            ],
                            'charging',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-inuse'
                            ],
                            'preparing',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-inuse'
                            ],
                            'finishing',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-inuse'
                            ],
                            'suspendedevse',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-inuse'
                            ],
                            'suspendedev',
                            [
                                'match',
                                ['id'],
                                selectedFeatures[0].id,
                                'chargepoint-selected',
                                'chargepoint-inuse'
                            ],
                            'chargepoint-unavailable'
                        ]
                    )
                    const coordinates = selectedFeatures[0].geometry.coordinates.slice();
                    // Ensure that if the map is zoomed out such that multiple
                    // copies of the feature are visible, the popup appears
                    // over the copy being pointed to.
                    map.current.flyTo({
                        center: [feature.geometry.coordinates[0], feature.geometry.coordinates[1] - 0.001],
                        speed: 10,
                        zoom: 17,
                        easing(t) {
                            return t;
                        }
                    });
                    map.current.resize();
                    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                    }
                    if (feature.layer.id === 'chargersLayer') {
                        let activeDevice = inventory.find(e => e.device._id === selectedFeatures[0].properties.id)
                        props.setModalContent({ device: activeDevice.device, asset: activeDevice.asset });
                    }
                    else if (feature.layer.id === 'projectsLayer') {
                        props.setProjectModalContent({ project: JSON.parse(selectedFeatures[0].properties.project), chargeStatus: selectedFeatures[0].properties.chargeStatus });
                    }
                }
                else {
                    map.current.setLayoutProperty('chargersLayer', 'icon-image',
                        [
                            'match',
                            ['get', 'chargeStatus'],
                            'unavailable', 'chargepoint-unavailable',
                            'inactive', 'chargepoint-inactive',
                            'faulted', 'chargepoint-inactive',
                            'available', 'chargepoint-active',
                            'charging', 'chargepoint-inuse',
                            'preparing', 'chargepoint-inuse',
                            'finishing', 'chargepoint-inuse',
                            'suspendedevse', 'chargepoint-inuse',
                            'suspendedev', 'chargepoint-inuse',
                            'chargepoint-unavailable'
                        ]
                    )

                    map.current.setLayoutProperty('projectsLayer', 'icon-image',
                        [
                            'match',
                            ['get', 'chargeStatus'],
                            'unavailable', 'chargepoint-unavailable',
                            'inactive', 'chargepoint-inactive',
                            'faulted', 'chargepoint-inactive',
                            'available', 'chargepoint-active',
                            'charging', 'chargepoint-inuse',
                            'preparing', 'chargepoint-inuse',
                            'finishing', 'chargepoint-inuse',
                            'suspendedevse', 'chargepoint-inuse',
                            'suspendedev', 'chargepoint-inuse',
                            'chargepoint-unavailable'
                        ]
                    )
                    $('#modalStationDetails').animate({ height: '0%' })
                    $('#sensorDetailModal').animate({ height: '0px' }, () => { $('#sensorDetailModal').animate({ opacity: 1 }); })
                }
            }
            else {
                map.current.setLayoutProperty('chargersLayer', 'icon-image',
                    [
                        'match',
                        ['get', 'chargeStatus'],
                        'unavailable', 'chargepoint-unavailable',
                        'inactive', 'chargepoint-inactive',
                        'faulted', 'chargepoint-inactive',
                        'available', 'chargepoint-active',
                        'charging', 'chargepoint-inuse',
                        'preparing', 'chargepoint-inuse',
                        'finishing', 'chargepoint-inuse',
                        'suspendedevse', 'chargepoint-inuse',
                        'suspendedev', 'chargepoint-inuse',
                        'chargepoint-unavailable'
                    ]
                )

                map.current.setLayoutProperty('projectsLayer', 'icon-image',
                        [
                            'match',
                            ['get', 'chargeStatus'],
                            'unavailable', 'chargepoint-unavailable',
                            'inactive', 'chargepoint-inactive',
                            'faulted', 'chargepoint-inactive',
                            'available', 'chargepoint-active',
                            'charging', 'chargepoint-inuse',
                            'preparing', 'chargepoint-inuse',
                            'finishing', 'chargepoint-inuse',
                            'suspendedevse', 'chargepoint-inuse',
                            'suspendedev', 'chargepoint-inuse',
                            'chargepoint-unavailable'
                        ]
                    )
                $('#modalStationDetails').animate({ height: '0%' })
                $('#sensorDetailModal').animate({ height: '0px' }, () => { $('#sensorDetailModal').animate({ opacity: 1 }); })
            }
        } catch (error) {
            addNotification(error);
        }
    }
    function handleMapNavs(e, option) {
        e.preventDefault();
        e.stopPropagation();
        $('#sensorDetailModal').animate({ height: '0px' }, () => { $('#sensorDetailModal').animate({ opacity: 1 }); })
        $(e.target).addClass('active').siblings('.active').removeClass('active');

        switch (option) {
            case 'Devices': {
                map.current.setLayoutProperty('chargersLayer', 'visibility', 'visible');
                map.current.setLayoutProperty('projectsLayer', 'visibility', 'none');
                break;
            }
            case 'Projects': {
                map.current.setLayoutProperty('chargersLayer', 'visibility', 'none');
                map.current.setLayoutProperty('projectsLayer', 'visibility', 'visible');
                break;
            }
            default:
                console.log('Invalid map input');
        }

    };
    useEffect(() => {
        try {
            renderStations();
        } catch (error) {
            addNotification(error);
        }
    }, [])
    useEffect(() => {
        try {
            if (map.current) return;
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mrpurple/ckkir186f14lx17nx1s1qk47z',
                maxPitch: 70,
            });
            map.current.on("load", () => {
                try {
                    addCharginStations(map);
                    map.current.on('click', (e) => {
                        try {
                            handleMapClick(e)
                        } catch (error) {
                            addNotification(error);
                        }
                    });
                    map.current.on('mouseenter', 'chargersLayer', () => {
                        try {
                            map.current.getCanvas().style.cursor = 'pointer';
                        } catch (error) {
                            addNotification(error);
                        }
                    });

                    map.current.on('mouseenter', 'projectsLayer', () => {
                        try {
                            map.current.getCanvas().style.cursor = 'pointer';
                        } catch (error) {
                            addNotification(error);
                        }
                    });
                    map.current.on('zoomend', (e) => {
                        try {
                            map.current.resize();
                        } catch (error) {
                            addNotification(error);
                        }
                    })
                    // Change it back to a pointer when it leaves.
                    map.current.on('mouseleave', 'chargersLayer', () => {
                        try {
                            map.current.getCanvas().style.cursor = '';
                        } catch (error) {
                            addNotification(error);
                        }
                    });

                    map.current.on('mouseleave', 'projectsLayer', () => {
                        try {
                            map.current.getCanvas().style.cursor = '';
                        } catch (error) {
                            addNotification(error);
                        }
                    });
                } catch (error) {
                    addNotification(error);
                }
            });
        } catch (error) {
            addNotification(error);
        }
    });
    useEffect(() => {
        try {
            if (stations && projects) {
                let _stations = {
                    type: "FeatureCollection",
                    features: stations
                }
                let _projects = {
                    type: "FeatureCollection",
                    features: projects
                }
                map.current.on("load", () => {
                    try {
                        map.current.getSource('chargersSource').setData(_stations);
                        map.current.getSource('projectsSource').setData(_projects);
                    } catch (error) {
                        addNotification(error);
                    }
                });
                if (stations.length > 0) {
                    var bounds = new mapboxgl.LngLatBounds();
                    stations.forEach(function (feature) {
                        bounds.extend([feature.geometry.coordinates[0], feature.geometry.coordinates[1]]);
                    });
                    if (bounds) map.current.fitBounds(bounds, { padding: 50 });
                }
            }
        } catch (error) {
            addNotification(error);
        }
    }, [stations, projects])
    useEffect(() => {
        try {
            map.current.once('moveend', function () {
                // map.current.setMinZoom(9);
            });
        } catch (error) {
            addNotification(error);
        }
    }, [location])
    return (
        <div className="parentMapContainer" >
            <nav id="menu">
                <a id='aProjects' href='#' className='active' onClick={(e) => { handleMapNavs(e, 'Projects') }}>Projects</a>
                <a id='aDevices' href='#' onClick={(e) => { handleMapNavs(e, 'Devices') }}>Devices</a>
            </nav>
            <div ref={mapContainer} className="mapContainer" />
        </div>
    );
});

export default OverviewMap;