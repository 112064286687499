import $, { data } from "jquery"
import { useEffect, useState } from "react"

import { API_GetAllProjects } from "../../api/project"
import { API_GetOrganizationAccounts } from "../../api/org"
import { API_GetAllUsernames, API_SpotlightAccounts } from "../../api/account"
import AddressSearchBar from "../mapConfigInput/AddressSearchBar"
import DynamicSelect from "../DynamicSelect"
import { addNotification } from "../../utils/addNotification"
import AuthModal from "./AuthModal"



function CreateModal(props) {
    let [lighthouseAccounts, setLighthouseAccounts] = useState(null)
    let [spotlightAccounts, setSpotlightAccounts] = useState(null)
    let accountIdList = {}

    let renderForm = () => {
        try {
            if (props.type === "Person") {
                return (
                    <form id="formInvitePerson" className="createForm">
                        <i
                            onClick={() => {
                                $(".modalBackground").hide()
                            }}
                            className="fas fa-times"
                        ></i>
                        <h1>Invite a Member to this Organization</h1>
                        <div id="divEmailInput" className="formInputs">
                            <label id="label_email" htmlFor="email">
                                Email
                            </label>
                            <input
                                id="email"
                                name="email"
                                type="email"
                                placeholder="Input an Email Address to Invite"
                                data-error="Email is a Required Field"
                                data-norm="Input an email address to Invite"
                            />
                        </div>
                        <div id="divJobTitleInput" className="formInputs">
                            <label id="label_jobTitle" htmlFor="jobTitle">
                                JobTitle
                            </label>
                            <input
                                id="jobTitle"
                                name="jobTitle"
                                placeholder="Input the Users Job Title"
                                data-error="Job Title is a Required Field"
                                data-norm="Input an Job Title to Invite"
                            />
                        </div>
                        <div id="divRoleInput" className="formInputs">
                            <label id="label_role" htmlFor="role">
                                Role Permissions
                            </label>
                            <select
                                id="role"
                                name="role"
                                data-error="Select an Appropriate Role"
                                data-norm="Choose a Role"
                            >
                                <option value="None">Choose a Role</option>
                                <option value="moderator">Moderator</option>
                                <option value="employee">Employee</option>
                                <option value="guest">Guest</option>
                            </select>
                        </div>
                        <div id="divMessageInput" className="formInputs">
                            <label id="label_message" htmlFor="message">
                                Message
                            </label>
                            <textarea
                                id="message"
                                name="message"
                                rows="10"
                                placeholder="Write a short invitation message for the reciepient"
                                data-error="Write a Short Message to the Reciepient"
                                data-norm="Write a short invitation message for the reciepient"
                            />
                        </div>
                    </form>
                )
            } else if (props.type === "Device") {
                return (
                    <div>
                        <div className="modalHeader">
                            <i
                                onClick={() => {
                                    $(".modalBackground").hide()
                                    $(".inputError")
                                        .removeClass("inputError")
                                        .each((e) => {
                                            $(e).attr(
                                                "placeholder",
                                                $(e).attr("data-norm")
                                            )
                                        })
                                    $(".createModal form")[0].reset()
                                    $(".createModal [id*='Container']").css({
                                        display: "none",
                                        opacity: "0",
                                        height: "0",
                                    })
                                }}
                                className="fas fa-times"
                            ></i>
                            <h1>Create New Device</h1>
                        </div>
                        <form id="formCreateDevice" className="createForm">
                            <div className="formInputs">
                                <label
                                    id="label_deviceName"
                                    htmlFor="deviceName"
                                >
                                    Device Name
                                </label>
                                <input
                                    id="deviceName"
                                    name="deviceName"
                                    placeholder="Input a Name for this device"
                                    data-error="Device Name is a Required Field"
                                    data-norm="Input a Name for this device"
                                />
                            </div>
                            <div className="formInputs">
                                <label
                                    id="label_providerName"
                                    htmlFor="providerName"
                                >
                                    Provider Name
                                </label>
                                {/* <input id="providerName" name="providerName" placeholder="Input the Provider Name for this device" /> */}
                                <select
                                    id="providerName"
                                    name="providerName"
                                    data-error="Select an Appropriate Provider Name"
                                    data-norm="Select Provider"
                                >
                                    <option value="None">
                                        {"Select Provider"}
                                    </option>
                                    <option value="Wavefuel">Wavefuel</option>
                                    <option value="EVRE">EVRE</option>
                                    <option value="ABB">ABB</option>
                                    <option value="Tellus">Tellus Power</option>
                                </select>
                            </div>
                            <div id="divProjectInput" className="formInputs">
                                <label id="label_projectId" htmlFor="project">
                                    Project
                                </label>
                                <DynamicSelect
                                    api={API_GetAllProjects}
                                    respType={"projects"}
                                    respChild={"project"}
                                    respEntry={"projectName"}
                                    fieldName={"projectId"}
                                    defaultSelection={"Select a Project"}
                                    defaultValue={"None"}
                                    errorMessage={
                                        "Select an Appropriate Project"
                                    }
                                    norm={"Select a Project"}
                                />
                            </div>
                            <div
                                id="divRoleInput"
                                className="formInputs modalParentInput"
                            >
                                <label
                                    id="label_deviceType"
                                    htmlFor="deviceType"
                                >
                                    Device Type
                                </label>
                                <select
                                    id="deviceType"
                                    name="deviceType"
                                    data-error="Select an Appropriate Device Type"
                                    data-norm="Select your Device Type"
                                >
                                    <option value="None">
                                        Select your Device Type
                                    </option>
                                    <option value="ChargingStation">
                                        Charging Station
                                    </option>
                                    {/* <option value="Sensor">IOT Sensor</option>
                                    <option value="Vehicle">Vehicle</option> */}
                                </select>
                            </div>
                            <div
                                id="divChargingStationInputContainer"
                                className="ChargingStation modalChildInputs"
                            >
                                <div className="formInputs">
                                    <label
                                        id="label_boxSerialNumber"
                                        htmlFor="boxSerialNumber"
                                    >
                                        Station Serial Number
                                    </label>
                                    <input
                                        id="boxSerialNumber"
                                        name="boxSerialNumber"
                                        placeholder="Input the Serial Number for this device"
                                        data-error="Station Serial Numbe is a Required Field"
                                        data-norm="Input the Serial Number for this device"
                                    />
                                </div>
                                <div
                                    id="divProtocolInput"
                                    className="formInputs"
                                >
                                    <label
                                        id="label_communicationProtocol"
                                        htmlFor="communicationProtocol"
                                    >
                                        Communication Protocol
                                    </label>
                                    <select
                                        id="communicationProtocol"
                                        name="communicationProtocol"
                                        data-error="Select an Appropriate Communication Protocol"
                                        data-norm="Select the Communication Protocol"
                                    >
                                        <option value="None">
                                            Select the Communication Protocol
                                        </option>
                                        <option value="OCPP1.6">OCPP1.6</option>
                                        <option value="OCPP2.0">OCPP2.0</option>
                                        <option value="OCPP2.1">OCPP2.1</option>
                                        <option value="MQTT">MQTT</option>
                                    </select>
                                </div>
                                <div
                                    id="divOutputRatingInput"
                                    className="formInputs"
                                >
                                    <label
                                        id="label_outputWattCategory"
                                        htmlFor="outputWattCategory"
                                    >
                                        Output Rating
                                    </label>
                                    <select
                                        id="outputWattCategory"
                                        name="outputWattCategory"
                                        data-error="Select an Appropriate Output Rating"
                                        data-norm="Select the Output Rating"
                                    >
                                        <option value="None">
                                            Select the Output Rating
                                        </option>
                                        <option value="3.3">3.3kW</option>
                                        <option value="7.2">7.2kW</option>
                                        <option value="7.2">10kW</option>
                                        <option value="15">15kW</option>
                                        <option value="22">22kW</option>
                                        <option value="30">30kW</option>
                                        <option value="60">60kW</option>
                                    </select>
                                </div>
                                <AddressSearchBar
                                    name={"address"}
                                    label={"Address"}
                                    class={"formInputs"}
                                    for={"address"}
                                />
                                <input name="lat" className="hide" />
                                <input name="lon" className="hide" />
                            </div>
                            <div id="divMessageInput" className="formInputs">
                                <label
                                    id="label_deviceDescription"
                                    htmlFor="deviceDescription"
                                >
                                    Device Description
                                </label>
                                <textarea
                                    id="deviceDescription"
                                    name="deviceDescription"
                                    rows="10"
                                    placeholder="Write a short description about the device and its configuration details."
                                    data-error="Device Description is a Required Field."
                                    data-norm="Write a short description about the device and its configuration details."
                                />
                            </div>
                        </form>
                    </div>
                )
            } else if (props.type === "Project") {
                return (
                    <div>
                        <div className="modalHeader">
                            <i
                                onClick={() => {
                                    $(".modalBackground").hide()
                                    $(".inputError")
                                        .removeClass("inputError")
                                        .each((e) => {
                                            $(e).attr(
                                                "placeholder",
                                                $(e).attr("data-norm")
                                            )
                                        })
                                    $(".createModal form")[0].reset()
                                    $(".createModal [id*='Container']").css({
                                        display: "none",
                                        opacity: "0",
                                        height: "0",
                                    })
                                }}
                                className="fas fa-times"
                            ></i>
                            <h1>Create New Project</h1>
                        </div>
                        <form id="formCreateDevice" className="createForm">
                            <div className="formInputs">
                                <label
                                    id="label_projectName"
                                    htmlFor="projectName"
                                >
                                    Project Name
                                </label>
                                <input
                                    id="projectName"
                                    name="projectName"
                                    placeholder="Input a Name for this Project"
                                    data-error="Project Name is a Required Field"
                                    data-norm="Input a Name for this Project"
                                />
                            </div>
                            <div
                                id="divProjectType"
                                className="formInputs modalParentInput"
                            >
                                <label
                                    id="label_projectType"
                                    htmlFor="projectType"
                                >
                                    Project Type
                                </label>
                                <select
                                    id="projectType"
                                    name="projectType"
                                    data-error="Select an Appropriate Project Type"
                                    data-norm="Select your Project Type"
                                >
                                    <option value="None">
                                        Select your Project Type
                                    </option>
                                    <option value="ChargingStation">
                                        Charging Station
                                    </option>
                                    {/* <option value="Sensor">IOT Sensor</option>
                                    <option value="Vehicle">Vehicle</option> */}
                                </select>
                            </div>
                            <div
                                id="divLocationInput"
                                className="formInputs modalParentInput"
                            >
                                <label
                                    id="label_locationCategory"
                                    htmlFor="locationCategory"
                                >
                                    Location Category
                                </label>
                                <select
                                    id="locationCategory"
                                    name="locationCategory"
                                    data-error="Select an Appropriate Location Category"
                                    data-norm="Select your Location Type"
                                >
                                    <option value="None">
                                        Select your Location Type
                                    </option>
                                    <option value="Office">Office</option>
                                    <option value="Muncipal">Muncipal</option>
                                    <option value="Parking">Parking</option>
                                    <option value="Retail">Retail</option>
                                    <option value="Residential">
                                        Residential
                                    </option>
                                </select>
                            </div>
                            <div
                                id="divAccessCategoryInput"
                                className="formInputs modalParentInput"
                            >
                                <label
                                    id="label_accessCategory"
                                    htmlFor="accessCategory"
                                >
                                    Access Category
                                </label>
                                <select
                                    id="accessCategory"
                                    name="accessCategory"
                                    data-error="Select an Appropriate Access Category"
                                    data-norm="Select your Access Type"
                                >
                                    <option value="None">
                                        Select your Access Type
                                    </option>
                                    <option value="Private">Private</option>
                                    <option value="Public">Public</option>
                                </select>
                            </div>
                            <AddressSearchBar
                                name={"address"}
                                label={"Address"}
                                class={"formInputs"}
                                for={"address"}
                            />
                            <input name="lat" className="hide" />
                            <input name="lon" className="hide" />
                            <div id="divMessageInput" className="formInputs">
                                <label
                                    id="label_projectDescription"
                                    htmlFor="projectDescription"
                                >
                                    Project Description
                                </label>
                                <textarea
                                    id="projectDescription"
                                    name="projectDescription"
                                    rows="10"
                                    placeholder="Write a short description about the device and its configuration details."
                                    data-error="Project Description is a Required Field"
                                    data-norm="Write a short description about the device and its configuration details."
                                />
                            </div>
                        </form>
                    </div>
                )
            } else if (props.type === "Auth") {
                return (<AuthModal/>)
                
            } else if (props.type === "Connector") {
                return (
                    <form id="formCreateConnector" className="createForm">
                        <i
                            onClick={() => {
                                $(".modalBackground").hide()
                            }}
                            className="fas fa-times"
                        ></i>
                        <h1>Add a Connector to the Device</h1>
                        <div
                            id="divConnectorNumberInput"
                            className="formInputs"
                        >
                            <label
                                id="label_ConnectorNumber"
                                htmlFor="connectorNumber"
                            >
                                Connector Number
                            </label>
                            <select
                                id="connectorNumber"
                                name="connectorNumber"
                                placeholder="Input the Connector Number"
                                data-error="Connector Number is a required Field"
                                data-norm="Input the Connector Number"
                            >
                                <option default value={"1"}>
                                    1
                                </option>
                                <option value={"2"}>2</option>
                                <option value={"3"}>3</option>
                            </select>
                        </div>
                        <div id="divOutputWattCattegory" className="formInputs">
                            <label
                                id="label_expiryDate"
                                htmlFor="outputWattCategory"
                            >
                                Output Category
                            </label>
                            <select
                                id="outputWattCategory"
                                name="outputWattCategory"
                                placeholder="Select the Output Watts"
                                data-error="Select an Appropriate Output Category"
                                data-norm="Select the Output Watts"
                            >
                                <option default value={"3.3"}>
                                    3.3 kWh
                                </option>
                                <option value={"7.2"}>7.2 kWh</option>
                                <option value={"15"}>15 kWh</option>
                                <option value={"22"}>22 kWh</option>
                                <option value={"30"}>30 kWh</option>
                                <option value={"60"}>60 kWh</option>
                            </select>
                        </div>
                        <div id="divConnectorType" className="formInputs">
                            <label
                                id="label_expiryDate"
                                htmlFor="outputWattCategory"
                            >
                                Connector Type
                            </label>
                            <select
                                id="connectorType"
                                name="connectorType"
                                placeholder="Select the Connector Type"
                                data-error="Select an Appropriate Connector Type"
                                data-norm="Select the Connector Type"
                            >
                                <option value={"CHADEMO"}>CHADEMO</option>
                                <option value={"CCS2"}>CCS2</option>
                                <option value={"CCS1"}>CCS1</option>
                                <option value={"Type1"}>Type1</option>
                                <option default value={"Type2"}>
                                    Type2
                                </option>
                                <option value={"BharatAC"}>BharatAC</option>
                                <option value={"BharatDC"}>BharatDC</option>
                                <option value={"3Pin"}>3Pin</option>
                            </select>
                        </div>
                    </form>
                )
            } else if (props.type === "Session") {
            } else {
                return null
            }
        } catch (error) {
            addNotification(error)
        }
    }
    useEffect(() => {
        try {
            $(".modalParentInput select").on("change", function (e) {
                try {
                    var optionSelected = $("option:selected", this)
                    var valueSelected = this.value
                    if ($("." + valueSelected).length > 0) {
                        $("." + valueSelected).show()
                        $("." + valueSelected).animate(
                            { height: "300px", opacity: 1 },
                            () => {
                                $("." + valueSelected + " .formInputs").show()
                            }
                        )
                    } else {
                        $(".modalChildInputs").animate(
                            { height: "0px", opacity: 0 },
                            () => {
                                $(".modalChildInputs .formInputs").hide()
                                $(".modalChildInputs").hide()
                            }
                        )
                    }
                } catch (error) {
                    addNotification(error)
                }
            })
        } catch (error) {
            addNotification(error)
        }
    })
    return (
        <div className="modalBackground">
            <div className="createModal">
                {renderForm()}
                <div className="createButtons">
                    <button
                        type="button"
                        onClick={() => {
                            props.submit()
                        }}
                        className="primaryButton"
                    >
                        Submit
                    </button>
                    <button
                        type="button"
                        onClick={() => {
                            $(".modalBackground").hide()
                            $(".inputError")
                                .removeClass("inputError")
                                .each((e) => {
                                    $(e).attr(
                                        "placeholder",
                                        $(e).attr("data-norm")
                                    )
                                })
                            $(".createModal form")[0].reset()
                            $(".createModal [id*='Container']").css({
                                display: "none",
                                opacity: "0",
                                height: "0",
                            })
                        }}
                        className="secondaryButton"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CreateModal
