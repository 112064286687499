import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import $ from 'jquery'
import { API_MonthByMonthSessions } from '../../../api/org';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from '../../loader/Loader';
import { Rnd } from 'react-rnd'
import { addNotification } from '../../../utils/addNotification'
import stream from 'highcharts/modules/streamgraph'

stream(Highcharts);
const UsageStreamChart = forwardRef((props, ref) => {
    let chartComponent = useRef();
    let [seriesData, setSeriesData] = useState(null)
    let [options, setOptions] = useState(null);
    let [isLoading, setIsLoading] = useState(true);
    useImperativeHandle(
        ref,
        () => ({
            resizeChart() {
                try {
                    chartComponent.current.chart.reflow();
                } catch (error) {
                    addNotification(error);
                }
            }
        }),
    )
    let colors = Highcharts.getOptions().colors;
    const opt = {
        chart: {
            type: 'streamgraph',
            marginBottom: 30,
            reflow: true,
            backgroundColor: '#e2e2e200',
            height: props.height
        },
        // Make sure connected countries have similar colors
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: false,
        },
        annotations: {
            labels: {
                format: "₹{value}",
            },
        },
        legend: {
            enabled: false
        },
        title: {
            text: 'Annual Revenue Stream By Project',
            floating: true,
            style: { fontSize: "12px" },
        },
        subtitle: {
            text: 'Consumption pattern by Month for all charging stations.',
            floating: true,
            style: { fontSize: "10px", color: "gray" },
            y: 30,
        },
        xAxis: {
            maxPadding: 0,
            type: 'category',
            crosshair: true,
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            labels: {
                align: 'left',
                reserveSpace: false,
                rotation: 270
            },
            lineWidth: 0,
            margin: 20,
            tickWidth: 0
        },

        yAxis: {
            visible: false,
            startOnTick: false,
            endOnTick: false
        },
        plotOptions: {
            series: {
                label: {
                    minFontSize: 5,
                    maxFontSize: 15,
                    style: {
                        color: 'rgba(255,255,255,0.75)'
                    }
                }
            }
        },

        // Data parsed with olympic-medals.node.js
        series: [],
    };
    useEffect(() => {
        try {
            if (JSON.parse(localStorage.getItem('organizationId'))) {
                let obj = { organization: JSON.parse(localStorage.getItem('organizationId')) }
                API_MonthByMonthSessions(obj).then(d => {
                    try {
                        let _opt = opt;
                        _opt.series = d.series;
                        setOptions(_opt);
                    } catch (error) {
                        addNotification(error);
                    }
                }).catch(error => { addNotification(error); });
            }
        } catch (error) {
            addNotification(error);
        }
    }, [])
    return (
        <div id="streamChart" className="dashboardChartContainer">
            {
                (options) ? <HighchartsReact id="chart" highcharts={Highcharts} ref={chartComponent} options={options} containerProps={{ className: 'chart', style: { height: "100%" } }} />
                    : <Loader />
            }
        </div>
    )
});
export default UsageStreamChart