import '../Forms.css';

import {
    AutoField,
    AutoFields,
    AutoForm,
    ErrorsField,
    LongTextField,
    SubmitField
} from 'uniforms-semantic';
import { useEffect, useState } from 'react';

import $ from 'jquery';
import { API_GetAllProjects } from '../../../api/project';
import AddressSearchBar from '../../mapConfigInput/AddressSearchBar';
import DynamicSelect from '../../DynamicSelect';
import { deviceSchema } from './DeviceConfigSchema';
import { addNotification } from '../../../utils/addNotification';

function DeviceConfigForm(props) {
    let [schema, setSchema] = useState(deviceSchema(props.device));
    let setFields = () => {
        try {
            let schemaKeys = Object.keys(schema.getField().properties);
            return schemaKeys.map((e, i, arr) => {
                let isReadOnly = schema.schema.properties[e].readOnly ? true : false
                if (props.device[e]) {
                    if (schema.schema.properties[e].format === 'date-time') {
                        let c = Date.parse(props.device[e]);
                        return (<AutoField name={e} key={i} value={new Date(c)} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly} />)
                    }
                    else if (e === 'deviceDescription') {
                        return (<LongTextField name={e} key={i} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly} />)
                    }
                    else if (e === 'projectId') {
                        return (
                            <div className='field'>
                                <label>Project Name</label>
                                <DynamicSelect api={API_GetAllProjects} respType={'projects'} respChild={'project'} respEntry={'projectName'} fieldName={'projectId'} defaultSelection={props.device.projectId.projectName} defaultValue={props.device.projectId._id} />
                            </div>

                        )
                    }
                    else if (e === 'address') {
                        return (<AddressSearchBar name={e} label={'Address'} inputvalue={props.device[e]} />)
                    }
                    else {
                        return (<AutoField name={e} key={i} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly} />)
                    }
                }
                else {
                    if (schema.schema.properties[e].format === 'date-time') {
                        return (<AutoField name={e} key={i} value={new Date()} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly} />)
                    }
                    else if (e === 'projectId') {
                        return (
                            <div className='field'>
                                <label>Project Name</label>
                                <DynamicSelect api={API_GetAllProjects} respType={'projects'} respChild={'project'} respEntry={'projectName'} fieldName={'projectId'} defaultSelection={props.device.projectId.projectName} defaultValue={props.device.projectId._id} />
                            </div>
                        )
                    }
                    else if (e === 'deviceDescription') {
                        return (<LongTextField name={e} key={i} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly} />)
                    }
                    else if (e === 'address') {
                        return (<AddressSearchBar name={e} label={'Address'} />)
                    }
                    else {
                        return (<AutoField name={e} key={i} className={isReadOnly ? 'readOnlyInput' : ''} readOnly={isReadOnly} />)
                    }
                }
            })
        } catch (error) {
            addNotification(error);
        }
    }
    useEffect(() => {
        $('#deviceConfigForm input[type="submit"]').remove();
    }, [])
    useEffect(() => {
        try { if (props.device) setSchema(deviceSchema(props.device)) } catch (error) {
            addNotification(error);
        }
    }, [props.device])
    return (
        <div className='manageForm'>
            <AutoForm id="deviceConfigForm" schema={schema} >
                {setFields()}
            </AutoForm>
        </div>
    )
}

export default DeviceConfigForm