
export async function API_CreateProject(formData) {
    let url = process.env.REACT_APP_SERVER_API_URL + '/project/create/';
    formData.account = JSON.parse(localStorage.getItem('profile')).accountId;
    formData.organizationId = JSON.parse(localStorage.getItem('organizationId'));
    let resp = await fetch(url, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        },
        body: JSON.stringify(formData),
    })
        .then(res => res.json()).then(resp => {
            if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                localStorage.clear();
                window.location.replace('/');
            }
            return resp
        })
        .catch((err) => {
            console.log(err);
        })
    return resp;
}


export async function API_GetAllProjects() {
    let url = process.env.REACT_APP_SERVER_API_URL + '/project/get/all?orgId=' + JSON.parse(localStorage.getItem('organizationId'));
    let resp = await fetch(url, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        }
    }).then(res => res.json()).then(resp => {
        if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
            localStorage.clear();
            window.location.replace('/');
        }
        return resp
    })
    return resp;
}

export async function API_GetProjectDevices(projectId) {
    let url = process.env.REACT_APP_SERVER_API_URL + '/project/get/devices?orgId=' + JSON.parse(localStorage.getItem('organizationId')) + '&projectId=' + projectId;
    let resp = await fetch(url, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        }
    }).then(res => res.json()).then(resp => {
        if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
            localStorage.clear();
            window.location.replace('/');
        }
        return resp
    })
    return resp;
}

export async function API_GetProject(id) {
    let url = process.env.REACT_APP_SERVER_API_URL + '/project/get/one?id=' + id;
    let resp = await fetch(url, {
        mode: 'cors',
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        }
    }).then(res => res.json()).then(resp => {
        if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
            localStorage.clear();
            window.location.replace('/');
        }
        return resp
    })
    return resp;
}

export async function API_UpdateProject(id, formData) {
    let url = process.env.REACT_APP_SERVER_API_URL + '/project/update?id=' + id;
    let resp = await fetch(url, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        },
        body: JSON.stringify(formData),
    })
        .then(res => res.json()).then(resp => {
            if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                localStorage.clear();
                window.location.replace('/');
            }
            return resp
        })
        .catch((err) => {
            console.log(err);
        })
    return resp;
}

export async function API_UpdateProjectAuth(formData) {
    let url = process.env.REACT_APP_SERVER_API_URL + '/project/update/auth';
    let resp = await fetch(url, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        },
        body: JSON.stringify(formData),
    })
        .then(res => res.json()).then(resp => {
            if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                localStorage.clear();
                window.location.replace('/');
            }
            return resp
        })
        .catch((err) => {
            console.log(err);
        })
    return resp;
}

export async function API_GetProjectMetrics(obj) {
    let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/project/getProjectStatistics';
    let resp = await fetch(url, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        },
        body: JSON.stringify(obj),
    })
        .then(res => res.json()).then(resp => {
            if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                localStorage.clear();
                window.location.replace('/');
            }
            return resp
        })
        .catch((err) => {
            console.log(err);
        })
    return resp;
}

export async function API_GetRevenueBreakDownByDevice(obj) {
    let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/project/getProjectRevenueBreakdown';
    let resp = await fetch(url, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        },
        body: JSON.stringify(obj),
    })
        .then(res => res.json()).then(resp => {
            if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                localStorage.clear();
                window.location.replace('/');
            }
            return resp
        })
        .catch((err) => {
            console.log(err);
        })
    return resp;
}

export async function API_ProjectRecentChargeSessions(obj) {
    let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/project/getProjectRecentSessions';
    let resp = await fetch(url, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        },
        body: JSON.stringify(obj),
    })
        .then(res => res.json()).then(resp => {
            if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                localStorage.clear();
                window.location.replace('/');
            }
            return resp
        })
        .catch((err) => {
            console.log(err);
        })
    return resp;
}


export async function API_UserChargeByDay(obj) {
    let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/sessions/getSessionsByDay';
    let form = {
        session: obj,
    }
    let resp = await fetch(url, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        },
        body: JSON.stringify(form),
    })
        .then(res => res.json()).then(resp => {
            if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                localStorage.clear();
                window.location.replace('/');
            }
            return resp
        })
        .catch((err) => {
            console.log(err);
        })
    return resp;
}

export async function API_ProjectUserChargeByDay(obj) {
    let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/project/getProjectSessionsByDay';
    let resp = await fetch(url, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        },
        body: JSON.stringify(obj),
    })
        .then(res => res.json()).then(resp => {
            if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                localStorage.clear();
                window.location.replace('/');
            }
            return resp
        })
        .catch((err) => {
            console.log(err);
        })
    return resp;
}

export async function API_GetHourlyData(obj) {
    let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/sessions/getHourlyData';
    let form = {
        session: obj,
    }
    let resp = await fetch(url, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        },
        body: JSON.stringify(form),
    })
        .then(res => res.json()).then(resp => {
            if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                localStorage.clear();
                window.location.replace('/');
            }
            return resp
        })
        .catch((err) => {
            console.log(err);
        })
    return resp;
}

export async function API_GetProjectHourlyData(obj) {
    let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/project/getProjectHourlyData';
    let resp = await fetch(url, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        },
        body: JSON.stringify(obj),
    })
        .then(res => res.json()).then(resp => {
            if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                localStorage.clear();
                window.location.replace('/');
            }
            return resp
        })
        .catch((err) => {
            console.log(err);
        })
    return resp;
}

export async function API_GetUserMetrics(obj) {
    let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/sessions/getUserMetrics';
    let form = {
        session: obj,
    }
    let resp = await fetch(url, {
        mode: 'cors',
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Accept: "application/json",
            Authorization: 'Bearer ' + localStorage.getItem('auth')
        },
        body: JSON.stringify(form),
    })
        .then(res => res.json()).then(resp => {
            if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                localStorage.clear();
                window.location.replace('/');
            }
            return resp
        })
        .catch((err) => {
            console.log(err);
        })
    return resp;
}