import { Route, Switch } from "react-router-dom"

import SessionsList from './SessionsList'

function Sessions(props) {
    return (
        <Switch>
            <Route exact path={props.match.url +  '/'} component={SessionsList} />
        </Switch>
    )
}

export default Sessions