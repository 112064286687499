import RGL, { WidthProvider } from 'react-grid-layout';
import { useEffect, useRef, useState } from "react";

import $ from 'jquery'
import { API_GetProjectMetrics } from "../../../../api/project";
import { API_UpdateDevice } from '../../../../api/device';
import RecentChargeSessions from '../../../../components/charts/project/dashboard/RecentChargeSessions';
import RevenueBreakdownByDevice from "../../../../components/charts/project/dashboard/RevenueBreakdownByDevice.js";
import _ from "lodash";
import { addNotification } from '../../../../utils/addNotification';

const GridLayout = WidthProvider(RGL);

function ProjectDashboard(props) {
    const projectRevenueBubble = useRef();
    const hourlyConsumptionRef = useRef();
    const sessionUsageRef = useRef();
    props = {
        className: "layout",
        items: 6,
        rowHeight: 85,
        onLayoutChange: function () { },
        cols: 12,
        ...props
    }
    let generateLayout = () => {
        try {
            const p = props
            return _.map(new Array(p.items), function (item, i) {
                const y = _.result(p, "y") || Math.ceil(Math.random() * 4) + 1;
                let layout = {
                    x: (i * 2) % 12,
                    y: 0,
                    w: 6,
                    h: 4,
                    i: i.toString()
                };
                return layout
            });
        } catch (error) {
            addNotification(error);
        }
    }
    function secondToString(seconds) {
        try {
            var sec_num = parseInt(seconds, 10); // don't forget the second param
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            if (seconds < 10) { seconds = "0" + seconds; }
            return hours + 'H ' + minutes + 'M';
        } catch (error) {
            addNotification(error);
        }
    }
    function calculateAverage(array) {
        try {
            var total = 0;
            var count = 0;

            array.forEach(function (item, index) {
                total += item;
                count++;
            });

            return total / count;
        } catch (error) {
            addNotification(error);
        }
    }
    const [layout, setLayout] = useState(generateLayout());
    const [metrics, setMetrics] = useState({ averageChargeTime: 0, totalEnergyConsumed: 0, totalChargeSessions: 0, utilizationRates: 0 });
    let onLayoutChange = (layout) => {
        try {
            props.onLayoutChange(layout);
        } catch (error) {
            addNotification(error);
        }
    }
    let handleResize = () => {
        try {
            hourlyConsumptionRef.current.resizeChart();
            sessionUsageRef.current.resizeChart();
        } catch (error) {
            addNotification(error);
        }
    }
    useEffect(() => {
        try {
            let today = new Date();
            var lastWeek = new Date();
            var weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
            lastWeek.setTime(lastWeek.getTime() - weekInMilliseconds);

            let day = today.getDate();
            let month = today.getMonth();
            let year = today.getFullYear();
            let _lastWeekDay = lastWeek.getDate();
            let _lastWeekMonth = lastWeek.getMonth();
            let _lastWeekYear = lastWeek.getFullYear();
            let obj = {
                project: props.project._id,
            }
            API_GetProjectMetrics(obj).then(d => {
                let averageChargeTime = d?.averageChargeTime ? secondToString(d?.averageChargeTime.toFixed(0)) : 0
                let utilizationRates = Object.values(d.utilizationRates)
                let utilizationAvg = utilizationRates.reduce((a, b) => a + b, 0);
                utilizationAvg = (utilizationAvg / (utilizationRates.length)).toFixed(2);
                setMetrics({ utilizationRate: utilizationAvg, totalChargeSessions: d.totalChargeSessions, averageChargeTime: averageChargeTime, totalRevenue: d.totalRevenue, activeDevices: d.activeDevices })
            }).catch(err => {
                addNotification(err);
            });
        } catch (error) {
            addNotification(error);
        }
    }, [])
    return (
        <div className='detailAnalyticsContainer'>
            <div id="dashboardMetrics" className="dashboardMetricsContainer">
                <div className="metric">
                    <i className="fa-solid fa-bullseye" style={{ color: "green" }}></i>
                    <div>
                        <span id="bl" style={{ color: "green" }}>{metrics.activeDevices}</span>
                        <h1>Active Devices</h1>
                    </div>
                </div>
                <div className="metric">
                    <i className="fa-solid fa-bolt" style={{ color: "green" }}></i>
                    <div>
                        <span id="bl" style={{ color: "green" }}>{metrics.utilizationRate}</span>
                        <h1>Utilization Rate(%)</h1>
                    </div>
                </div>
                <div className="metric">
                    <i className="fa-solid fa-network-wired" style={{ color: "#1783bb" }}></i>
                    <div>
                        <span id="bl" style={{ color: "#1783bb" }}>{metrics.totalChargeSessions}</span>
                        <h1>Total Charge Sessions</h1>
                    </div>
                </div>
                <div className="metric">
                    <i className="fa-solid fa-clock"></i>
                    <div>
                        <span id="rul" style={{ color: "#e08357" }}>{metrics.averageChargeTime}</span>
                        <h1>Average Charge Time</h1>
                    </div>
                </div>
                <div className="metric">
                    <i className="fa-solid fa-money-check-dollar"></i>
                    <div>
                        <span id="rul" style={{ color: "#e08357" }}>₹{metrics.totalRevenue}</span>
                        <h1>Total Revenue(₹)</h1>
                    </div>
                </div>
            </div>
            <div className='dashboardMetricCharts'>
                <GridLayout
                    id="chartGrid"
                    className="layout"
                    // layout={layout}
                    cols={props.cols}
                    rowHeight={props.rowHeight}
                    onLayoutChange={onLayoutChange}
                    onResize={handleResize}
                    isDraggable={false}
                    isResizable={false}
                    margin={[-20, 80]}
                >
                    <div id="parentRadialChart" key="1" data-grid={{ x: 0, y: 0, w: 5, h: 3 }} className="analytic">
                        <RevenueBreakdownByDevice ref={projectRevenueBubble} project={props.project} height={"inherit"} />
                    </div>
                    <div id="parentRadialChart" key="2" data-grid={{ x: 6, y: 0, w: 5, h: 3 }} className="analytic">
                        <RecentChargeSessions ref={sessionUsageRef} project={props.project} height={"inherit"} />
                    </div>
                </GridLayout>
            </div>
        </div>
    )
}

export default ProjectDashboard;