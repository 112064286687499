import { API_Signin, API_Signup } from '../../../api/account';
import { Link, Route, Switch, useHistory } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react';

import $ from 'jquery';
import { API_AuthenticateAccount } from '../../../api/storage/auth';
import Analytics from '../../account/containers/dashboard/Analytics';
import ChargingStationDashboard from '../../account/containers/devices/chargingstations/ChargingStationDashboard';
import Login from './Login';
import PublicLogo from '../../../components/logo/PublicLogo'
import Signup from './Signup';
import is from 'is_js';
import landingVideo from '../../../assets/video.mp4';

function Landing(props) {
  const [formState, setFormState] = useState('Login');
  const [Form, setForm] = useState([]);
  let history = useHistory();
  let signupSubmit = async () => {
    try {
      let signupForm = $("#formSignupInputs").serializeArray();
      if (isFormValid(signupForm, "signup")) {
        var formJson = {};
        for (var i = 0; i < signupForm.length; i++) {
          if (signupForm[i].name === "organizationName" || signupForm[i].name === "email") {
            formJson[signupForm[i].name] = signupForm[i].value.toLowerCase();
          }
          else {
            formJson[signupForm[i].name] = signupForm[i].value;
          }
        }
        let res = await API_Signup(formJson)
        if (res.status === 200) {
          console.log(res);
          setFormState('Login');
        }
        else {
          console.log(res)
        };
      }
    } catch (error) {
      console.log(error);
    }
  }
  let loginSubmit = async () => {
    try {
      let loginForm = $("#formLoginInputs").serializeArray();
      $('input').each((i, e) => {
        let message = $(e).attr('data-norm');
        $(e).val('').attr('placeholder', message);
        $(e).removeClass('inputError');
      })
      if (isFormValid(loginForm, "login")) {
        var formJson = {};
        for (var i = 0; i < loginForm.length; i++) {
          if (loginForm[i].name === "password") {
            formJson[loginForm[i].name] = loginForm[i].value;
          }
          else {
            formJson[loginForm[i].name] = loginForm[i].value.toLowerCase();
          }
        }
        let res = await API_Signin(formJson)
        if (res.status === 200) {
          API_AuthenticateAccount(res, () => {
            try {
              let location = {
                pathname: '/client/account/' + res.id,
                state: { profile: res.profile, organizationId: res.organizationId }
              }
              history.push(location);
            } catch (error) {
              console.log(error);
            }
          })
        }
        else {
          if (res.status === 401) {
            $('#password').val('').attr('placeholder', res.message).addClass('inputError')
          }
          else if (res.status === 406) {
            $('#orgId').val('').attr('placeholder', res.message).addClass('inputError')
          }
          else if (res.status === 404) {
            $('#email').val('').attr('placeholder', res.message).addClass('inputError')
          }
          else {
            $('input').val('').attr('placeholder', res.message).addClass('inputError')
            console.log(res)
          }
        };
      }
    } catch (error) {
      console.log(error);
    }
  }


  // let isFormValid = (formArray, type) => {
  //   try {
  //     let nullCounter = 0;
  //     formArray.forEach((e, j) => {
  //       let obj = $('#' + e.name);
  //       if (obj.length > 0) {
  //         obj.each((i, elm) => {
  //           let elmId = elm.id;
  //           if (e.value === null || e.value === "" || e.value === "None") {
  //             let errMsg = $('#' + elmId).attr('data-error');
  //             $('#label_' + type + "_" + elmId).html(errMsg).addClass('inputError')
  //             nullCounter++
  //           }
  //           else {
  //             if (e.name === 'email') {
  //               if (is.email(e.value)) {
  //                 let normMsg = $('#' + elmId).attr('data-norm');
  //                 $('#label_' + type + "_" + elmId).html(normMsg).removeClass('inputError');
  //               }
  //               else {
  //                 let errMsg = $('#' + elmId).attr('data-error');
  //                 $('#label_' + type + "_" + elmId).html(errMsg).addClass('inputError')
  //                 nullCounter++
  //               }
  //             }
  //             else if (e.name === 'phoneNumber') {
  //               if (is.nanpPhone(e.value)) {
  //                 let normMsg = $('#' + elmId).attr('data-norm');
  //                 $('#label_' + type + "_" + elmId).html(normMsg).removeClass('inputError');
  //               }
  //               else {
  //                 let errMsg = $('#' + elmId).attr('data-error');
  //                 $('#label_' + type + "_" + elmId).html(errMsg).addClass('inputError')
  //                 nullCounter++
  //               }
  //             }
  //             else if (e.name === 'password') {
  //               var passw = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
  //               if (e.value.match(passw) && is.string(e.value)) {
  //                 let normMsg = $('#' + elmId).attr('data-norm');
  //                 $('#label_' + type + "_" + elmId).html(normMsg).removeClass('inputError');
  //               }
  //               else {
  //                 let errMsg = $('#' + elmId).attr('data-error');
  //                 $('#label_' + type + "_" + elmId).html(errMsg).addClass('inputError')
  //                 nullCounter++
  //               }
  //             }
  //             else if (e.name === 'confirmPassword') {
  //               if (e.value === formArray[j - 1].value) {
  //                 let normMsg = $('#' + elmId).attr('data-norm');
  //                 $('#label_' + type + "_" + elmId).html(normMsg).removeClass('inputError');
  //               }
  //               else {
  //                 let errMsg = $('#' + elmId).attr('data-error');
  //                 $('#label_' + type + "_" + elmId).html(errMsg).addClass('inputError')
  //                 nullCounter++
  //               }
  //             }
  //             else {
  //               let normMsg = $('#' + elmId).attr('data-norm');
  //               $('#label_' + type + "_" + elmId).html(normMsg).removeClass('inputError');
  //             }
  //           }
  //         });
  //       }
  //     })
  //     if (nullCounter > 0) {
  //       return false;
  //     }
  //     else {
  //       return true;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  let isFormValid = (formArray, type) => {
    try {
      let nullCounter = 0;
      let passval;
      formArray.forEach((e, j) => {
        let obj = $('[name="' + e.name + '"]');
        if (obj.length > 0) {
          obj.each((i, elm) => {
            let elmName = e.name;
            if(elmName==='password'){
              passval = e.value;
            }
            let tag = $('[name="' + elmName + '"]').prop('tagName');
            if ($('#label_' + type + '_' + elmName).filter(':visible').length) {
              let message = '';
              if (e.value === null || e.value === "" || e.value === "None") {
                message = $('[name="' + elmName + '"]').attr('data-error');
                if (tag === 'SELECT') {
                  $('[name="' + elmName + '"] option:first').text(message);
                  $('[name="' + elmName + '"]').addClass('inputError');
                }
                else {
                  $('[name="' + elmName + '"]').attr('placeholder', message).addClass('inputError')
                }
                nullCounter++
              }
              else if (elmName === 'confirmPassword' && ($('[name="' + elmName + '"]').val() !== passval)) {
                message = $('[name="' + elmName + '"]').attr('data-error');
                $('[name="' + elmName + '"]').val('');
                $('[name="' + elmName + '"]').attr('placeholder', message).addClass('inputError');
                nullCounter++;
              }
              else {
                message = $('[name="' + elmName + '"]').attr('data-norm');
                if (tag === 'SELECT') {
                  $('[name="' + elmName + '"] option:first').text(message);
                  $('[name="' + elmName + '"]').removeClass('inputError');
                }
                else {
                  $('[name="' + elmName + '"]').attr('placeholder', message).removeClass('inputError')
                }
              }
            }
          });
        }
      })
      if (nullCounter > 0) {
        return false;
      }
      else {
        return true;
      }
    } catch (error) {
      console.log(error);
    }
  }
  let handleFormTransition = useCallback(() => {
    try {
      if (formState === 'Login' || formState === 'Signup') {
        if (formState == 'Login') {
          $('.signupForm').hide();
          $('.loginForm').fadeIn(800);
        }
        if (formState == 'Signup') {
          $('.loginForm').hide();
          $('.signupForm').fadeIn(800);
        }
        $('.publicNavMenu h1').addClass('publicNavMenuOnHighlight');
        $('#divFormContent').show().removeClass('slideViewInActive').addClass('slideViewActive');
      }
      if (formState === 'inactive') {
        $('.publicNavMenu h1').removeClass('publicNavMenuOnHighlight');
        $('#divFormContent').removeClass('slideViewActive').addClass('slideViewInActive');
      }
    } catch (error) {
      console.log(error);
    }
  })
  useEffect(() => {
    try {
      $('#divFormContent').hide()
      let video = document.getElementById('landingVideo').play();
    } catch (error) {
      console.log(error);
    }
  }, [])
  useEffect(() => {
    try {
      handleFormTransition()
    } catch (error) {
      console.log(error);
    }
  }, [formState, handleFormTransition])
  return (
    <div id="divPublic" className="publicLandingContainer">
      <video autoPlay muted loop id="landingVideo">
        <source src={landingVideo} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      <div id="divLandingHeader" className="landingHeader">
        <PublicLogo />
        {/* <div id="divNavMenu" className="publicNavMenu">
          <h1 id="h1AboutLink">About</h1>
          <h1 id="h1ContactLink">Dashboard</h1>
          <h1 id="h1LoginLink" onClick={() => { setFormState('Login') }}>Login</h1>
        </div> */}
        <div id="divNavMenu" className="publicNavMenu">
          <h1 id="h1LoginLink" onClick={() => { setFormState('Login') }}>Login</h1>
          <h1 id="h1SignupLink" onClick={() => { setFormState('Signup') }}>Signup</h1>
        </div>
      </div>
      <div id="divLandingContent" className="landingContent">
        <div className='publicDashboard'>

        </div>
      </div>
      <div id="divFormContent" className="slideView">
        <Login submit={() => loginSubmit()} handleForm={() => handleFormTransition()} setForm={state => setFormState(state)} />
        <Signup submit={() => signupSubmit()} handleForm={() => handleFormTransition()} setForm={state => setFormState(state)} />
      </div>
    </div>
  );
}

export default Landing;