import './App.scss';

import React, { useEffect, useState } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { createBrowserHistory, createHashHistory } from 'history';

import Account from './account/Account'
import Error from './error/ErrorPage'
import Public from './public/Public'

function isElectron() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.indexOf(' electron/') !== -1;
}

function App() {
  const history = isElectron() ? createHashHistory() : createBrowserHistory();
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <Redirect from='/' to={{
            pathname: "/client/public",
          }} />
        </Route>
        <Route path="/client/public" component={Public} />
        <Route path="/client/account/:accountId" component={Account} />
        <Route path='/error/:message' component={Error} />
        <Route component={Error} />
      </Switch>
    </Router>
  );
}

export default App;
