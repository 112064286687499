import { useEffect, useState } from 'react'

import $ from 'jquery'
import { API_GetOrganizationAccounts } from '../../../../api/org'
import { API_InviteUser } from '../../../../api/account'
import CreateButton from '../../../../components/modal/CreateButton'
import CreateModal from '../../../../components/modal/CreateModal'
import SearchInput from '../../../../components/searchInput/SearchInput'
import { addNotification } from '../../../../utils/addNotification'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

function Settings() {
    let [accounts, setAccounts] = useState(null);
    let [accountList, setAccountList] = useState(null);
    let history = useHistory();
    let isFormValid = (formArray) => {
        try {
            let nullCounter = 0;
            formArray.forEach((e, j) => {
                let obj = $('[name="' + e.name + '"]');
                if (obj.length > 0) {
                    obj.each((i, elm) => {
                        let elmName = e.name;
                        let tag = $('[name="' + elmName + '"]').prop('tagName');
                        if ($('#label_' + elmName).filter(':visible').length) {
                            let message = '';
                            if (e.value === null || e.value === "" || e.value === "None") {
                                message = $('[name="' + elmName + '"]').attr('data-error');
                                if (tag === 'SELECT') {
                                    $('[name="' + elmName + '"] option:first').text(message);
                                    $('[name="' + elmName + '"]').addClass('inputError');
                                }
                                else {
                                    $('[name="' + elmName + '"]').attr('placeholder', message).addClass('inputError')
                                }

                                nullCounter++
                            }
                            else {
                                message = $('[name="' + elmName + '"]').attr('data-norm');
                                if (tag === 'SELECT') {
                                    $('[name="' + elmName + '"] option:first').text(message);
                                    $('[name="' + elmName + '"]').removeClass('inputError');
                                }
                                else {
                                    $('[name="' + elmName + '"]').attr('placeholder', message).removeClass('inputError')
                                }
                            }
                        }
                    });
                }
            })
            if (nullCounter > 0) {
                return false;
            }
            else {
                return true;
            }
        } catch (error) {
            addNotification(error);
        }
    }
    let handleInviteUser = async () => {
        try {
            let form = $("#formInvitePerson").serializeArray();
            if (isFormValid(form) === true) {
                var formJson = {};
                for (var i = 0; i < form.length; i++) {
                    formJson[form[i].name] = form[i].value;
                }
                formJson.profile = localStorage.getItem('profile');
                let res = await API_InviteUser(formJson)
                if (res.status === 200) {
                    console.log(res);
                }
                else {
                    console.log(res)
                };
            }
        } catch (error) {
            addNotification(error);
        }
    }
    let getOrganizationAccounts = async () => {
        try {
            let obj = { organization: JSON.parse(localStorage.getItem('organizationId')) }
            API_GetOrganizationAccounts(obj).then(resp => {
                try {
                    let accounts = resp.accounts;
                    accounts = accounts.filter(e => { return e !== null });
                    accounts.sort((a, b) => { return a._id - b._id });
                    setAccounts(accounts);
                } catch (error) {
                    addNotification(error);
                }
            }).catch(error => { addNotification(error); })
        } catch (error) {
            addNotification(error);
        }
    }
    let renderAccountList = (_accounts) => {
        try {
            let projectList = _accounts.map((e, i) => {
                let classname = null;
                // if (e.locationCategory === 'Office') classname = 'fa-solid fa-building'
                // else if (e.locationCategory === 'Muncipal') classname = 'fa-solid fa-people-roof'
                // else if (e.locationCategory === 'Parking') classname = 'fa-solid fa-square-parking'
                // else if (e.locationCategory === 'Retail') classname = 'fa-solid fa-shop'
                // else if (e.locationCategory === 'Residential') classname = 'fa-solid fa-house'
                return (
                    <div id={e._id} key={i} className="listRow" onClick={() => { console.log(e); }}>
                        <i className={classname || 'fa-solid fa-user-astronaut'}></i>
                        <p>{e?.lighthouseUserId?.username}</p>
                        <p>{e?.email}</p>
                        <p>{e?.role?.name}</p>
                        <p>{e?.lighthouseUserId?.jobTitle}</p>
                        <p>{e?.lighthouseUserId.newProfile ? 'Invited' : e.accountStatus}</p>
                    </div >
                )
            });
            setAccountList(projectList);
        } catch (error) {
            addNotification(error);
        }
    }
    let searchFilter = (item, query) => {
        try {
            return (item?.lighthouseUserId?.username.toLowerCase().includes(query.toLowerCase()) || item?.role?.name.toLowerCase().includes(query.toLowerCase()) || [item?.accountStatus].includes(query.toLowerCase() || item?.lighthouseUserId?.jobTitle.toLowerCase().includes(query.toLowerCase())))
        } catch (error) {
            addNotification(error);
        }
    }
    useEffect(() => {
        try {
            getOrganizationAccounts();
        } catch (error) {
            addNotification(error);
        }
    }, [])
    useEffect(() => {
        try {
            if (accounts) {
                renderAccountList(accounts);
            }
        } catch (error) {
            addNotification(error);
        }
    }, [accounts])
    return (
        <div id="divSettings" className="accountContainer">
            <div id="divManageMembers">
                <div id="divSettingsHeader" className="sectionHeader">
                    <div style={{ width: "100%" }}>
                        <h1>Manage Members</h1>
                        <SearchInput id={"memberSearch"} placeholder={"Search Members"} data={accounts} filter={searchFilter} searchResult={e => { renderAccountList(e) }} />
                    </div>
                    {/* <div style={{ height: "80px", marginLeft: "15px", display: "flex", alignItems: "flex-end" }}>
                        <CreateButton text="Invite Person" />
                    </div> */}
                </div>
                <div id="divMembersContainer" className="sectionContent">
                    <div className="listTable">
                        <div className="listTableHeader">
                            <i className=""></i>
                            <p>Name</p>
                            <p>Email</p>
                            <p>Role</p>
                            <p>Job Title</p>
                            <p>Status</p>
                        </div>
                        <div className="listTableRows">
                            {accountList}
                        </div>
                    </div>
                </div>
            </div>
            <CreateModal type='Person' submit={() => handleInviteUser()} />
        </div>
    )
}

export default Settings