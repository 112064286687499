import $ from 'jquery'
import { addNotification } from '../../utils/addNotification'

function CreateButton(props) {
    try {
        let openCreateModal = () => {
            try {
                $('.modalBackground').css({ display: "flex" })
                $('.createForm').trigger("reset");
            } catch (error) {
                addNotification(error);
            }
        }
        return (
            <button className={props.class} style={props.style} onClick={openCreateModal}>{props.text}</button>
        )
    } catch (error) {
        addNotification(error);
    }
}

export default CreateButton