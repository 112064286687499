import { API_GetProject, API_UpdateProject } from "../../../../api/project";
import { useEffect, useState } from "react";

import $ from 'jquery'
import AccessController from "./AccessController";
import DeviceController from "./DeviceController";
import Loader from "../../../../components/loader/Loader";
import ProjectConfig from "./ProjectConfig";
import ProjectDashboard from "./ProjectDashboard";
import { addNotification } from "../../../../utils/addNotification";
import { useHistory } from "react-router";

function ManageProjects(props) {
    let history = useHistory();
    let [contentState, setContentState] = useState(null)
    let [editable, setEditable] = useState(false)
    let [project, setProject] = useState(history.location.state)
    let [devices, setDevices] = useState([])
    let [assets, setAssets] = useState([])

    let handleEditButton = () => {
        try {
            $('#editDeviceDetails').animate({ width: 0, opacity: 0 }, () => {
                $('#editDeviceDetails').hide();
                $('#divEditButtons').show().animate({ opacity: 1 });
                $('#divEditButtons button').animate({ width: "75px" })
            });
            setEditable(true);
            $('.deviceContentContainer input').prop('disabled', false).removeClass("disabledInput");
            $('.deviceContentContainer textArea').prop('disabled', false).removeClass("disabledInput")
            $('.deviceContentContainer select').not('.permanentlyDisabledInput').prop('disabled', false).removeClass("disabledInput")
            $('#deviceSearch').prop('disabled', false).removeClass("disabledInput");
            $('.createButtons .primaryButton').prop('disabled', false).removeClass("disabledButton")
        } catch (error) {
            addNotification(error);
        }
    }
    let handleSaveButton = () => {
        try {
            let updatedProject = [];
            $('.deviceContentContainer input').each(function (e) {
                updatedProject.push({ 'name': $(this).attr("name"), 'value': $(this).val() });
            });
            $('.deviceContentContainer textArea').each(function (e) {
                updatedProject.push({ 'name': $(this).attr("name"), 'value': $(this).val() });
            });
            $('.deviceContentContainer select').each(function (e) {
                updatedProject.push({ 'name': $(this).attr("name"), 'value': $(this).val() });
            });
            var formJson = {};
            for (var i = 0; i < updatedProject.length; i++) {
                formJson[updatedProject[i].name] = updatedProject[i].value;
            }
            delete formJson._id;
            delete formJson.createdAt;
            delete formJson.updatedAt;
            let keys = Object.keys(formJson);
            keys.forEach(e => {
                if (formJson[e] === "" || formJson[e] === null) {
                    delete formJson[e];
                }
            })
            if (formJson['lat'] || formJson['lon']) formJson.location = { lat: formJson['lat'], lon: formJson['lon'] }
            if (formJson['serviceFee'] || formJson['priceUnit'] || formJson['sessionTariff'] || formJson['reservationFee']) {
                formJson.serviceFee = { price: formJson['serviceFee'], unit: formJson['priceUnit'] }
                formJson.sessionTariff = { price: formJson['sessionTariff'], unit: formJson['priceUnit'] }
                formJson.reservationFee = { price: formJson['reservationFee'], unit: formJson['priceUnit'] }
            }
            API_UpdateProject(history.location.state._id, formJson).then((resp) => {
                try {
                    setProject(resp.project);
                    handleCancelButton();
                } catch (error) {
                    addNotification(error);
                }
            }).catch(error => { addNotification(error); })
        } catch (error) {
            addNotification(error);
        }
    }
    let handleCancelButton = () => {
        try {
            $('#divEditButtons').animate({ opacity: 0 }, () => {
                $('#divEditButtons').hide();
                $('#editDeviceDetails').show().animate({ opacity: 1, width: "150px" });
            });
            setEditable(false);
            $('.deviceContentContainer input').prop('disabled', true).addClass("disabledInput");
            $('.deviceContentContainer textArea').prop('disabled', true).addClass("disabledInput")
            $('.deviceContentContainer select').prop('disabled', true).addClass("disabledInput")
            $('#deviceSearch').prop('disabled', false).removeClass("disabledInput");
            $('.createButtons .primaryButton').prop('disabled', true).addClass("disabledButton")
        } catch (error) {
            addNotification(error);
        }
    }
    let projectContent = () => {
        if (contentState === 'ProjectConfig' || contentState === 'DeviceController' || contentState === 'AccessController') {
            $('#editDeviceDetails').show()
        }
        else {
            $('#editDeviceDetails').hide()
        }
        if (contentState === 'ProjectConfig') {
            return (
                <ProjectConfig project={project} devices={devices} edit={editable} setProject={setProject} />
            )
        }
        else if (contentState === 'DeviceController') {
            return (
                <DeviceController project={project} match={props.match} devices={devices} edit={editable} setProject={setProject} />
            )
        }
        else if (contentState === 'AccessController') {
            return (
                <AccessController project={project} devices={devices} edit={editable} setProject={setProject} />
            )
        }
        else if (contentState === 'Dashboard') {
            return (
                <ProjectDashboard project={project} devices={devices} edit={editable} />
            )
        }
        else {
            return (
                <div className="loaderParent">
                    <Loader />
                </div>
            )
        }
    }
    let handleRefreshButton = () => {
        let previousMenuState = contentState;
        setContentState('Loading');
        API_GetProject(history.location.state._id).then(d => {
            try {
                setProject(d.project);
                setDevices(d.devices);
                setContentState(previousMenuState);
            } catch (error) {
                addNotification(error);
            }
        }).catch(error => { addNotification(error); });
    }
    useEffect(() => {
        $('.deviceMenu div').hover(
            (e) => {
                $('#' + e.currentTarget.id).addClass('activeMenuItem_Hover')
            },
            (e) => {
                $('#' + e.currentTarget.id).removeClass('activeMenuItem_Hover')
            });
        $('.deviceMenu div').on('click', (e) => {
            try {
                setContentState(e.currentTarget.dataset.menuState);
                $('.deviceMenu div').removeClass('activeMenuItem_Click');
                $('#' + e.currentTarget.id).addClass('activeMenuItem_Click');
                handleCancelButton();
            } catch (error) {
                addNotification(error);
            }
        });
        let previousMenuState = contentState;
        setContentState('Loading');
        API_GetProject(history.location.state._id).then(d => {
            try {
                setProject(d.project);
                setDevices(d.devices);
                setContentState("Dashboard");
            } catch (error) {
                addNotification(error);
            }
        }).catch(error => { addNotification(error); });
    }, [])
    return (
        <div id="divDeviceContainer" className="deviceContainer" >
            <div className="deviceHeader">
                <div className="manageProjectHeader">
                    <h1>{project.projectName}</h1>
                    <p className={project.projectStatus}><i style={{ marginRight: "7px" }} className="fa-solid fa-circle-dot"></i>{project.projectStatus}</p>
                </div>
                <div id="divManageButtons" className="manageDeviceButtons">
                    <button id="refreshDeviceDetails" className="secondaryButton" onClick={() => { handleRefreshButton() }}><i className="fa-solid fa-arrows-rotate"></i></button>
                    <button id="editDeviceDetails" className="primaryButton" onClick={() => { handleEditButton() }}>Edit Project</button>
                    <div id="divEditButtons" className="editButtons">
                        <button id="saveDeviceDetails" style={{ width: "0%" }} className="primaryButton" onClick={() => { handleSaveButton() }}><i className="fas fa-save"></i></button>
                        <button id="cancelDeviceDetails" style={{ width: "0%" }} className="secondaryButton" onClick={() => { handleCancelButton() }}><i className="fas fa-times"></i></button>
                    </div>
                </div>
            </div>
            <div className="deviceMenu">
                <div id="projectMenuTestAlgo" className="activeMenuItem_Click" data-menu-state="Dashboard">
                    <h1>Dashboard</h1>
                    <hr></hr>
                </div>
                <div id="projectMenuConfig" data-menu-state="ProjectConfig">
                    <h1>Project Configuration</h1>
                    <hr></hr>
                </div>
                <div id="projectMenuReportViewer" data-menu-state="DeviceController">
                    <h1>Device Controller</h1>
                    <hr></hr>
                </div>
                <div id="projectMenuAlertConfig" data-menu-state="AccessController">
                    <h1>Access Controller</h1>
                    <hr></hr>
                </div>
            </div>
            <div className="deviceContentContainer">
                {projectContent()}
            </div>
        </div>
    )
}

export default ManageProjects;