import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'

import $ from 'jquery'
import HC_export_data from "highcharts/modules/export-data";
import HC_exporting from 'highcharts/modules/exporting'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Rnd } from 'react-rnd'
import highchartsMore from 'highcharts/highcharts-more'
import { addNotification } from '../../../utils/addNotification';

var colors = Highcharts.getOptions().colors;
var ranges = [
    [14.3, 27.7],
    [14.5, 27.8],
    [15.5, 29.6],
    [16.7, 30.7],
    [3, 10],
    [5, 10],
    [13.5, 24.8],
    [10.5, 21.4],
    [9.2, 23.8],
    [11.6, 21.8],
    [10.7, 23.7],
    [11.0, 23.3],
    [0, 8],
    [0, 5],
    [0, 0],
    [0, 0],
    [0, 3.2],
    [0, 3.5],
    [0, 2.3],
    [8, 12],
    [12.2, 15.5],
    [12.0, 20.8],
    [12.2, 15.5],
    [12.0, 20.8],
],
    averages = [
        21.5,
        22.1,
        23,
        10.8,
        5.4,
        21.3,
        18.3,
        15.4,
        16.4,
        0,
        0,
        0,
        0,
        0,
        0,
        5.3,
        5.3,
        0,
        0,
        0,
        13.7,
        15.7,
        15.7,
        15.7,
    ],
    localityAverages = [
        8.5,
        12.1,
        13,
        10.8,
        15.4,
        3.3,
        18.3,
        15.4,
        16.4,
        10,
        3,
        0,
        0,
        0,
        0,
        5.3,
        5.3,
        8.7,
        0,
        0,
        13.7,
        14.7,
        10.7,
        10.7,
    ]
const HourlyUsageStats = forwardRef((props, ref) => {
    let chartComponent = useRef();
    HC_exporting(Highcharts);
    HC_export_data(Highcharts);
    useImperativeHandle(
        ref,
        () => ({
            resizeChart() {
                try {
                    chartComponent.current.chart.reflow();
                } catch (error) {
                    addNotification(error?.message)
                }
            }
        }),
    )
    const options = {
        chart: {
            backgroundColor: '#e2e2e200',
            plotBorderWidth: 0,
            reflow: true
        },
        legend: {
            enabled: false
        },
        credits: {
            enabled: false,
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbolFill: "#989898",
                    theme: {
                        fill: "#e2e2e200",
                        padding: 5,
                        stroke: "none",
                    },
                    symbol: 'menu',
                    menuItems: [
                        "viewFullscreen",
                        "printChart", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG",
                        {
                            text: "Download CSV File",
                            onclick() {
                                let csv = this.getCSV();
                                const blob = new Blob([csv], { type: 'text/csv' });

                                // Creating an object for downloading url
                                const url = window.URL.createObjectURL(blob)

                                // Creating an anchor(a) tag of HTML
                                const a = document.createElement('a')

                                // Passing the blob downloading url 
                                a.setAttribute('href', url)

                                // Setting the anchor tag attribute for downloading
                                // and passing the download file name
                                a.setAttribute('download', 'download.csv');

                                // Performing a download with click
                                a.click()
                            }
                        },
                    ]
                }
            }
        },
        annotations: {
            labels: {
                format: "{value}",
            },
        },
        title: {
            text: 'Hourly Consumption Pattern',
            floating: true,
            style: { fontSize: "12px" },
            x: 20,
        },
        subtitle: {
            text: 'Consumption pattern by hour for this specific charging station, calculated with median values.',
            floating: true,
            style: { fontSize: "10px", color: "gray" },
            x: 20,
            y: 30,
        },
        xAxis: {
            categories: ['12am', '1am', '2am', '3am', '4am', '5am',
                '6am', '7am', '8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm', '4pm', '5pm',
                '6pm', '7pm', '8pm', '9pm', '10pm', '11pm',],
            labels: {
                style: { fontSize: 8 },
            }
        },
        yAxis: {
            title: {
                text: ''
            },
            min: 0,
            labels: {
                style: { fontSize: 8 },
                formatter: function () {
                    return this.value + 'kWh';
                }
            }
        },

        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: 'kWH'
        },

        series: [{
            name: 'Current Consumption Pattern',
            data: averages,
            type: 'spline',
            zIndex: 1,
            color: '#4cbc58',
            marker: {
                enabled: false,
            }
        }, {
            name: 'Average Consumption Pattern of the Locality',
            data: localityAverages,
            type: 'spline',
            zIndex: 0,
            color: '#b8ccd7',
            fillOpacity: 0.3,
            marker: {
                enabled: false,
            }
        }, {
            name: 'Historical Consumption Range',
            data: ranges,
            type: "areasplinerange",
            lineWidth: 0,
            linkedTo: ':previous',
            color: '#4cbc58',
            fillOpacity: 0.3,
            zIndex: 0,
            marker: {
                enabled: false
            }
        }]
    };
    return (
        <div id="hourlyUsageStats" className="dashboardChartContainer">
            <HighchartsReact highcharts={highchartsMore(Highcharts)} ref={chartComponent} options={options} containerProps={{ className: 'chart', style: { height: props.height } }} />
        </div>
    )
});
export default HourlyUsageStats