import { API_GetProject, API_GetProjectDevices } from '../../../../api/project';
import { Link, useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react';

import $ from 'jquery';
import { API_CreateDevice } from '../../../../api/device';
import CreateButton from '../../../../components/modal/CreateButton';
import CreateModal from '../../../../components/modal/CreateModal'
import SearchInput from '../../../../components/searchInput/SearchInput';
import { addNotification } from '../../../../utils/addNotification';

function DeviceController(props) {
    let [devices, setDevices] = useState(null);
    let history = useHistory();
    let [deviceList, setDeviceList] = useState(null);
    let handleDeviceDetails = (obj) => {
        try {
            $('#divAccountNavBar h1').removeClass('activeNavItem');
            $('#h1Nav_Devices').addClass('activeNavItem');
            history.push({
                pathname: props.match.url.replace('/projects/' + props.project._id, '') + '/devices/' + obj._id,
                state: obj,
            })
        } catch (error) {
            addNotification(error);
        }
    }

    let isFormValid = (formArray) => {
        try {
            let nullCounter = 0;
            formArray.forEach((e, j) => {
                let obj = $('[name="' + e.name + '"]');
                if (obj.length > 0) {
                    obj.each((i, elm) => {
                        let elmName = e.name;
                        let tag = $('[name="' + elmName + '"]').prop('tagName');
                        if ($('#label_' + elmName).filter(':visible').length) {
                            let message = '';
                            if (e.value === null || e.value === "" || e.value === "None") {
                                message = $('[name="' + elmName + '"]').attr('data-error');
                                if (tag === 'SELECT') {
                                    $('[name="' + elmName + '"] option:first').text(message);
                                    $('[name="' + elmName + '"]').addClass('inputError');
                                }
                                else {
                                    $('[name="' + elmName + '"]').attr('placeholder', message).addClass('inputError')
                                }

                                nullCounter++
                            }
                            else {
                                message = $('[name="' + elmName + '"]').attr('data-norm');
                                if (tag === 'SELECT') {
                                    $('[name="' + elmName + '"] option:first').text(message);
                                    $('[name="' + elmName + '"]').removeClass('inputError');
                                }
                                else {
                                    $('[name="' + elmName + '"]').attr('placeholder', message).removeClass('inputError')
                                }
                            }
                        }
                    });
                }
            })
            if (nullCounter > 0) {
                return false;
            }
            else {
                return true;
            }
        } catch (error) {
            addNotification(error);
        }
    }
    let createDevice = async () => {
        try {
            let deviceForm = $('.createModal form').serializeArray();
            var formJson = {};
            if (isFormValid(deviceForm)) {
                for (var i = 0; i < deviceForm.length; i++) {
                    formJson[deviceForm[i].name] = deviceForm[i].value;
                }
                if (formJson['lat'] || formJson['lon']) formJson.location = { lat: formJson['lat'], lon: formJson['lon'] }
                formJson["projectId"] = props.project._id;
                let res = await API_CreateDevice(formJson)
                if (res.status === 200) {
                    document.getElementById("formCreateDevice").reset();
                    getDeviceList();
                    $('.modalBackground').hide();
                }
                else {
                    console.log(res)
                };
            }
        }
        catch (err) {
            addNotification(err);
        }
    }
    let getDeviceList = async () => {
        try {
            API_GetProject(props.project._id).then(resp => {
                let devices = resp.devices;
                devices.sort((a, b) => { return a.deviceId - b.deviceId });
                setDevices(devices);
            }).catch(err => {
                addNotification(err);
            })
        } catch (error) {
            addNotification(error);
        }
    }
    let renderDeviceList = (d) => {
        try {
            let deviceList = d.map(e => {
                let iclassname;
                let cAt = new Date(e.createdAt);
                let uAt = new Date(e.updatedAt);
                if (e.deviceType === 'ChargingStation') iclassname = "fa-solid fa-charging-station"
                else if (e.deviceType === 'Vehicle') iclassname = "fa-solid fa-car"
                else if (e.deviceType === 'Sensor') iclassname = "fa-solid fa-microchip"
                return (
                    <div id={e._id} key={e._id} className="listRow" onClick={() => handleDeviceDetails(e)}>
                        <i className={iclassname}></i>
                        <p>{e.deviceName}</p>
                        <p>{e.deviceType}</p>
                        <p>{e.providerName}</p>
                        <p className={e.deviceStatus}><i style={{ marginRight: "7px" }} className="fa-solid fa-circle-dot"></i>{e.deviceStatus}</p>
                        <p>{cAt.toLocaleDateString() + ' ' + cAt.toLocaleTimeString()}</p>
                        <p>{uAt.toLocaleDateString() + ' ' + uAt.toLocaleTimeString()}</p>
                    </div>
                )
            });
            setDeviceList(deviceList);
        } catch (error) {
            addNotification(error);
        }
    }
    let searchFilter = (item, query) => {
        try {
            return (item.deviceName.toLowerCase().includes(query.toLowerCase()) || item.deviceType.toLowerCase().includes(query.toLowerCase()) || item.providerName.toLowerCase().includes(query.toLowerCase()) || item.deviceStatus.toLowerCase().includes(query.toLowerCase()))
        } catch (error) {
            addNotification(error);
        }
    }
    useEffect(() => {
        try {
            getDeviceList();
            $('#divProjectInput').hide()
        } catch (error) {
            addNotification(error);
        }
    }, [])
    useEffect(() => {
        try {
            if (devices) {
                renderDeviceList(devices);
            }
        } catch (error) {
            addNotification(error);
        }
    }, [devices])
    return (
        <div id="divParentDeviceController">
            <div id="divParentDeviceController">
                <div>
                    <div className='viewButtons'>
                        <CreateButton text="Create Device" class='createInputButton' />
                        <SearchInput id={"deviceSearch"} placeholder={"Search Devices"} filter={searchFilter} data={devices} searchResult={e => { renderDeviceList(e) }} />
                    </div >
                </div >
                <div id="divDeviceContainer" className="inputContent">
                    <div className="listTable">
                        <div className="listTableHeader">
                            <i className=""></i>
                            <p>Name</p>
                            <p>Device Type</p>
                            <p>Provider</p>
                            <p>Status</p>
                            <p>Created At</p>
                            <p>Updated At</p>
                        </div>
                        <div className="listTableRows">
                            {deviceList}
                        </div>
                    </div>
                </div>
            </div >
            <CreateModal type="Device" submit={() => { createDevice() }} />
        </div >
    )
}

export default DeviceController;