import { Route, Switch } from "react-router-dom"

import DeviceList from './DeviceList'
import ManageDevice from './ManageDevice'

function Devices(props) {
    return (
        <Switch>
            <Route exact path={props.match.url +  '/'} component={DeviceList} />
            <Route path={props.match.url + "/:deviceId"} component={ManageDevice} />
        </Switch>
    )
}

export default Devices