import Ajv from 'ajv';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
import { addNotification } from '../../../utils/addNotification'

function projectSchema(scm) {
  try {
    let schema = (scm) => {
      try {
        if (!scm.location) {
          scm.location = { lat: null, lon: null };
        }
        return {
          title: 'Project',
          type: 'object',
          properties: {
            _id: { type: 'string', pattern: '^[0-9a-fA-F]{24}$', readOnly: true },
            projectName: { type: 'string', readOnly: true },
            projectType: { type: 'string', enum: ['ChargingStation'] },
            projectStatus: { type: 'string', enum: ['Active', 'Inactive', 'Breakdown', 'Repair'], default: 'Active' },
            projectDescription: { type: 'string' },
            lat: { type: 'number' },
            lon: { type: 'number' },
            address: { type: 'string' },
            installationDate: { type: 'string', format: 'date-time' },
            locationCategory: { type: 'string', enum: ['Office', 'Muncipal', 'Parking', 'Retail', 'Residential'] },
            accessCategory: { type: 'string', enum: ['Private', 'Public'] },
            serviceFee: { type: 'number' },
            sessionTariff: { type: 'number' },
            reservationFee: { type: 'number' },
            priceUnit: { type: 'string', enum: ['INR'] },
            updatedAt: { type: 'string', format: 'date-time' },
            createdAt: { type: 'string', format: 'date-time' },
          }, required: ['projectName']
        }
      } catch (error) {
        addNotification(error);
      }
    };
    const _schema = schema(scm);
    let propKeys = Object.keys(_schema.properties);
    propKeys.forEach(e => {
      if (e === 'lat' && scm.location && scm.location['lat']) {
        _schema.properties[e].default = scm.location['lat'];
      }
      else if (e === 'lon' && scm.location && scm.location['lon']) {
        _schema.properties[e].default = scm.location['lon'];
      }
      else if (e === 'serviceFee' && scm.serviceFee?.price && scm.serviceFee?.unit) {
        _schema.properties[e].default = scm.serviceFee['price'];
        _schema.properties['priceUnit'].default = 'INR';
      }
      else if (e === 'sessionTariff' && scm.sessionTariff?.price && scm.sessionTariff?.unit) {
        _schema.properties[e].default = scm.sessionTariff['price'];
        _schema.properties['priceUnit'].default = 'INR';
      }
      else if (e === 'reservationFee' && scm.reservationFee?.price && scm.reservationFee?.unit) {
        _schema.properties[e].default = scm.reservationFee['price'];
        _schema.properties['priceUnit'].default = 'INR';
      }
      else {
        _schema.properties[e].default = scm[e];
      }
    })
    const ajv = new Ajv({ allErrors: true, useDefaults: true });

    function createValidator() {
      try {
        const validator = ajv.compile(_schema);

        return (model) => {
          validator(model);
          return validator.errors?.length ? { details: validator.errors } : null;
        };
      } catch (error) {
        addNotification(error);
      }
    }

    const schemaValidator = createValidator(_schema);
    return new JSONSchemaBridge(_schema, schemaValidator);
  } catch (error) {
    addNotification(error);
  }
}

export { projectSchema }