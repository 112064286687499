import { useEffect, useState } from "react"
import { addNotification } from '../utils/addNotification'

function DynamicSelect(props) {
    let [list, setList] = useState([]);
    let [options, setOptions] = useState()
    useEffect(() => {
        try {
            props.api().then(resp => {
                try {
                    let list = resp[props.respType];
                    list = list.filter(e => { return e !== null });
                    setList(list);
                } catch (error) {
                    addNotification(error);
                }
            }).catch(error => { addNotification(error); })
        } catch (error) {
            addNotification(error);
        }
    }, [])
    useEffect(() => {
        try {
            let _options = list.map((e, i) => {
                if (e) {
                    return <option key={i} value={e._id}>{e.projectName}</option>
                }
            })
            setOptions(_options);
        } catch (error) {
            addNotification(error);
        }
    }, [list])
    return (
        <select id='dynamicSelect' name={props.fieldName} defaultValue={props.defaultValue}>
            <option default value={props.defaultValue || "None"} disabled>{props.defaultSelection}</option>
            {options}
        </select>
    )
}

export default DynamicSelect