import {
    API_GetUserMetrics,
    API_UpdateDeviceAuth,
} from "../../../../../api/device"
import { useEffect, useRef, useState } from "react"

import $ from "jquery"
import CreateButton from "../../../../../components/modal/CreateButton"
import CreateModal from "../../../../../components/modal/CreateModal"
import SelectInput from "../../../../../components/selectMenu/SelectMenu"
import UserChargeByDay from "../../../../../components/charts/chargingStation/Dashboard/UserChargeByDay"
import UserChargeByHourOfDay from "../../../../../components/charts/chargingStation/Dashboard/UserChargeByHourOfDay"
import { addNotification } from "../../../../../utils/addNotification"
import { API_GetPrivateUsernames } from "../../../../../api/account"

function AccessController(props) {
    const radialRef = useRef()
    let [authList, setAuthList] = useState(null)
    let [asset, setAsset] = useState(props.asset)
    let [activeUser, setActiveUser] = useState(null)
    let [userMetrics, setUserMetrics] = useState({
        isActive: null,
        lastSessionTime: null,
        averageChargeTime: null,
        totalChargeSessions: null,
        totalAmount: null,
    })



    function secondToString(seconds) {
        try {
            var sec_num = parseInt(seconds, 10) // don't forget the second param
            var hours = Math.floor(sec_num / 3600)
            var minutes = Math.floor((sec_num - hours * 3600) / 60)
            var seconds = sec_num - hours * 3600 - minutes * 60

            if (hours < 10) {
                hours = "0" + hours
            }
            if (minutes < 10) {
                minutes = "0" + minutes
            }
            if (seconds < 10) {
                seconds = "0" + seconds
            }
            return hours + "H " + minutes + "M"
        } catch (error) {
            addNotification(error)
        }
    }
    let renderAuthRows = async() => {
        try {
            let authList
            if (props.asset && props.asset.authList) {
                if (props.asset.accessCategory === "Public") {
                    setActiveUser(null)
                    authList = [
                        <div key={1} className={"listRow selected"}>
                            <i
                                className="fa-solid fa-user-tag"
                                style={{ width: "0%" }}
                            ></i>
                            <p>Any Username</p>
                            <p>Any IdTag</p>
                            <p>N/A</p>
                            <p>N/A</p>
                        </div>,
                    ]
                } else {
                    let accountIds = props.asset.authList.map(e => e.account)
                    let usernames = await API_GetPrivateUsernames(accountIds)
                    authList = props.asset.authList.map((e, i) => {
                        // if (i === 0) setActiveUser(e);
                        return (
                            <div
                                id={e._id}
                                key={i}
                                className={"listRow"}
                                onClick={() => {
                                    setActiveUser(e)
                                }}
                            >
                                <i
                                    className="fa-solid fa-user-tag"
                                    onClick={() => {
                                        removeAuthList(e)
                                    }}
                                    style={{ width: "0%" }}
                                ></i>
                                <p>{usernames.usernames[e.account]}</p>
                                <p>{e.idTag}</p>
                                <p>{e.expiryDate}</p>
                                <p>N/A</p>
                            </div>
                        )
                    })
                }
            } else {
                authList = (
                    <div className="defaultEmptyRow">
                        Device Has no Authentication Ids
                    </div>
                )
            }
            setAuthList(authList)
            return authList
        } catch (error) {
            addNotification(error)
        }
    }
    let addAuthList = () => {
        try {
            try {
                let authForm = $(".createModal form").serializeArray()
                if (isFormValid(authForm)) {
                    var formJson = {}
                    for (var i = 0; i < authForm.length; i++) {
                        formJson[authForm[i].name] = authForm[i].value
                    }
                    formJson["deviceId"] = props.asset._id
                    API_UpdateDeviceAuth(formJson)
                        .then((res) => {
                            try {
                                document
                                    .getElementById("formCreateAuth")
                                    .reset()
                                if (res.status === 200) {
                                    $(".modalBackground").hide()
                                    props.setAsset(res.doc)
                                }
                                // else if (res.status === 403) {
                                //     let message = '';
                                //     // for (let i in res.errors) {
                                //     //     if (res.errors[i].kind === 'required')
                                //     //         message = "Can't leave this empty";
                                //     //     else {
                                //     //         message = "Invalid Field";
                                //     //     }
                                //     //     $('#' + i).css('border-color', 'red').prop('placeholder', message);
                                //     // }
                                // }
                                else {
                                    console.log(res)
                                }
                            } catch (error) {
                                addNotification(error)
                            }
                        })
                        .catch((error) => {
                            addNotification(error)
                        })
                }
            } catch (err) {
                console.log(err)
            }
        } catch (error) {
            addNotification(error)
        }
    }
    let removeAuthList = (e) => {
        try {
            let formJson = {}
            formJson["deviceId"] = props.asset._id
            formJson["action"] = "delete"
            formJson["idTag"] = e.idTag
            formJson["expiryDate"] = e.expiryDate
            API_UpdateDeviceAuth(formJson)
                .then((res) => {
                    try {
                        if (res.status === 200) {
                            props.setAsset(res.doc)
                        } else {
                            console.log(res)
                        }
                    } catch (error) {
                        addNotification(error)
                    }
                })
                .catch((error) => {
                    addNotification(error)
                })
        } catch (err) {
            console.log(err)
        }
    }
    let getUserMetrics = (e) => {
        try {
            let today = new Date()
            var lastWeek = new Date()
            var weekInMilliseconds = 7 * 24 * 60 * 60 * 1000
            lastWeek.setTime(lastWeek.getTime() - weekInMilliseconds)

            let day = today.getDate()
            let month = today.getMonth() + 1
            let year = today.getFullYear()
            let _lastWeekDay = lastWeek.getDate()
            let _lastWeekMonth = lastWeek.getMonth() + 1
            let _lastWeekYear = lastWeek.getFullYear()
            let obj = {
                chargingStation: props.asset._id,
                idTag: activeUser.idTag,
                createdAt: {
                    $gte: `${_lastWeekYear}-${_lastWeekMonth}-${_lastWeekDay}`,
                    $lt: `${year}-${month}-${day}`,
                },
            }
            API_GetUserMetrics(obj)
                .then((d) => {
                    try {
                        let sessionLast = new Date(d.lastSessionTime)
                        if (d.lastSessionTime !== 0) {
                            d.lastSessionTime =
                                sessionLast.getDate() +
                                "/" +
                                sessionLast.getMonth() +
                                "/" +
                                sessionLast.getFullYear()
                        } else {
                            d.lastSessionTime = "None"
                        }
                        d.averageChargeTime = secondToString(
                            d.averageChargeTime
                        )
                        d.isActive = d.isActive ? "True" : "False"
                        setUserMetrics(d)
                    } catch (error) {
                        addNotification(error)
                    }
                })
                .catch((error) => {
                    addNotification(error)
                })
        } catch (error) {
            addNotification(error)
        }
    }
    let isFormValid = (formArray) => {
        try {
            let nullCounter = 0
            formArray.forEach((e, j) => {
                let obj = $('[name="' + e.name + '"]')
                if (obj.length > 0) {
                    obj.each((i, elm) => {
                        let elmName = e.name
                        let tag = $('[name="' + elmName + '"]').prop("tagName")
                        if ($("#label_" + elmName).filter(":visible").length) {
                            let message = ""
                            if (
                                e.value === null ||
                                e.value === "" ||
                                e.value === "None"
                            ) {
                                message = $('[name="' + elmName + '"]').attr(
                                    "data-error"
                                )
                                if (tag === "SELECT") {
                                    $(
                                        '[name="' + elmName + '"] option:first'
                                    ).text(message)
                                    $('[name="' + elmName + '"]').addClass(
                                        "inputError"
                                    )
                                } else {
                                    $('[name="' + elmName + '"]')
                                        .attr("placeholder", message)
                                        .addClass("inputError")
                                }

                                nullCounter++
                            } else {
                                message = $('[name="' + elmName + '"]').attr(
                                    "data-norm"
                                )
                                if (tag === "SELECT") {
                                    $(
                                        '[name="' + elmName + '"] option:first'
                                    ).text(message)
                                    $('[name="' + elmName + '"]').removeClass(
                                        "inputError"
                                    )
                                } else {
                                    $('[name="' + elmName + '"]')
                                        .attr("placeholder", message)
                                        .removeClass("inputError")
                                }
                            }
                        }
                    })
                }
            })
            if (nullCounter > 0) {
                return false
            } else {
                return true
            }
        } catch (error) {
            addNotification(error)
        }
    }
    useEffect(async () => {
        try {
            renderAuthRows()
        } catch (error) {
            addNotification(error)
        }
    }, [props.asset])
    useEffect(() => {
        try {
            if (activeUser?.idTag) {
                $(".listRow").removeClass("selected")
                $("#" + activeUser._id).addClass("selected")
                getUserMetrics()
            }
        } catch (error) {
            addNotification(error)
        }
    }, [activeUser])
    return (
        <div>
            <div className="viewButtons">
                <CreateButton
                    text="Add User Access"
                    class="createInputButton"
                />
                {/* <SelectInput defaultValue={'Select Table View'} optionsList={[{ name: 'Authentication', val: 'authentication', iconClass: 'fa-solid fa-microchip' }, { name: 'Transactions', val: 'transactions', iconClass: 'fa-solid fa-charging-station' }]} /> */}
            </div>
            <div className="splitTableViewer">
                <div>
                    <div className="inputContent">
                        <div className="listTable">
                            <div className="listTableHeader">
                                <p>Username</p>
                                <p>IdTag</p>
                                <p>Expiry Date</p>
                                <p>Billing</p>
                            </div>
                            <div className="listTableRows">{authList}</div>
                        </div>
                    </div>
                </div>
                <div className="sectionContent">
                    <div className="userMetricsView">
                        <h1>Usage Analytics</h1>
                        <div className="detailAnalyticsContainer">
                            {activeUser ? (
                                <div
                                    id="dashboardMetrics"
                                    className="dashboardMetricsContainer"
                                >
                                    <div className="metric">
                                        <i
                                            className="fa-solid fa-money-check-dollar"
                                            style={{ color: "green" }}
                                        ></i>
                                        <div>
                                            <span
                                                id="bl"
                                                style={{ color: "green" }}
                                            >
                                                {userMetrics.isActive}
                                            </span>
                                            <h1>Active Session</h1>
                                        </div>
                                    </div>
                                    <div className="metric">
                                        <i
                                            className="fa-solid fa-clock"
                                            style={{ color: "#1783bb" }}
                                        ></i>
                                        <div>
                                            <span
                                                id="bl"
                                                style={{ color: "#1783bb" }}
                                            >
                                                {userMetrics.lastSessionTime}
                                            </span>
                                            <h1>Last Login Time</h1>
                                        </div>
                                    </div>
                                    <div className="metric">
                                        <i className="fa-solid fa-network-wired"></i>
                                        <div>
                                            <span
                                                id="rul"
                                                style={{ color: "#e08357" }}
                                            >
                                                {userMetrics.averageChargeTime}
                                            </span>
                                            <h1>Average Charge Time</h1>
                                        </div>
                                    </div>
                                    <div className="metric">
                                        <i className="fa-solid fa-bolt"></i>
                                        <div>
                                            <span
                                                id="rul"
                                                style={{ color: "#e08357" }}
                                            >
                                                {
                                                    userMetrics.totalChargeSessions
                                                }
                                            </span>
                                            <h1>Total Charge Sessions</h1>
                                        </div>
                                    </div>
                                    <div className="metric">
                                        <i
                                            className="fa-solid fa-money-check-dollar"
                                            style={{ color: "green" }}
                                        ></i>
                                        <div>
                                            <span
                                                id="bl"
                                                style={{ color: "green" }}
                                            >
                                                ₹{userMetrics.totalAmount}
                                            </span>
                                            <h1>Total Expense (₹)</h1>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div></div>
                            )}
                            <div className="userMetricCharts">
                                <div
                                    id="parentRadialChart"
                                    className="analytic"
                                    style={{ minWidth: "300px" }}
                                >
                                    <UserChargeByHourOfDay
                                        asset={props.asset}
                                        activeUser={activeUser}
                                        ref={radialRef}
                                        height={"inherit"}
                                    />
                                </div>
                                <div
                                    id="parentRadialChart"
                                    className="analytic"
                                    style={{ minWidth: "300px" }}
                                >
                                    <UserChargeByDay
                                        asset={props.asset}
                                        activeUser={activeUser}
                                        ref={radialRef}
                                        height={"inherit"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {activeUser && <div className='sectionContent'>
                    <div className='userManagement'>
                        <h1>User Management</h1>
                        <div className='detailUserForm'>
                            <div className='formInputs'>
                                <label>Username: </label><input className='Input' placeholder='Username' value={"Username"} disabled={true} />
                            </div>
                            <div className='formInputs'>
                                <label>Email: </label><input className='Input' placeholder='Email' value={"Email"} disabled={true} />
                            </div>
                            <div className='formInputs'>
                                <label>Phone Number: </label><input className='Input' placeholder='Phone Number' value={"Phone Number"} disabled={true} />
                            </div>
                            <div className='formInputs'>
                                <label>RFID: </label><input className='Input' placeholder='RFID' value={"RFID"} disabled={true} />
                            </div>
                            <div className='formInputs'>
                                <label>Vehicle Number: </label><input className='Input' placeholder='Vehicle number' value={"Vehicle number"} disabled={true} />
                            </div>
                        </div>
                        <button className='primaryButton' onClick={() => { $('#billingDetails').fadeToggle() }}>Billing Details</button>
                        <div className='detailUserForm' id="billingDetails">
                            <div className='formInputs'>
                                <label>Session Tariff: </label><input className='Input' placeholder='Session Tarrif' value={"0"} disabled={true} />
                            </div>
                            <div className='formInputs'>
                                <label>Station Tariff </label><input className='Input' placeholder='Station Tariff' value={"0"} disabled={true} />
                            </div>
                            <div className='formInputs'>
                                <label>Project Tariff </label><input className='Input' placeholder='Project Tariff' value={"0"} disabled={true} />
                            </div>
                            <div className='formInputs'>
                                <label>Reservation Fee: </label><input className='Input' placeholder='Reservation Fee' value={"0"} disabled={true} />
                            </div>
                            <div className='formInputs'>
                                <label>Total Amount Billable for 1Kwh: </label><input className='Input' placeholder='0' value={"0"} disabled={true} />
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
            <CreateModal
                type="Auth"
                submit={() => {
                    addAuthList()
                }}
            />
        </div>
    )
}

export default AccessController
