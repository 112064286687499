import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { API_MonthByMonthRevenue } from '../../../../api/device';
import HC_export_data from "highcharts/modules/export-data";
import HC_exporting from 'highcharts/modules/exporting'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Loader from '../../../loader/Loader';
import { addNotification } from '../../../../utils/addNotification';
import highchartsMore from 'highcharts/highcharts-more'

const MonthByMonthRevenue = forwardRef((props, ref) => {
    let opt = {
        chart: {
            zoomType: 'xy',
            backgroundColor: '#e2e2e200',
            plotBorderWidth: 0,
            reflow: true,
            type: 'column',
        },
        exporting: {
            enabled: true,
            buttons: {
                contextButton: {
                    symbolFill: "#989898",
                    theme: {
                        fill: "#e2e2e200",
                        padding: 5,
                        stroke: "none",
                    },
                    symbol: 'menu',
                    menuItems: [
                        "viewFullscreen",
                        "printChart", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG",
                        {
                            text: "Download CSV File",
                            onclick() {
                                let csv = this.getCSV();
                                const blob = new Blob([csv], { type: 'text/csv' });

                                // Creating an object for downloading url
                                const url = window.URL.createObjectURL(blob)

                                // Creating an anchor(a) tag of HTML
                                const a = document.createElement('a')

                                // Passing the blob downloading url 
                                a.setAttribute('href', url)

                                // Setting the anchor tag attribute for downloading
                                // and passing the download file name
                                a.setAttribute('download', 'download.csv');

                                // Performing a download with click
                                a.click()
                            }
                        },
                    ],
                }
            },
            y: -200
        },
        title: {
            align: 'center',
            text: ' '
        },
        subtitle: {
            align: 'center',
            text: 'Charge Sessions categorized by usage, with a drill down feature to the individual dates'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: 'Revenue Generated'
            }

        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: true,
                    format: '₹{point.y:.1f}'
                }
            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>INR {point.y:.2f}</b><br/>'
        },

        series: [
            {
                name: 'Usage Type',
                colorByPoint: true,
                data: []
            }
        ],
        credits: {
            enabled: false,
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        floating: false,
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom',
                        x: 0,
                        y: 0
                    },
                    yAxis: [{
                        labels: {
                            align: 'right',
                            x: 0,
                            y: -6
                        },
                        showLastLabel: false
                    }, {
                        labels: {
                            align: 'left',
                            x: 0,
                            y: -6
                        },
                        showLastLabel: false
                    }, {
                        visible: false
                    }]
                }
            }]
        }
    };
    let [seriesData, setSeriesData] = useState(null)
    let [options, setOptions] = useState(null);
    let chartComponent = useRef();
    useImperativeHandle(
        ref,
        () => ({
            resizeChart() {
                try {
                    chartComponent.current.chart.reflow();
                } catch (error) {
                    addNotification(error);
                }
            }
        }),
    )
    useEffect(() => {
        try {
            if (props.asset?._id) {
                API_MonthByMonthRevenue({ chargingStation: props.asset._id }).then(d => {
                    try {
                        console.log(d);
                        let seriesData = d.usageByDuration;
                        let _opt = opt;
                        _opt.series[0].data = seriesData;
                        setOptions(_opt);
                    } catch (error) {
                        addNotification(error);
                    }
                }).catch(error => { addNotification(error); });
            }
        } catch (error) {
            addNotification(error);
        }
    }, [props.asset])
    HC_exporting(Highcharts);
    HC_export_data(Highcharts);
    return (
        <div id="barChart" className='dashboardChartContainer'>
            {
                options ? <HighchartsReact highcharts={highchartsMore(Highcharts)} ref={chartComponent} options={options} containerProps={{ className: 'chart', style: { height: props.height } }} />
                    : <Loader />
            }
        </div>
    )
});

export default MonthByMonthRevenue;