import { useEffect, useState } from "react";
import { addNotification } from '../../utils/addNotification'

function SearchInput(props) {
    const [filteredList, setFilteredList] = useState(null);

    try {
        const filterBySearch = (event) => {
            try {
                // Access input value
                const query = event.target.value;
                // Create copy of item list
                var updatedList = [...props.data];
                // Include all elements which includes the search query
                updatedList = updatedList.filter((i) => props.filter(i, query));
                // Trigger render with updated values
                setFilteredList(updatedList);
                props.searchResult(updatedList);
            } catch (error) {
                addNotification(error);
            }
        };
        useEffect(() => {
            try {
                setFilteredList(props.data);
            } catch (error) {
                addNotification(error);
            }
        }, [props.data])
        return (
            <div>
                <input id={props.id} type="search" placeholder={props.placeholder} onChange={filterBySearch} />
            </div>
        )
    } catch (error) {
        addNotification(error);
    }
}

export default SearchInput;