"use client";

import { Route, Switch, useHistory } from "react-router-dom";
import { addNotification, setCallBack } from '../../utils/addNotification'
import { useEffect, useRef, useState } from "react";

import $ from 'jquery'
import { API_SignoutAccount } from '../../api/storage/auth'
import Alert from "../../components/alert/Alert"
import Dashboard from "./containers/dashboard/Dashboard";
import Devices from "./containers/devices/Devices";
import Landing from "./containers/Landing";
import Playground from "./containers/playground/Playground";
import Projects from "./containers/projects/Projects";
import Sessions from "./containers/sessions/Sessions";
import Settings from './containers/settings/Settings'

function Account(props) {
    const history = useHistory();
    let [profile, setProfile] = useState(JSON.parse(localStorage.getItem('profile')))
    let timer = useRef();;
    let [notifications, setNotifications] = useState(null);
    let [notificationStack, setNotificationStack] = useState([]);

    function addComponentToNotification(error) {
        setNotifications((notification) => {
            let modNotifications = [...notificationStack];
            modNotifications.push(error);
            setNotificationStack(modNotifications);
            console.error(error);
            return (
                <div key={modNotifications.length + 1} className="notifications">
                    <i className="fa fa-exclamation-circle"></i>
                    <p>{error?.message || "Something Went Wrong, Click Here."}</p>
                    <i className="fa fa-close close" onClick={(event) => { setNotifications(null) }}></i>
                </div>);
        });
    }

    useEffect(() => {
        if (!(localStorage.getItem("auth") && localStorage.getItem("organizationId") && localStorage.getItem("profile") && localStorage.getItem("accountId"))) {
            localStorage.clear();
            history.push('/client/public');
        }
        $('#divAccountNavBar').on('mouseenter', (e) => {
            timer.current = setTimeout(function () {
                try {
                    $('.accountNavBar i').hide();
                    if ($(window).width() > 400) {
                        $('.detailModal').css({ width: 'calc(100vw - 255px)', left: "255px" });
                        $('#divAccountContent').animate({ marginLeft: "350px" });
                    }
                    else {
                        $('.detailModal').css({ width: 'calc(100vw - 255px)', left: "60vw" });
                        $('#divAccountContent').animate({ marginLeft: "66vw" });
                    }
                    $('.accountNavBar span').show();
                    $('.childNav').show();
                    $('.childNav').animate({ height: "150px", left: "0px", opacity: 1 }, () => {
                        $('.childNav h1').show();
                        $('.childNav i').show();
                    });
                } catch (error) {
                    addNotification(error);
                }
            }, 500);
        });
        $('#divAccountNavBar').on('mouseleave', (e) => {
            try {
                clearTimeout(timer.current);
                $('.accountNavBar span').hide();
                if ($(window).width() > 400) {
                    $('.detailModal').css({ width: 'calc(100vw - 100px)', left: "100px" });
                    $('#divAccountContent').animate({ marginLeft: "200px" });
                }
                else {
                    $('.detailModal').css({ width: 'calc(100vw - 100px)', left: "20vw" });
                    $('#divAccountContent').animate({ marginLeft: "23vw" });
                }
                $('.accountNavBar i').show();
                $('.childNav h1').hide();
                $('.childNav').animate({ height: "-150px", left: "0px", opacity: 0 });
                $('.childNav').hide();
            } catch (error) {
                addNotification(error)
            }
        });
        // $('#h1Nav_Dashboard').on('mouseenter', () => {
        //     timer = setTimeout(function () {
        //         $('.childNav').show();
        //         $('.childNav').animate({ height: "100px", left: "0px", opacity: 1 }, () => {
        //             $('.childNav h1').show();
        //         });
        //     }, 300)
        // })
        $('#divAccountNavBar h1').on('click', (e) => {
            try {
                if (e.currentTarget.classList[0] !== 'inactiveNavItem') {
                    $('#divAccountNavBar h1').removeClass('activeNavItem');
                    if (e.currentTarget.id !== '') {
                        $('#' + e.currentTarget.id).addClass('activeNavItem');
                        if (e.currentTarget.id === "h1Nav_Logout") {
                            API_SignoutAccount(() => {
                                history.push('/client/public')
                            })
                        }
                        else {
                            history.push(props.match.url + '/' + e.currentTarget.dataset["route"])
                        }
                    }
                }
            } catch (error) {
                addNotification(error)
            }
        });
        setCallBack(addComponentToNotification);
    }, [])
    return (
        <div className="accountParentContainer">
            <div id="divAccountNavBar" className="accountNavBar">
                <div id="divLogo" className="accountLogo">
                    <h1 data-route="home"><i className="fab fa-watchman-monitoring"></i><span>Lighthouse</span></h1>
                </div>
                <div id="divNavContextMenu" >
                    <h1 id="h1Nav_Home" data-route="home"><i className="fas fa-home"></i><span>Home</span></h1>
                    <h1 id="h1Nav_Dashboard" data-route="dashboard/analytics"><i className="fas fa-tachometer-alt"></i><span>Dashboard</span></h1>
                    {/* <div className="childNav">
                        <h1 id="h1Nav_Dashboard" className="inactiveNavItem" data-route="dashboard/analytics"><span>Control Panel</span></h1>
                        <h1 id="h1Nav_Dashboard" data-route="Dashboard/Formula_Builder"><span>Formula Builder</span></h1>
                        <h1 id="h1Nav_Analytics" data-route="dashboard/analytics"><span>Analytics</span></h1>
                    </div> */}
                    {/* <h1 id="h1Nav_Analytics" data-route="Analytics"><i className="fas fa-chart-pie"></i><span>Analytics</span></h1> */}
                    <h1 id="h1Nav_Control" data-route="devices"><i className="fas fa-satellite-dish"></i><span>Control Panel</span></h1>
                    <div className="childNav">
                        <h1 id="h1Nav_Devices" data-route="devices"><i className="fas fa-satellite-dish"></i><span>Devices</span></h1>
                        <h1 id="h1Nav_Projects" data-route="projects"><i className="fa-solid fa-location-crosshairs"></i><span>Projects</span></h1>
                        <h1 id="h1Nav_Sessions" data-route="sessions"><i className="fa-solid fa-file-invoice"></i><span>Sessions</span></h1>
                    </div>
                    <h1 id="h1Nav_Monitors" data-route="monitors" className="inactiveNavItem"><i className="far fa-bell"></i><span>Monitors</span></h1>
                    <h1 id="h1Nav_Playground" data-route="playground" className="inactiveNavItem"><i className="far fa-paper-plane"></i><span>Playground</span></h1>
                </div>
                <div id="divNavAccountMenu">
                    <h1 id="h1Nav_Settings" data-route="settings" className=""><i className="fas fa-cog"></i><span>Settings</span></h1>
                    <h1 id="h1Nav_Help" data-route="help" className="inactiveNavItem"><i className="fas fa-info-circle"></i><span>Help</span></h1>
                    <h1 id="h1Nav_Logout" data-route="logout"><i className="fas fa-sign-out-alt"></i><span>Logout</span></h1>
                </div>
            </div>
            <div id="divAccountContent" className="accountContent">
                <div id="divNotificationHandler" style={{ width: "100%" }}>
                    {notifications}
                </div>
                {/* <Alert isActive={true} alertContent={"Warning: Therm_401234 is not fully operational (22 additional alerts that require immediate review). Click here to redirect to Alerts Manager"} /> */}
                <Switch>
                    <Route exact path={props.match.url + "/"}>
                        <Landing profile={profile} match={props.match} />
                    </Route>
                    <Route path={props.match.url + "/home"}>
                        <Landing profile={profile} match={props.match} />
                    </Route>
                    <Route path={props.match.url + "/dashboard"} component={Dashboard} />
                    <Route path={props.match.url + "/settings"} component={Settings} />
                    <Route path={props.match.url + "/devices"} component={Devices} />
                    <Route path={props.match.url + "/projects"} component={Projects} />
                    <Route path={props.match.url + "/playground"} component={Playground} />
                    <Route path={props.match.url + "/sessions"} component={Sessions} />
                </Switch>
            </div>
        </div>
    );
}

export default Account