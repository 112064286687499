import Ajv from 'ajv';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
import { addNotification } from '../../../utils/addNotification';
function deviceSchema(scm) {
  try {
    let schema = (scm) => {
      try {
        if (!scm.location) {
          scm.location = { lat: null, lon: null };
        }
        return {
          title: 'Device',
          type: 'object',
          properties: {
            _id: { type: 'string', pattern: '^[0-9a-fA-F]{24}$', readOnly: true },
            deviceName: { type: 'string' },
            deviceStatus: { type: 'string', enum: ['Active', 'Inactive', 'Breakdown', 'Repair'] },
            deviceType: { type: 'string', enum: ['ChargingStation', 'Sensor', 'Vehicle'], readOnly: true },
            providerName: { type: 'string', readOnly: true },
            projectId: { type: 'string', pattern: '^[0-9a-fA-F]{24}$', readOnly: true },
            connectionString: { type: 'string' },
            apiKey: { type: 'string' },
            lat: { type: 'number' },
            lon: { type: 'number' },
            address: { type: 'string' },
            deviceDescription: { type: 'string' },
            directionsDescription: { type: 'string' },
            installationDate: { type: 'string', format: 'date-time' },
            updatedAt: { type: 'string', format: 'date-time', readOnly: true },
            createdAt: { type: 'string', format: 'date-time', readOnly: true },
          },
          required: ['deviceName', 'deviceType']
        }
      } catch (error) {
        addNotification(error);
      }
    };
    const _schema = schema(scm);
    let propKeys = Object.keys(_schema.properties);
    propKeys.forEach(e => {
      if (e === 'lat' && scm.location && scm.location['lat']) {
        _schema.properties[e].default = scm.location['lat'];
      }
      else if (e === 'lon' && scm.location && scm.location['lon']) {
        _schema.properties[e].default = scm.location['lon'];
      }
      else {
        _schema.properties[e].default = scm[e];
      }
    })
    const ajv = new Ajv({ allErrors: true, useDefaults: true });

    function createValidator() {
      try {
        const validator = ajv.compile(_schema);

        return (model) => {
          try {
            validator(model);
            return validator.errors?.length ? { details: validator.errors } : null;
          } catch (error) {
            addNotification(error);
          }
        };
      } catch (error) {
        addNotification(error);
      }
    }

    const schemaValidator = createValidator(_schema);
    return new JSONSchemaBridge(_schema, schemaValidator);
  } catch (error) {
    addNotification(error);
  }
}

export { deviceSchema }