import { useEffect, useRef } from "react";

import $ from 'jquery';
import { addNotification } from "../../utils/addNotification";

const AddressSearchBar = (props) => {
    const autoCompleteRef = useRef();
    const inputRef = useRef();
    const options = {
        fields: ["address_components", "geometry", "icon", "name"],
        types: ["establishment"]
    };
    useEffect(() => {
        try {
            if (props.inputvalue) {
                $('#addressInput').val(props.inputvalue).trigger('input');
            }
            if (window.google) {
                autoCompleteRef.current = new window.google.maps.places.Autocomplete(
                    inputRef.current,
                    options
                );
                autoCompleteRef.current.addListener("place_changed", async function () {
                    const place = await autoCompleteRef.current.getPlace();
                    if (place?.address_components) {
                        let address = place.address_components.map(e => {
                            return e.short_name;
                        }).join(', ')
                        $('#addressInput').val(place.name + ', ' + address).trigger('input');
                        $('input[name="lat"]').val(place.geometry.location.lat()).trigger("input")
                        $('input[name="lon"]').val(place.geometry.location.lng()).trigger("input")
                    }
                    else{
                        $("#addressInput").val('');
                    }
                });
            }
        } catch (error) {
            addNotification(error);
        }
        if($('input[name="lat"]').val()==='' || $('input[name="lat"]').val()==='')
        {
            $("#addressInput").val('');
        }
    }, []);
    return (
        <div className={"field " + props.class}>
            <label id={props.for ? 'label_' + props.for : ''} htmlFor={props.for || "deviceConfigForm"}>{props.label}</label>
            <div className={props.class ? "" : "ui input"}>
                <input name="address" id="addressInput" ref={inputRef} />
            </div>
        </div>
    );
};
export default AddressSearchBar;