import './Error.scss'
import { useParams } from 'react-router-dom';


function Error() {
    let urlParams = useParams();
    let errImg = "https://cdn.dribbble.com/userupload/4060799/file/original-c8d3f500ec2ef0185efb096db31966a9.png?resize=640x480&vertical=center";
    return (
        <div className='loader'>
            <div className='loadingContent'>
                <image src={errImg}></image>
                <div className='loaderText'>
                    <p>
                        {urlParams?.message? urlParams.message:"Our Premium Plan is Faster..."}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Error