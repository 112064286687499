import { API_CreateProject, API_GetAllProjects } from '../../../../api/project';
import { Link, useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react';

import $ from 'jquery';
import CreateButton from '../../../../components/modal/CreateButton';
import CreateModal from '../../../../components/modal/CreateModal'
import Loader from '../../../../components/loader/Loader';
import NoData from '../../../../components/nodata/NoData';
import SearchInput from '../../../../components/searchInput/SearchInput';
import { addNotification } from '../../../../utils/addNotification';

function ProjectsList(props) {
    let [projects, setProjects] = useState(null);
    let [projectList, setProjectList] = useState(null);
    let history = useHistory();
    let handleProjectDetails = (obj) => {
        try {
            history.push({
                pathname: props.match.url + '/' + obj._id,
                state: obj
            })
        } catch (error) {
            addNotification(error);
        }
    }
    let createProject = async () => {
        try {
            let projectForm = $('.createModal form').serializeArray();
            var formJson = {};
            if (isFormValid(projectForm)) {
                for (var i = 0; i < projectForm.length; i++) {
                    formJson[projectForm[i].name] = projectForm[i].value;
                }
                if (formJson['lat'] || formJson['lon']) formJson.location = { lat: formJson['lat'], lon: formJson['lon'] }
                let res = await API_CreateProject(formJson)
                document.getElementById("formCreateDevice").reset();
                if (res.status === 200) {
                    getProjectList();
                    $('.modalBackground').hide();
                }
                else if (res.status === 400) {
                    if (res.err && res.err?.code === 11000) {
                        let fieldNames = Object.keys(res.err.keyValue);
                        fieldNames.forEach((e, i) => {
                            $('#' + e).attr('placeholder', 'This field needs to be unique, please renter.').addClass('inputError');
                        })
                    }
                    else {
                        $('input').val('').attr('placeholder', res?.message).addClass('inputError')
                        $('select').val('').attr('placeholder', res?.message).addClass('inputError')
                        $('textarea').val('').attr('placeholder', res?.message).addClass('inputError')
                    }
                }
                else {
                    $('input').val('').attr('placeholder', 'Invalid Entry').addClass('inputError')
                    $('select').val('').attr('placeholder', 'Invalid Entry').addClass('inputError')
                    $('textarea').val('').attr('placeholder', 'Invalid Entry').addClass('inputError')
                    console.log(res)
                };
            }
        }
        catch (err) {
            addNotification(err);
        }
    }
    let isFormValid = (formArray) => {
        try {
            let nullCounter = 0;
            formArray.forEach((e, j) => {
                let obj = $('[name="' + e.name + '"]');
                if (obj.length > 0) {
                    obj.each((i, elm) => {
                        let elmName = e.name;
                        let tag = $('[name="' + elmName + '"]').prop('tagName');
                        let message = '';
                        if (e.value === null || e.value === "" || e.value === "None") {
                            if(e.name==='lat' || e.name==='lon'){
                                message = "Select Address from below List"
                                if($('[name="' + 'address' + '"]').val()===""){
                                    message = "Address is a Required Field"
                                }
                                $('[name="' + 'address' + '"]').val('')
                                $('[name="' + 'address' + '"]').attr('placeholder', message).addClass('inputError')
                            }
                            message = $('[name="' + elmName + '"]').attr('data-error');
                            if (tag === 'SELECT') {
                                $('[name="' + elmName + '"] option:first').text(message);
                                $('[name="' + elmName + '"]').addClass('inputError');
                            }
                            else {
                                $('[name="' + elmName + '"]').attr('placeholder', message).addClass('inputError')
                            }

                            nullCounter++
                        }
                        else {
                            message = $('[name="' + elmName + '"]').attr('data-norm');
                            if (tag === 'SELECT') {
                                $('[name="' + elmName + '"] option:first').text(message);
                                $('[name="' + elmName + '"]').removeClass('inputError');
                            }
                            else {
                                $('[name="' + elmName + '"]').attr('placeholder', message).removeClass('inputError')
                            }
                        }

                    });
                }
            })
            if (nullCounter > 0) {
                return false;
            }
            else {
                return true;
            }
        } catch (error) {
            addNotification(error);
        }
    }
    let getProjectList = async () => {
        try {
            API_GetAllProjects().then(resp => {
                try {
                    let _projects = resp.projects;
                    _projects = _projects.filter(e => { return e !== null });
                    _projects.sort((a, b) => { return a._id - b._id });
                    setProjects(_projects);
                } catch (error) {
                    addNotification(error);
                }
            }).catch(err => {
                addNotification(err);
            });
        } catch (error) {
            addNotification(error);
        }
    }
    let renderProjectList = (_projects) => {
        try {
            let projectList = _projects.map(e => {
                let classname;
                if (e.locationCategory === 'Office') classname = 'fa-solid fa-building'
                else if (e.locationCategory === 'Muncipal') classname = 'fa-solid fa-people-roof'
                else if (e.locationCategory === 'Parking') classname = 'fa-solid fa-square-parking'
                else if (e.locationCategory === 'Retail') classname = 'fa-solid fa-shop'
                else if (e.locationCategory === 'Residential') classname = 'fa-solid fa-house'
                return (
                    <div id={e._id} key={e._id} className="listRow" onClick={() => handleProjectDetails(e)}>
                        <i className={classname}></i>
                        <p>{e.projectName}</p>
                        <p>{e.projectType}</p>
                        <p>{e.accessCategory}</p>
                        <p>{e.locationCategory}</p>
                        <p className={e.projectStatus}><i style={{ marginRight: "7px" }} className="fa-solid fa-circle-dot"></i>{e.projectStatus}</p>
                    </div>
                )
            });
            setProjectList(projectList);
        } catch (error) {
            addNotification(error);
        }
    }
    let searchFilter = (item, query) => {
        try {
            return (item.projectName.toLowerCase().includes(query.toLowerCase()) || item.projectType.toLowerCase().includes(query.toLowerCase()) || item.locationCategory.toLowerCase().includes(query.toLowerCase()) || item.accessCategory.toLowerCase().includes(query.toLowerCase()) || item.projectStatus.toLowerCase().includes(query.toLowerCase()))
        } catch (error) {
            addNotification(error);
        }
    }
    let handleProjectList = () => {
        try {
            if (projects) {
                if (projects.length > 0) {
                    return (
                        <div className="listTable">
                            <div className="listTableHeader">
                                <i className=""></i>
                                <p>Name</p>
                                <p>Project Type</p>
                                <p>Access Type</p>
                                <p>Location Type</p>
                                <p>Status</p>
                            </div>
                            <div className="listTableRows">
                                {projectList}
                            </div>
                        </div>
                    )
                }
                else {
                    return (
                        <NoData />
                    )
                }
            }
            else {
                return (
                    <Loader />
                )
            }
        } catch (error) {
            addNotification(error);
        }
    }
    useEffect(() => {
        try {
            getProjectList();
        } catch (error) {
            addNotification(error);
        }
    }, [])
    useEffect(() => {
        try {
            if (projects) {
                renderProjectList(projects);
            }
        } catch (error) {
            addNotification(error);
        }
    }, [projects])
    return (
        <div id="divSettings" className="accountContainer">
            <div id="divManageDevices">
                <div className="sectionHeader">
                    <div style={{ width: "80%" }}>
                        <h1>Manage Projects</h1>
                        <SearchInput id={"projectSearch"} placeholder={"Search Projects"} data={projects} filter={searchFilter} searchResult={e => {
                            try { renderProjectList(e) } catch (error) {
                                addNotification(error);
                            }
                        }} />
                    </div>
                    <div style={{ height: "80px", marginLeft: "15px", display: "flex", alignItems: "flex-end" }}>
                        <CreateButton text="Create Project" style={{ top: "3px", height: "40px" }} />
                        {/* <SelectInput defaultValue={'Filter By Type'} optionsList={[{name: 'Devices', val:'devices', iconClass:'fa-solid fa-microchip'}, {name: 'Charging Stations', val:'ChargingStations', iconClass:'fa-solid fa-charging-station'}, {name: 'Sensors', val:'sensor', iconClass:'fa-solid fa-microchip'}, {name: 'Vehicles', val:'vehicles', iconClass:'fa-solid fa-car'}]} /> */}
                    </div>
                </div>
                <div id="divDeviceContainer" className="sectionContent">
                    {handleProjectList()}
                </div>
            </div>
            <CreateModal type="Project" submit={() => { createProject() }} />
        </div>
    )
}

export default ProjectsList;