import { API_GetAllDevice, API_GetDevice } from '../../../api/device'
import { API_GetAllProjects, API_GetProjectMetrics } from '../../../api/project';
import { useEffect, useRef, useState } from "react"
import { useHistory, useLocation } from "react-router-dom";

import $ from 'jquery'
import { API_GetDeviceMetrics } from '../../../api/device';
import { API_UpdateProfile } from '../../../api/account';
import Loader from '../../../components/loader/Loader';
import ModalChargeSessions from '../../../components/charts/chargingStation/Modal/ModalChargeSessions';
import OnBoardingUser from "../../../components/onboarding/OnBoardingUser";
import OverviewMap from "../../../components/overviewMap/OverviewMap";
import SelectInput from "../../../components/selectMenu/SelectMenu";
import { addNotification } from '../../../utils/addNotification';

function Landing(props) {
    let history = useHistory();
    let lineAreaRef = useRef();
    let location = useLocation();
    const honeyCombChartRef = useRef()
    const overviewMapRef = useRef();
    const [landingState, setLandingState] = useState(localStorage.getItem('profile').newProfile);
    const [overviewContent, setOverviewContent] = useState({ total: -1, active: -1, inactive: -1, breakdown: -1 });
    const [inventory, setInventory] = useState([]);
    const [projects, setProjects] = useState([]);
    const [activeDevice, setActiveDevice] = useState({ deviceId: 1 });
    const [activeProject, setActiveProject] = useState({ projectId: 1 });
    const [modalType, setModalType] = useState(null)
    const [activeAsset, setActiveAsset] = useState({ deviceId: 1 });
    const [metrics, setMetrics] = useState({ averageChargeTime: 0, totalEnergyConsumed: 0, totalChargeSessions: 0, utilizationRates: 0 });
    const [modalState, setModalState] = useState(null);
    const [modalView, setModalView] = useState(null);
    let isFormValid = (formArray) => {
        try {
            let nullCounter = 0;
            formArray.forEach((e, j) => {
                let obj = $('[name="' + e.name + '"]');
                if (obj.length > 0) {
                    obj.each((i, elm) => {
                        let elmName = e.name;
                        let tag = $('[name="' + elmName + '"]').prop('tagName');
                        if ($('#label_' + elmName).filter(':visible').length) {
                            let message = '';
                            if (e.value === null || e.value === "" || e.value === "None") {
                                message = $('[name="' + elmName + '"]').attr('data-error');
                                if (tag === 'SELECT') {
                                    $('[name="' + elmName + '"] option:first').text(message);
                                    $('[name="' + elmName + '"]').addClass('inputError');
                                }
                                else {
                                    $('[name="' + elmName + '"]').attr('placeholder', message).addClass('inputError')
                                }

                                nullCounter++
                            }
                            else {
                                message = $('[name="' + elmName + '"]').attr('data-norm');
                                if (tag === 'SELECT') {
                                    $('[name="' + elmName + '"] option:first').text(message);
                                    $('[name="' + elmName + '"]').removeClass('inputError');
                                }
                                else {
                                    $('[name="' + elmName + '"]').attr('placeholder', message).removeClass('inputError')
                                }
                            }
                        }
                    });
                }
            })
            if (nullCounter > 0) {
                return false;
            }
            else {
                return true;
            }
        } catch (error) {
            addNotification(error);
        }
    }
    let handleOverviewContent = () => {
        try {
            API_GetAllDevice().then(resp => {
                API_GetAllProjects().then(projectResp => {
                    let devices = resp.assets.map(e => { return e.device })
                    let assets = resp.assets.map(e => { return e.asset })
                    devices = devices.filter(e => { return e !== null });
                    assets = assets.filter(e => { return e !== null && e?.statusNotification });
                    let inactiveDevices = assets.filter(e => e.statusNotification === 'Unavailable');
                    let breakdownDevices = assets.filter(e => e.statusNotification === 'Faulted');
                    let activeDevices = assets.filter(e => e.statusNotification !== ('Unavailable' || 'Faulted'));
                    setOverviewContent({ total: devices.length, active: activeDevices.length, inactive: inactiveDevices.length, breakdown: breakdownDevices.length })
                    setInventory(resp.assets);
                    setProjects(projectResp.projects.filter(e => e !== null));
                    // setDevices(devices);
                    // setAssets(assets);
                    return resp;
                })
            }).catch(err => {
                addNotification(err);
            })
        } catch (error) {
            addNotification(error);
        }
    }
    let handleProfileUpdate = async () => {
        try {
            let form = $("#profileUpdateForm").serializeArray();
            if (isFormValid(form) === true) {
                var formJson = {};
                for (var i = 0; i < form.length; i++) {
                    formJson[form[i].name] = form[i].value;
                }
                formJson.id = props.profile._id;
                formJson.newProfile = false;
                let res = await API_UpdateProfile(formJson)
                if (res.status === 200) {
                    location.state = { profile: res.profile };
                    localStorage.setItem('profile', JSON.stringify(res.profile));
                    setLandingState(res.profile.newProfile);
                }
                else {
                    console.log(res)
                };
            }
        } catch (error) {
            addNotification(error);
        }
    }
    function secondToString(seconds) {
        try {
            var sec_num = parseInt(seconds, 10); // don't forget the second param
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);

            if (hours < 10) { hours = "0" + hours; }
            if (minutes < 10) { minutes = "0" + minutes; }
            if (seconds < 10) { seconds = "0" + seconds; }
            return hours + 'H ' + minutes + 'M';
        } catch (error) {
            addNotification(error);
        }
    }
    function calculateAverage(array) {
        try {
            var total = 0;
            var count = 0;

            array.forEach(function (item, index) {
                total += item;
                count++;
            });

            return total / count;
        } catch (error) {
            addNotification(error);
        }
    }
    let handleDeviceModal = (e) => {
        try {
            setModalState(false);
            $('#sensorDetailModal').animate({ opacity: 1 }, () => {
                $('#sensorDetailModal').animate({ height: '400px' }, () => {
                });
            });
            let today = new Date();
            var lastMonth = new Date();
            var weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
            lastMonth.setTime(lastMonth.getTime() - weekInMilliseconds);

            let day = today.getDate();
            let month = today.getMonth() + 1;
            let year = today.getFullYear();
            let _lastWeekDay = lastMonth.getDate();
            let _lastWeekMonth = lastMonth.getMonth() + 1;
            let _lastWeekYear = lastMonth.getFullYear();
            let obj = {
                chargingStation: e.asset._id,
                createdAt: {
                    "$gte": lastMonth.toISOString(),
                    "$lte": today.toISOString()
                }
            }
            API_GetDeviceMetrics(obj).then(d => {
                setActiveDevice(e.device);
                setActiveAsset(e.asset);
                setMetrics(d)
                setModalType('Devices');
                setModalState(true);
            }).catch(err => {
                addNotification(err);
            });
        } catch (error) {
            addNotification(error);
        }
    }

    let handleProjectModal = (e) => {
        try {
            setModalState(false);
            $('#sensorDetailModal').animate({ opacity: 1 }, () => {
                $('#sensorDetailModal').animate({ height: '400px' }, () => {
                });
            });
            let today = new Date();
            var lastMonth = new Date();
            var weekInMilliseconds = 7 * 24 * 60 * 60 * 1000;
            lastMonth.setTime(lastMonth.getTime() - weekInMilliseconds);

            let day = today.getDate();
            let month = today.getMonth() + 1;
            let year = today.getFullYear();
            let _lastWeekDay = lastMonth.getDate();
            let _lastWeekMonth = lastMonth.getMonth() + 1;
            let _lastWeekYear = lastMonth.getFullYear();
            let obj = {
                project: e.project._id,
                createdAt: {
                    "$gte": lastMonth.toISOString(),
                    "$lte": today.toISOString()
                }
            }
            API_GetProjectMetrics(obj).then(d => {
                setActiveProject(e);
                setMetrics(d)
                setModalType('Projects');
                setModalState(true);
            }).catch(err => {
                addNotification(err);
            });
        } catch (error) {
            addNotification(error);
        }
    }

    let goToManageDevice = () => {
        try {
            history.push({
                pathname: props.match.url + '/devices/' + activeDevice._id,
                state: activeDevice,
            })
            $('#h1Nav_Home').removeClass('activeNavItem');
            $('#h1Nav_Devices').addClass('activeNavItem');
        } catch (error) {
            addNotification(error);
        }
    }

    let goToManageProject = () => {
        try {
            history.push({
                pathname: props.match.url + '/projects/' + activeProject.project._id,
                state: activeProject.project,
            })
            $('#h1Nav_Home').removeClass('activeNavItem');
            $('#h1Nav_Devices').addClass('activeNavItem');
        } catch (error) {
            addNotification(error);
        }
    }

    let renderOverviewContent = () => {
        try {
            if ((inventory && inventory.length > 0) && (projects && projects.length > 0)) {
                return (
                    <OverviewMap inventory={inventory} projects={projects} setModalContent={e => handleDeviceModal(e)} setProjectModalContent={e => handleProjectModal(e)} setModalState={setModalState} ref={overviewMapRef} />
                )
            }
            else {
                return (
                    <div className='parentLoaderContainer'>
                        <OverviewMap inventory={[]} setModalContent={e => handleDeviceModal(e)} setProjectModalContent={e => handleProjectModal(e)} ref={null} />
                    </div>
                )
            }
        } catch (error) {
            addNotification(error);
        }
    }
    useEffect(() => {
        try {
            handleOverviewContent();
        } catch (error) {
            addNotification(error);
        }
    }, []);
    useEffect(() => {
        try {
            if (modalState && metrics) {
                if (modalType === 'Devices' && activeDevice) {
                    setModalView([
                        <div className='detailStats'>
                            <div className='detailInfo'>
                                <h1>{activeDevice.deviceName}</h1>
                                <p className={activeDevice.deviceStatus}><i style={{ marginRight: "7px" }} className="fa-solid fa-circle-dot"></i>{activeDevice.deviceStatus}</p>
                            </div>
                            <div className="detailButtons">
                                <button onClick={() => { goToManageDevice() }}><i className="fas fa-tachometer-alt"></i></button>
                                <button onClick={() => { $('#sensorDetailModal').animate({ height: '0px' }, () => { $('#sensorDetailModal').animate({ opacity: 1 }); overviewMapRef.current.resetMapClick(); }) }}>
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                            <div>
                                <div className="detailDataContainer">
                                    <div id="divModalAnalytics" className='detailModalAnalytics'>
                                        <div id="parentLineAreaChart" className="modalChart">
                                            <ModalChargeSessions asset={activeAsset} ref={lineAreaRef} />
                                        </div>
                                    </div>
                                    <div id="dashboardMetrics" className="modalMetricsContainer">
                                        <div className="metric">
                                            <i className="fa-solid fa-bullseye" style={{ color: "green" }}></i>
                                            <div>
                                                <span id="bl" style={{ color: "green" }}>{activeAsset.statusNotification.charAt(0).toUpperCase() + activeAsset.statusNotification.slice(1) || 'Unavailable'}</span>
                                                <h1>Station Sstatus</h1>
                                            </div>
                                        </div>
                                        <div className="metric">
                                            <i className="fa-solid fa-bolt"></i>
                                            <div>
                                                <span id="bl" style={{ color: "#e08357" }}>{calculateAverage(Object.values(metrics?.utilizationRates || 0)).toFixed(2)}</span>
                                                <h1>Utilization Rate(%)</h1>
                                            </div>
                                        </div>
                                        <div className="metric">
                                            <i className="fa-solid fa-clock" style={{ color: "#1783bb" }}></i>
                                            <div>
                                                <span id="bl" style={{ color: "#1783bb" }}>{metrics?.totalChargeSessions || 0}</span>
                                                <h1>Total Charge Sessions</h1>
                                            </div>
                                        </div>
                                        <div className="metric">
                                            <i className="fa-solid fa-network-wired"></i>
                                            <div>
                                                <span id="rul" style={{ color: "#e08357" }}>{secondToString(metrics?.averageChargeTime || 0)}</span>
                                                <h1>Average Charge Time</h1>
                                            </div>
                                        </div>
                                        <div className="metric">
                                            <i className="fa-solid fa-money-check-dollar" style={{ color: "green" }}></i>
                                            <div>
                                                <span id="rul" style={{ color: "green" }}>₹{((metrics?.totalRevenue || 0)).toFixed(2)}</span>
                                                <h1>Total Revenue (₹)</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ])
                }
                else if (modalType === 'Projects' && activeProject) {
                    setModalView([
                        <div className='detailStats'>
                            <div className='detailInfo'>
                                <h1>{activeProject.project.projectName}</h1>
                                <p className={activeProject.project.projectStatus}><i style={{ marginRight: "7px" }} className="fa-solid fa-circle-dot"></i>{activeProject.project.projectStatus}</p>
                            </div>
                            <div className="detailButtons">
                                <button onClick={() => { goToManageProject() }}><i className="fas fa-tachometer-alt"></i></button>
                                <button onClick={() => { $('#sensorDetailModal').animate({ height: '0px' }, () => { $('#sensorDetailModal').animate({ opacity: 1 }); overviewMapRef.current.resetMapClick(); }) }}>
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                            <div>
                                <div className="detailDataContainer">
                                    <div id="divModalAnalytics" className='detailModalAnalytics'>
                                        <div id="parentLineAreaChart" className="modalChart">
                                            <ModalChargeSessions project={activeProject.project} ref={lineAreaRef} />
                                        </div>
                                    </div>
                                    <div id="dashboardMetrics" className="modalMetricsContainer">
                                        <div className="metric">
                                            <i className="fa-solid fa-bullseye" style={{ color: "green" }}></i>
                                            <div>
                                                <span id="bl" style={{ color: "green" }}>{activeProject?.project.devices?.filter(e => { return e?.deviceStatus === 'Active' }).length || '0'}</span>
                                                <h1>Active Devices</h1>
                                            </div>
                                        </div>
                                        <div className="metric">
                                            <i className="fa-solid fa-bolt"></i>
                                            <div>
                                                <span id="bl" style={{ color: "#e08357" }}>{calculateAverage(Object.values(metrics?.utilizationRates || 0)).toFixed(2)}</span>
                                                <h1>Utilization Rate(%)</h1>
                                            </div>
                                        </div>
                                        <div className="metric">
                                            <i className="fa-solid fa-clock" style={{ color: "#1783bb" }}></i>
                                            <div>
                                                <span id="bl" style={{ color: "#1783bb" }}>{metrics?.totalChargeSessions || 0}</span>
                                                <h1>Total Charge Sessions</h1>
                                            </div>
                                        </div>
                                        <div className="metric">
                                            <i className="fa-solid fa-network-wired"></i>
                                            <div>
                                                <span id="rul" style={{ color: "#e08357" }}>{secondToString(metrics?.averageChargeTime || 0)}</span>
                                                <h1>Average Charge Time</h1>
                                            </div>
                                        </div>
                                        <div className="metric">
                                            <i className="fa-solid fa-money-check-dollar" style={{ color: "green" }}></i>
                                            <div>
                                                <span id="rul" style={{ color: "green" }}>₹{((metrics?.totalRevenue || 0)).toFixed(2)}</span>
                                                <h1>Total Revenue (₹)</h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ])
                }
                else {
                    setModalView([
                        <div className='detailStats'>
                            <div className='detailInfo'>
                                <h1>Loading</h1>
                                <p className="Inactive"><i style={{ marginRight: "7px" }} className="fa-solid fa-circle-dot"></i>Inactive</p>
                            </div>
                            <div className="detailButtons">
                                {/* <button onClick={() => { goToManageDevice() }}><i className="fas fa-tachometer-alt"></i></button> */}
                                <button onClick={() => { $('#sensorDetailModal').animate({ height: '0px' }, () => { $('#sensorDetailModal').animate({ opacity: 1 }); overviewMapRef.current.resetMapClick(); }) }}>
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                            <div>
                                <div className="detailDataContainer">
                                    <Loader />
                                </div>
                            </div>
                        </div>
                    ])
                }
            }
            else {
                setModalView([
                    <div className='detailStats'>
                        <div className='detailInfo'>
                            <h1>Loading</h1>
                            <p className="Inactive"><i style={{ marginRight: "7px" }} className="fa-solid fa-circle-dot"></i>Inactive</p>
                        </div>
                        <div className="detailButtons">
                            {/* <button onClick={() => { goToManageDevice() }}><i className="fas fa-tachometer-alt"></i></button> */}
                            <button onClick={() => { $('#sensorDetailModal').animate({ height: '0px' }, () => { $('#sensorDetailModal').animate({ opacity: 1 }); overviewMapRef.current.resetMapClick(); }) }}>
                                <i className="fas fa-times"></i>
                            </button>
                        </div>
                        <div>
                            <div className="detailDataContainer">
                                <Loader />
                            </div>
                        </div>
                    </div>
                ])
            }
        } catch (error) {
            addNotification(error);
        }
    }, [modalState]);
    if (landingState === true) {
        return (
            <OnBoardingUser handleProfileUpdate={() => handleProfileUpdate()} match={props.match} />
        )
    }
    else {
        if (overviewContent.total !== -1) {
            return (
                <div id="landingContainer" className="landingContainer">
                    <div className="overviewContent">
                        <div className="overviewHeaderSelector">
                            <h1> Overview Map </h1>
                            {/* <SelectInput defaultValue={'Change View Type'} optionsList={[{ name: 'Map View', val: 'map', iconClass: 'fa-solid fa-map' }, { name: 'Analytic View', val: 'analytic', iconClass: 'fa-solid fa-chart-line' }]} /> */}
                        </div>
                        <p>This platform has in <span style={{ fontWeight: 600 }}>total {overviewContent.total} assets</span>, of which <span style={{ color: '#5CB42A', fontWeight: 600 }}>{overviewContent.active} are
                            online and operating in full capacity</span>, <span style={{ color: '#ff0e0e', fontWeight: 600 }}>{overviewContent.inactive} are unavailable or are inactive</span>
                            , and <span style={{ color: '#F2731E', fontWeight: 600 }}>{overviewContent.breakdown} are either faulted, or have a high likelihood of breakdown
                            </span>. Click on an individual asset to get more detailed information.</p>
                    </div>
                    {renderOverviewContent()}
                    <div id="sensorDetailModal" className="detailModal">
                        {modalView}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div id="landingContainer" className="landingContainer">
                    <div className="overviewContent">
                        <div className="overviewHeaderSelector">
                            <h1> Overview Map </h1>
                            {/* <SelectInput defaultValue={'Change View Type'} optionsList={[{ name: 'Map View', val: 'map', iconClass: 'fa-solid fa-map' }, { name: 'Analytic View', val: 'analytic', iconClass: 'fa-solid fa-chart-line' }]} /> */}
                        </div>
                    </div>
                    <div class="loadingCenter">
                        <Loader />
                    </div>
                </div>
            )
        }
    }
}

export default Landing