import { API_Create, API_GetAllDevice } from '../../../../api/device';
import { Link, useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react';

import $ from 'jquery';
import { API_CreateDevice } from '../../../../api/device';
import { API_OrganizationSessions } from '../../../../api/org';
import CreateButton from '../../../../components/modal/CreateButton';
import CreateModal from '../../../../components/modal/CreateModal'
import Loader from '../../../../components/loader/Loader';
import Modal from '../../../../components/modal/Modal';
import NoData from '../../../../components/nodata/NoData';
import SearchInput from '../../../../components/searchInput/SearchInput';
import SelectInput from '../../../../components/selectMenu/SelectMenu';
import { addNotification } from '../../../../utils/addNotification';

function SessionsList(props) {
    let [sessions, setSessions] = useState(null);
    let [sessionsList, setSessionsList] = useState(null);
    let [activeSession, setActiveSession] = useState(null);
    let history = useHistory();
    let handleSessionDetails = (e) => {
        try {
            $('.modalBackground').css({ display: "flex" });
            setActiveSession(e);
            console.log(e);
        } catch (error) {
            addNotification(error);
        }
    }
    let renderSessionsList = (sessions) => {
        try {
            if (!sessions) {
                return API_OrganizationSessions().then(resp => {
                    setSessions(resp.sessions);
                    let txList = resp.sessions.map((e, i) => {
                        let endDate;
                        let startDate = new Date(e.startTransaction.createdAt);
                        if (e?.stopTransaction?.createdAt) {
                            let date = new Date(e.stopTransaction.createdAt);
                            endDate = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
                        }
                        else {
                            endDate = 'NULL';
                        }
                        let iconColor;
                        let energy = e?.meterValues.slice(-1);
                        if (energy.length > 0) {
                            energy = energy[0].find(f => { return (f.measurand === 'Energy.Active.Import.Register' || f.unit === "Wh") });
                            energy = Number(energy.value / 1000).toFixed(2) + ' kwh';
                        }
                        else energy = null;
                        let startPayload = null, stopPayload = null;
                        if (e?.startTransaction) startPayload = JSON.parse(e?.startTransaction.payload);
                        if (e?.stopTransaction) stopPayload = JSON.parse(e?.stopTransaction?.payload);
                        if (e.status === 'open') iconColor = "Active"
                        else if (e.status === 'closed' && (stopPayload?.reason === 'Remote' || stopPayload?.reason === 'Local')) iconColor = "Closed"
                        else if (e.status === 'closed' && (stopPayload?.reason !== 'Remote' || stopPayload?.reason !== 'Local')) {
                            iconColor = "Inactive";
                        }
                        else iconColor = "Inactive"
                        return (
                            <div id={e._id} key={i} className="listRow" onClick={() => handleSessionDetails(e)}>
                                <i className={"fa-solid fa-circle-dot " + iconColor}></i>
                                <p>{e.initiationDate}</p>
                                <p>{startDate.toLocaleDateString() + ' ' + startDate.toLocaleTimeString()}</p>
                                <p>{endDate}</p>
                                <p>{e.amount}</p>
                                <p>{energy}</p>
                            </div>
                        )
                    })
                    setSessionsList(txList);
                    return txList;
                }).catch(err => {
                    addNotification(err);
                });
            } else {
                let txList = sessions.map((e, i) => {
                    let endDate;
                    let startDate = new Date(e.startTransaction.createdAt);
                    if (e?.stopTransaction?.createdAt) {
                        let date = new Date(e.stopTransaction.createdAt);
                        endDate = date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
                    }
                    else {
                        endDate = 'NULL';
                    }
                    let iconColor;
                    let energy = e?.meterValues.slice(-1);
                    if (energy.length > 0) {
                        energy = energy[0].find(f => { return (f.measurand === 'Energy.Active.Import.Register' || f.unit === "Wh") });
                        energy = Number(energy.value / 1000).toFixed(2) + ' kwh';
                    }
                    else energy = null;
                    let startPayload = null, stopPayload = null;
                    if (e?.startTransaction) startPayload = JSON.parse(e?.startTransaction.payload);
                    if (e?.stopTransaction) stopPayload = JSON.parse(e?.stopTransaction?.payload);
                    if (e.status === 'open') iconColor = "Active"
                    else if (e.status === 'closed' && (stopPayload?.reason === 'Remote' || stopPayload?.reason === 'Local')) iconColor = "Closed"
                    else if (e.status === 'closed' && (stopPayload?.reason !== 'Remote' || stopPayload?.reason !== 'Local')) {
                        iconColor = "Inactive";
                    }
                    else iconColor = "Inactive"
                    return (
                        <div id={e._id} key={i} className="listRow" onClick={() => handleSessionDetails(e)}>
                            <i className={"fa-solid fa-circle-dot " + iconColor}></i>
                            <p>{e.initiationDate}</p>
                            <p>{startDate.toLocaleDateString() + ' ' + startDate.toLocaleTimeString()}</p>
                            <p>{endDate}</p>
                            <p>{e.amount}</p>
                            <p>{energy}</p>
                        </div>
                    )
                })
                setSessionsList(txList);
                return txList;
            }
        } catch (error) {
            addNotification(error);
        }
    }
    let searchFilter = (item, query) => {
        try {
            let col1 = item?.initiationDate?.toString().includes(query.toLowerCase())
            let col2 = new Date(item?.startTransaction?.createdAt).toLocaleDateString().toLowerCase().includes(query.toLowerCase())
            let col3 = new Date(item.stopTransaction?.createdAt).toLocaleDateString().toLowerCase().includes(query.toLowerCase())
            let col4 = item.amount?.toString().includes(query.toLowerCase())
            return (col1 || col2 || col3 || col4)
        } catch (error) {
            addNotification(error);
        }
    }
    let handleListView = () => {
        try {
            if (sessionsList) {
                if (sessionsList.length > 0) {
                    return (
                        <div className="listTable">
                            <div className="listTableHeader">
                                <i className=""></i>
                                <p>TransactionId</p>
                                <p>Start Date</p>
                                <p>Stop Date</p>
                                <p>Amount</p>
                                <p>Energy Delivered</p>
                            </div>
                            <div className="listTableRows">
                                {sessionsList}
                            </div>
                        </div>
                    )
                }
                else {
                    return (
                        <NoData />
                    )
                }
            }
            else {
                return (
                    <Loader />
                )
            }
        } catch (error) {
            addNotification(error);
        }
    }
    useEffect(() => {
        try {
            renderSessionsList();
        } catch (error) {
            addNotification(error);
        }
    }, [])
    return (
        <div id="divSettings" className="accountContainer">
            <div id="divManageSessions">
                <div className="sectionHeader">
                    <div style={{ width: "80%" }}>
                        <h1>Manage Sessions</h1>
                        <SearchInput id={"sessionSearch"} placeholder={"Search Sessions"} filter={searchFilter} data={sessions} searchResult={e => { renderSessionsList(e) }} />
                    </div>
                </div>
                <div id="divSessionContainer" className="sectionContent">
                    {handleListView()}
                </div>
            </div>
            <Modal type="SessionDetails" data={activeSession} />
        </div>
    )
}

export default SessionsList;