import "../../../../../node_modules/react-grid-layout/css/styles.css"
import "../../../../../node_modules/react-resizable/css/styles.css"

import RGL, { WidthProvider } from 'react-grid-layout';
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { useEffect, useRef, useState } from "react"

import Analytics from "./Analytics";
import ControlPanel from "./ControlPanel";
import FormulaBuilder from "./FormulaBuilder";
import _ from "lodash";

const GridLayout = WidthProvider(RGL);

function Dashboard(props) {
    return (
        <div id="divDashboard" className="accountContainer">
            <Switch>
                <Route exact path={props.match.url + "/"} component={Analytics} />
                <Route path={props.match.url + "/analytics"} component={Analytics} />
            </Switch>
        </div>
    )
}

export default Dashboard