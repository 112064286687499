import { addNotification } from "../utils/addNotification";

export async function API_GetOrganization(id) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/org/get/' + id;
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetOrganizationAccounts(obj) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/org/get/accounts';
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(obj),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetOrganizationMetrics(obj) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/organization/getOrganiztionStatistics';
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(obj),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_GetRevenueBreakDownByProject(obj) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/organization/getOrganizationRevenueBreakdown';
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(obj),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_MonthByMonthRevenue(obj) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/organization/getOrganizationMonthByMonthRevenue';
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(obj),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_MonthByMonthSessions(obj) {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/analytics/chargingSessions/organization/getOrganizationMonthByMonthSessions';
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(obj),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}

export async function API_OrganizationSessions() {
    try {
        let url = process.env.REACT_APP_SERVER_API_URL + '/org/get/sessions';
        let body = {orgId: JSON.parse(localStorage.getItem('organizationId'))}
        let resp = await fetch(url, {
            mode: 'cors',
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Accept: "application/json",
                Authorization: 'Bearer ' + localStorage.getItem('auth')
            },
            body: JSON.stringify(body),
        })
            .then(res => res.json()).then(resp => {
                try {
                    if ((resp.status === 401 || resp.status === 403) && resp.isJWT) {
                        localStorage.clear();
                        window.location.replace('/');
                    }
                    return resp
                } catch (error) {
                    addNotification(error);
                }
            })
            .catch((err) => {
                addNotification(err);
            })
        return resp;
    } catch (error) {
        addNotification(error);
    }
}