import { useEffect, useState } from "react";

import $ from 'jquery'
import { API_GetAllProjects } from "../../api/project";
import AddressSearchBar from "../mapConfigInput/AddressSearchBar";
import DynamicSelect from "../DynamicSelect";
import SessionMeterValueChart from "../charts/sessions/SessionMeterValues";
import { addNotification } from "../../utils/addNotification";

function Modal(props) {
    try {
        let renderForm = () => {
            try {
                if (props.type === 'SessionDetails' && props.data) {

                    let startDate = new Date(props.data?.startTransaction?.createdAt);
                    let endDate = new Date(props.data?.stopTransaction?.createdAt);
                    let duration = endDate - startDate;
                    let seconds = Math.floor(duration / 1000);
                    let minutes = Math.floor(seconds / 60);
                    seconds %= 60;
                    let hours = Math.floor(minutes / 60);
                    minutes %= 60;

                    duration = (hours < 10? "0" + hours:hours)  + ":" + (minutes < 10? "0" + minutes:minutes) + ":" + (seconds < 10? "0" + seconds:seconds);
                    let energy = props.data?.meterValues.slice(-1);
                    if (energy.length > 0) {
                        energy = energy[0].find(f => { return (f.measurand === 'Energy.Active.Import.Register' || f.unit === "Wh") });
                        energy = Number(energy.value / 1000).toFixed(2) + ' kwh';
                    }
                    else energy = null;
                    let startPayload = null, stopPayload = null;
                    if (props.data?.startTransaction) startPayload = JSON.parse(props.data?.startTransaction.payload);
                    if (props.data?.stopTransaction) stopPayload = JSON.parse(props.data?.stopTransaction?.payload);
                    let startTime = new Date(props.data?.initiationDate * 1000).toLocaleString();
                    let tariffNumber = props.data?.chargingStation?.pricingPolicy?.price;
                    let amount = '₹' + Math.round((props.data?.amount + Number.EPSILON) * 100) / 100;
                    let paymentStatus = 'Success';
                    let paymentMethod = 'Wavefuel Wallet';
                    return (
                        <div>
                            <div className="modalHeader">
                                <i onClick={() => { $('.modalBackground').hide() }} className="fas fa-times"></i>
                                <h1 style={{
                                    fontSize: "20px",
                                    color: "#c2c2c2"
                                }}>Session Modal</h1>
                            </div>
                            <div className="modalContent" style={{ height: '65vh', margin: '20px 0px' }}>
                                <div id="stationInvoice" className='stationInvoiceContainer'>
                                    <div className='invoiceSection'>
                                        <h1>Session Details</h1>
                                        <div className='sectionDetails'>
                                            <div id="sessionDuration">
                                                <p>Duration</p>
                                                <div className='divider'></div>
                                                <p className='val'>{duration}</p>
                                            </div>
                                            <div id="sessionEnergy">
                                                <p>Energy Delivered</p>
                                                <div className='divider'></div>
                                                <p className='val'>{energy}</p>
                                            </div>
                                            <div id="sessionStartTime">
                                                <p>Session Start Time</p>
                                                <div className='divider'></div>
                                                <p className='val'>{startTime}</p>
                                            </div>
                                            <div id="sessionStartTime">
                                                <p>Session Stop Reason</p>
                                                <div className='divider'></div>
                                                <p className='val'>{stopPayload?.reason}</p>
                                            </div>
                                            <div id="sessionTariff">
                                                <p>Session Tariff (per kWh)</p>
                                                <div className='divider'></div>
                                                <p className='val'>{tariffNumber}</p>
                                            </div>
                                            <div id="transactionId">
                                                <p>Transaction Id</p>
                                                <div className='divider'></div>
                                                <p className='val'>{props.data?.initiationDate}</p>
                                            </div>
                                            <div id="idTag">
                                                <p>Id Tag</p>
                                                <div className='divider'></div>
                                                <p className='val'>{props.data?.idTag}</p>
                                            </div>
                                            <div id="connectorNumber">
                                                <p>ConnectorNumber</p>
                                                <div className='divider'></div>
                                                <p className='val'>{props.data?.connectorId}</p>
                                            </div>
                                            <div id="status">
                                                <p>Status</p>
                                                <div className='divider'></div>
                                                <p className='val'>{props.data?.status}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='invoiceSection'>
                                        <h1>Payment Details</h1>
                                        <div className='sectionDetails'>
                                            <div id="invoiceAmount">
                                                <p>Total Amount</p>
                                                <div className='divider'></div>
                                                <p className='val'>{amount}</p>
                                            </div>
                                            <div id="invoicePaymentStatus">
                                                <p>Payment Status</p>
                                                <div className='divider'></div>
                                                <p className='val'>{paymentStatus}</p>
                                            </div>
                                            <div id="invoicePaymentMethod">
                                                <p>Payment Method</p>
                                                <div className='divider'></div>
                                                <p className='val'>{paymentMethod}</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div id="parentLineAreaChart" className="analytic" style={{ width: "465px", height: "250px", border: "2px solid #e5e5e5", padding: "15px", borderRadius: "5px" }}>
                                    <SessionMeterValueChart session={props.data} />
                                </div>
                            </div>
                        </div>
                    )
                }
                else {
                    return null
                }
            } catch (error) {
                addNotification(error);
            }
        }
        useEffect(() => {
            try {
                $('.modalParentInput select').on('change', function (e) {
                    try {
                        var optionSelected = $("option:selected", this);
                        var valueSelected = this.value;
                        if ($('.' + valueSelected).length > 0) {
                            $('.' + valueSelected).show();
                            $('.' + valueSelected).animate({ height: "300px", opacity: 1 }, () => {
                                $('.' + valueSelected + ' .formInputs').show();
                            })
                        }
                        else {
                            $('.modalChildInputs').animate({ height: "0px", opacity: 0 }, () => {
                                $('.modalChildInputs .formInputs').hide();
                                $('.modalChildInputs').hide();
                            })
                        }
                    } catch (error) {
                        addNotification(error);
                    }
                });
            } catch (error) {
                addNotification(error);
            }
        })
        return (
            <div className="modalBackground">
                <div className="createModal">
                    {renderForm()}
                </div>
            </div>
        )
    } catch (error) {
        addNotification(error);
    }
}

export default Modal