import './SelectMenu.css';

import { useEffect, useState } from 'react';

import $ from 'jquery';
import { addNotification } from '../../utils/addNotification'

function SelectInput(props) {

    const [filteredList, setFilteredList] = useState(null);
    const filterBySearch = (name) => {
        try {
            // Access input value
            const query = name;
            // Create copy of item list
            var updatedList = [...props.data];
            // Include all elements which includes the search query
            updatedList = updatedList.filter((item) => {
                return (item.deviceName.toLowerCase().includes(query.toLowerCase()) || item.deviceType.toLowerCase().includes(query.toLowerCase()) || item.providerName.toLowerCase().includes(query.toLowerCase()) || item.deviceStatus.toLowerCase().includes(query.toLowerCase()))
            });
            // Trigger render with updated values
            setFilteredList(updatedList);
            props.searchResult(updatedList);
        } catch (error) {
            addNotification(error);
        }
    };

    let handleOptionClick = (option) => {
        try {
            $('#selected-value span').html(option.name);
            $('#options').addClass('hide');
            $('.option').removeClass('selected')
            $(option).addClass('selected');
            filterBySearch(option.val)
        } catch (error) {
            addNotification(error);
        }
    }

    let optionsList = props.optionsList.map((e, i) => {
        if (!e.disabled) {
            return (
                <div className="option" key={i} onClick={() => handleOptionClick(e)}>
                    <input className="s-c top" type="radio" name="platform" value={e.val} />
                    <i className={e.iconClass}></i>
                    <span className="label">{e.name}</span>
                </div>
            )
        }
        else {
            return (
                <div className="option inactiveFilterItem" key={i}>
                    <input className="s-c top" type="radio" name="platform" value={e.val} />
                    <i className={e.iconClass}></i>
                    <span className="label">{e.name}</span>
                </div>
            )
        }
    })

    useEffect(() => {
        try {
            setFilteredList(props.data);
        } catch (error) {
            addNotification(error);
        }
    }, [props.data])
    return (
        <form id="app-cover">
            <div id="select-box">
                <div id="select-button" className="brd" onClick={() => { $('#options').toggleClass('hide'); }}>
                    <div id="selected-value">
                        <span>{props.defaultValue}</span>
                    </div>
                    <div id="chevrons">
                        <i className="fas fa-chevron-up"></i>
                        <i className="fas fa-chevron-down"></i>
                    </div>
                </div>
                <div id="options" className="hide">
                    {optionsList}
                    <div id="option-bg"></div>
                </div>
            </div>
        </form>
    )
}

export default SelectInput