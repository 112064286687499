import { Route, Switch, useHistory } from "react-router-dom";

import Landing from './containers/Landing'
import Signup from './containers/Signup'
import { addNotification } from "../../utils/addNotification";
import { useEffect } from "react";
function Public(props) {
    let history = useHistory();
    useEffect(() => {
        try {
            if (localStorage.getItem("auth") && localStorage.getItem("organizationId") && localStorage.getItem("profile") && localStorage.getItem("accountId")) {
                history.push('/client/account/' + localStorage.getItem("accountId"), { replace: true });
            }
            else {
                localStorage.clear();
            }
            console.log(props);
        } catch (error) {
            console.log(error);
        }
    }, [])
    return (
        <div className="publicParentContainer">
            <Switch>
                <Route exact path={props.match.path + "/"} component={Landing} match={props.match} />
                {/* <Route path={props.match.path + "/signup"} component={Signup} /> */}
            </Switch>
        </div>
    );
}

export default Public