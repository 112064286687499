import { Link, Route, Switch, useHistory } from 'react-router-dom'

import $ from 'jquery';

function Login(props) {
    return(
        <div className="slideForm loginForm">
          <h1>Welcome Back</h1>
          <p>Enter your credentials to access your custom dashboards and gain key insights about your organization.</p>
          <form id="formLoginInputs" style={{ marginTop: "35px" }}>
            <div className="formInputs">
              <label id="label_login_organizationName" htmlFor="orgId">Organization Id</label>
              <input id="orgId" type="text" name="organizationName" placeholder="eg: 12-567" data-error="Invalid Organization Id" data-norm="OrganizaitonId" data-401="Organization Id Does Not Exist" data-402='Try Again' />
            </div>
            <div className="formInputs">
              <label id="label_login_email" htmlFor="email">Email</label>
              <input id="email" type="email" name="email" placeholder="eg: example@example.com" data-error="Invalid Email" data-norm="Email" data-401="Email Does Not Exist" data-402='Try Again' />
            </div>
            <div className="formInputs">
              <label id="label_login_password" htmlFor="password">Password</label>
              <input id="password" type="password" name="password" placeholder="eg: exampleA1" data-error="Invalid Password" data-norm="Password" data-401="Try Again" data-402='Password Does Not Match the Username.' />
            </div>
            <div className="formButtons">
              <div>
                <button id="btnLoginSubmit" type="button" className="primaryButton" onClick={() => {props.submit()}}>Login</button>
                <button id="btnSignupRedirect" type="button" className="secondaryButton" onClick={() => {props.setForm('inactive')}}>Cancel</button>
                {/* onClick={() => {props.setForm('inactive')}} */}
              </div>
              <div><p>Dont have an Account?</p> <span onClick={() => {props.setForm('Signup')}}>Signup Here.</span></div>
            </div>
          </form>
        </div>
    )
}

export default Login