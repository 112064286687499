import Ajv from 'ajv';
import { JSONSchemaBridge } from 'uniforms-bridge-json-schema';
import { addNotification } from '../../../utils/addNotification';

function chargingStationSchema(scm) {
  const schema = (scm) => {
    try {return {
      title: 'ChargingStation',
      type: 'object',
      properties: {
        _id: { type: 'string', pattern: '^[0-9a-fA-F]{24}$', readOnly: true },
        statusNotification: { type: 'string', enum: ['Available', 'Preparing', 'Charging', 'SuspendedEVSE', 'SuspendedEV', 'Finishing', 'Reserved', 'Unavailable', 'Faulted'], default: 'Unavailable' },
        boxSerialNumber: { type: 'string', readOnly: true },
        communicationProtocol: { type: 'string', enum: ['OCPP1.6', 'OCPP2.0', 'OCPP2.1', 'MQTT'] },
        firmwareVersion: { type: 'string' },
        locationCategory: { type: 'string', enum: ['Office', 'Muncipal', 'Parking', 'Retail', 'Residential'] },
        accessCategory: { type: 'string', enum: ['Private', 'Public'] },
        serviceType: { type: 'string', enum: ['Self-Service'] },
        price: { type: 'number' },
        priceUnit: { type: 'string', enum: ['INR'] },
        inputVoltage: { type: 'number' },
        outputType: { type: 'string', enum: ['ac', 'dc'] },
        outputWattCategory: { type: 'string', enum: ['3.3', '7.2', '15', '22', '30', '60'] },
        activeTransaction: { type: 'string', pattern: '^[0-9a-fA-F]{24}$', readOnly: true },
        activeSession: { type: 'string', pattern: '^[0-9a-fA-F]{24}$', readOnly: true },
      },
      required: ['boxSerialNumber']
    }} catch (error) {
      addNotification(error);
  }
  };

  let _schema = schema(scm);
  let propKeys = Object.keys(_schema.properties);
  propKeys.forEach(e => {
    if (e === 'price' && scm.pricingPolicy && scm.pricingPolicy['price']) {
      _schema.properties[e].default = scm.pricingPolicy['price'];
    }
    else if (e === 'priceUnit' && scm.pricingPolicy && scm.pricingPolicy['unit']) {
      _schema.properties[e].default = scm.pricingPolicy['unit'];
    }
    else {
      _schema.properties[e].default = scm[e];
    }
  })
  const ajv = new Ajv({ allErrors: true, useDefaults: true });

  function createValidator() {
    try {const validator = ajv.compile(_schema);

    return (model) => {
      validator(model);
      return validator.errors?.length ? { details: validator.errors } : null;
    };} catch (error) {
      addNotification(error);
  }
  }

  const schemaValidator = createValidator(_schema);
  return new JSONSchemaBridge(_schema, schemaValidator);
}

export { chargingStationSchema };