import { API_GetDevice, API_UpdateDevice } from "../../../../api/device";
import { useEffect, useState } from "react";

import $ from 'jquery'
import AccessController from "./chargingstations/AccessController";
import DeviceConfig from './DeviceConfig'
import DeviceDashboard from "./DeviceDashboard";
import FailureFormula from './FailureFormula'
import Loader from "../../../../components/loader/Loader";
import RemoteController from "./chargingstations/RemoteController";
import UnderConstruction from "../../../../components/UnderConstruction/UnderConstruction";
import { useHistory } from "react-router";
import { addNotification } from "../../../../utils/addNotification";
import { API_LighthouseAccount, API_SpotlightAccount } from "../../../../api/account";

function ManageDevice(props) {
    let history = useHistory();
    let [contentState, setContentState] = useState(null)
    let [editable, setEditable] = useState(false)
    let [device, setDevice] = useState(history.location.state)
    let [asset, setAsset] = useState(null)
    let [project, setProject] = useState(null)

    let handleEditButton = () => {
        try {
            $('#editDeviceDetails').animate({ width: 0, opacity: 0 }, () => {
                $('#editDeviceDetails').hide();
                $('#divEditButtons').show().animate({ opacity: 1 });
                $('#divEditButtons button').animate({ width: "75px" })
            });
            setEditable(true);
            $('.deviceContentContainer input').prop('disabled', false).removeClass("disabledInput");
            $('.deviceContentContainer textArea').prop('disabled', false).removeClass("disabledInput")
            $('.deviceContentContainer select').prop('disabled', false).removeClass("disabledInput")
            $('.createButtons .primaryButton').prop('disabled', false).removeClass("disabledButton")
        } catch (error) {
            addNotification(error)
        }
    }

    let handleSaveButton = async () => {
        try {
            let updatedDevice = [];
            $('.deviceContentContainer input').each(function (e) {
                updatedDevice.push({ 'name': $(this).attr("name"), 'value': $(this).val() });
            });
            $('.deviceContentContainer textArea').each(function (e) {
                updatedDevice.push({ 'name': $(this).attr("name"), 'value': $(this).val() });
            });
            $('.deviceContentContainer select').each(function (e) {
                updatedDevice.push({ 'name': $(this).attr("name"), 'value': $(this).val() });
            });
            var formJson = {};
            for (var i = 0; i < updatedDevice.length; i++) {
                formJson[updatedDevice[i].name] = updatedDevice[i].value;
            }
            delete formJson._id;
            delete formJson.createdAt;
            delete formJson.updatedAt;
            let keys = Object.keys(formJson);
            keys.forEach(e => {
                if (formJson[e] === "" || formJson[e] === null) {
                    delete formJson[e];
                }
            })
            if (formJson["lat"] || formJson["lon"])
                formJson.location = {
                    lat: formJson["lat"],
                    lon: formJson["lon"],
                }
            if (formJson["price"] || formJson["priceUnit"])
                formJson.pricingPolicy = {
                    price: formJson["price"],
                    unit: formJson["priceUnit"],
                }
            let d = await API_UpdateDevice(device._id, formJson)
            let previousMenuState = contentState
            setDevice(d.device)
            setAsset(d.device.assetId)
            if (d.project) {
                setProject(d.project)
            }
            setContentState(previousMenuState)
            handleCancelButton()
        } catch (error) {
            addNotification(error)
        }
    }

    let handleCancelButton = () => {
        try {
            $('#divEditButtons').animate({ opacity: 0 }, () => {
                $('#divEditButtons').hide();
                $('#editDeviceDetails').show().animate({ opacity: 1, width: "150px" });
            });
            setEditable(false);
            $('.deviceContentContainer input').prop('disabled', true).addClass("disabledInput");
            $('.deviceContentContainer textArea').prop('disabled', true).addClass("disabledInput")
            $('.deviceContentContainer select').prop('disabled', true).addClass("disabledInput")
            $('.createButtons .primaryButton').prop('disabled', true).addClass("disabledButton")
        } catch (error) {
            addNotification(error)
        }
    }

    let deviceContent = () => {
        try {
            if (contentState === 'DeviceConfig' || contentState === 'AccessController') {
                $('#editDeviceDetails').show()
            }
            else {
                $('#editDeviceDetails').hide()
            }
            if (device.deviceType === 'ChargingStation') {
                if (contentState === 'DeviceConfig') {
                    return (
                        <DeviceConfig device={device} edit={editable} asset={asset} setAsset={setAsset} />
                    )
                }
                else if (contentState === 'FailureFormula') {
                    return (
                        <FailureFormula device={device} edit={editable} asset={asset} />
                    )
                }
                else if (contentState === 'AccessController') {
                    return (
                        <AccessController device={device} edit={editable} asset={asset} setAsset={setAsset} />
                    )
                }
                else if (contentState === 'Dashboard') {
                    return (
                        <DeviceDashboard device={device} edit={editable} asset={asset} />
                    )
                }
                else if (contentState === 'RemoteController') {
                    return (
                        <RemoteController device={device} edit={editable} asset={asset} />
                    )
                }
                else {
                    return (
                        <div className="loaderParent">
                            <Loader />
                        </div>
                    )
                }
            }
            else {
                return (
                    <UnderConstruction />
                )
            }

        } catch (error) {
            addNotification(error)
        }
    }
    let handleRefreshButton = () => {
        try {
            let previousMenuState = contentState;
            setContentState('Loading');
            API_GetDevice(history.location.state._id).then(d => {
                setDevice(d.device);
                if (d.asset) {
                    setAsset(d.device.assetId);
                }
                if (d.project) {
                    setProject(d.project);
                }
                setContentState(previousMenuState);
            }).catch(err => {
                addNotification(err);
            });
        } catch (error) {
            addNotification(error)
        }
    }
    let handleProjectNav = (project) => {
        try {
            $('#divAccountNavBar h1').removeClass('activeNavItem');
            $('#h1Nav_Projects').addClass('activeNavItem');
            history.push({
                pathname: props.match.url.replace('/devices/' + device._id, '') + '/projects/' + project._id,
                state: project,
            })
        }
        catch (err) {
            addNotification(err);
        }
    }
    useEffect(() => {
        try {
            $('.deviceMenu div').hover(
                (e) => {
                    $('#' + e.currentTarget.id).addClass('activeMenuItem_Hover')
                },
                (e) => {
                    $('#' + e.currentTarget.id).removeClass('activeMenuItem_Hover')
                });
            $('.deviceMenu div').on('click', (e) => {
                try {
                    setContentState(e.currentTarget.dataset.menuState);
                    $('.deviceMenu div').removeClass('activeMenuItem_Click');
                    $('#' + e.currentTarget.id).addClass('activeMenuItem_Click');
                    handleCancelButton();
                } catch (error) {
                    addNotification(error);
                }
            });
            API_GetDevice(history.location.state._id).then((d) => {
                setDevice(d.device);
                if (d.device.assetId) {
                    setAsset(d.device.assetId);
                    setProject(d.device.projectId);
                }
                setContentState("Dashboard");
            }).catch(err => {
                addNotification(err);
            });
        } catch (error) {
            addNotification(error)
        }
    }, [])
    return (
        <div id="divDeviceContainer" className="deviceContainer" >
            <div className="deviceHeader">
                <div className="manageDeviceHeader">
                    <h1>{device.deviceName}</h1>
                    <p className={device.deviceStatus}><i style={{ marginRight: "7px" }} className="fa-solid fa-circle-dot"></i>{device.deviceStatus}</p>
                </div>
                <div id="divManageButtons" className="manageDeviceButtons">
                    <button id="refreshDeviceDetails" className="secondaryButton" onClick={() => { handleRefreshButton() }}><i className="fa-solid fa-arrows-rotate"></i></button>
                    <button id="projectDetails" className="secondaryButton" onClick={() => { handleProjectNav(project) }}><i className="fa-solid fa-location-crosshairs"></i></button>
                    <button id="editDeviceDetails" className="primaryButton" onClick={() => { handleEditButton() }}>Edit Device</button>
                    <div id="divEditButtons" className="editButtons">
                        <button id="saveDeviceDetails" style={{ width: "0%" }} className="primaryButton" onClick={() => { handleSaveButton() }}><i className="fas fa-save"></i></button>
                        <button id="cancelDeviceDetails" style={{ width: "0%" }} className="secondaryButton" onClick={() => { handleCancelButton() }}><i className="fas fa-times"></i></button>
                    </div>
                </div>
            </div>
            <div className="deviceMenu">
                <div id="deviceMenuTestAlgo" className="activeMenuItem_Click" data-menu-state="Dashboard">
                    <h1>Dashboard</h1>
                    <hr></hr>
                </div>
                <div id="deviceMenuConfig" data-menu-state="DeviceConfig">
                    <h1>Device Configuration</h1>
                    <hr></hr>
                </div>
                <div id="deviceAccessMenuReportViewer" data-menu-state="AccessController">
                    <h1>Access Controller</h1>
                    <hr></hr>
                </div>
                <div id="deviceRemoteMenuReportViewer" data-menu-state="RemoteController">
                    <h1>Remote Controller</h1>
                    <hr></hr>
                </div>
                {/* <div id="deviceMenuAlertConfig">
                    <h1>QA/QC Checks</h1>
                    <hr></hr>
                </div> */}
            </div>
            <div className="deviceContentContainer">
                {deviceContent()}
            </div>
        </div>
    )
}

export default ManageDevice;