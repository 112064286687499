import { Link, useHistory } from 'react-router-dom'

function PublicLogo() {
    let history = useHistory();
    let goToHome = () => {
        history.push('/');
    }
    return (
        <div id="divLogo" className="publicLogo">
            <h1 onClick={()=> {goToHome()}}>Lighthouse</h1>
        </div>
    )

}

export default PublicLogo