import "react-datepicker/dist/react-datepicker.css";

import { useHistory, useLocation } from "react-router-dom";

import DatePicker from 'react-datepicker';
import { useState } from 'react';
import { addNotification } from "../../utils/addNotification";

function OnBoradingUser(props) {
    let history = useHistory();
    const [startDate, setStartDate] = useState(new Date());
    let goToCreateDevice = () => {
        try {
            history.push({
                pathname: props.match.url + '/devices/'
            });
        } catch (error) {
            addNotification(error);
        }
    }
    return (
        <div id="onBoardingParent" className="onBoardingContainer">
            <div id="onBoardingText" className="onBoardingText">
                <h1>Welcome To Lighthouse</h1>
                <p>It looks like your a <span style={{ textDecoration: "underline" }}>new user</span>, before you explore the application lets setup some basic information about you, and add some devices to begin generating metrics</p>
            </div>
            <form id="profileUpdateForm" className="onBoardingForm">
                <h1>Tell us a little about yourself</h1>
                <div>
                    <label id="label_firstName" for="firstName">First Name</label>
                    <input id="firstName" name="firstName" type="text" data-error="First Name is a Required Field" data-norm="First Name" />
                </div>
                <div>
                    <label id="label_lastName" for="lastName">Last Name</label>
                    <input id="lastName" name="lastName" type="text" data-error="Last Name is a Required Field" data-norm="Last Name" />
                </div>
                <div>
                    <label id="label_dob" for="dob">Date of Birth</label>
                    <DatePicker id="dob" name="dob" selected={startDate} onChange={date => setStartDate(date)} maxDate={new Date()} />
                </div>
                <div>
                    <label id="label_jobTitle" for="jobTitle">Job Title</label>
                    <select id="jobTitle" name="jobTitle" style={{ width: "100%" }} data-error="Job Title is a Required Field" data-norm="Job Title">
                        <option value="None">Choose a Job Title</option>
                        <option value="QAQC">QA/QC</option>
                        <option value="Engineer">Engineer</option>
                        <option value="Management">Management</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
            </form>
            <div className="onBoardingNext">
                <h1>Get Started</h1>
                <div className="onBoardingNextStep" onClick={() => { goToCreateDevice() }}>
                    <div></div>
                    <p>Create a Device</p>
                </div>
                <div className="onBoardingNextStep">
                    <div></div>
                    <p>Customize your Analytics</p>
                </div>
            </div>
            <button onClick={() => props.handleProfileUpdate()}>Complete Setup</button>
        </div>
    )
}

export default OnBoradingUser