import { addStyles, EditableMathField, StaticMathField } from 'react-mathquill'
import { useState, useEffect } from 'react'
import $ from 'jquery';
function FailureFormula(props) {
    addStyles()
    const [latex, setLatex] = useState(props.device.formula);
    let [editState, setEditState] = useState(props.edit)
    useEffect(() => {
        if(editState == false) {
            $('.deviceFormulaContainer input').prop('disabled', true).addClass("disabledInput");
            $('.deviceFormulaContainer textArea').prop('disabled', true).addClass("disabledInput");
        }
        else {
            $('.deviceFormulaContainer input').prop('disabled', false).removeClass("disabledInput");
            $('.deviceFormulaContainer textArea').prop('disabled', false).removeClass("disabledInput")
        }
    }, [editState])
    return (
        <div className="deviceFormulaContainer">
            <div className="formulaPrimaryDetails">
                <div style={{ gridColumnStart: 1, gridColumnEnd: 2, gridRow: 1 }}>
                    <label>Formula</label>
                    <input type="text" defaultValue={latex} name="formula" onChange={(e) => {setLatex(e.target.value);}}/>
                </div>
                <StaticMathField style={{ gridColumnStart: 1, gridColumnEnd: 2, gridRow: 2 }}>{latex}</StaticMathField>
                <div style={{ gridColumnStart: 1, gridColumnEnd: 2, gridRow: 3 }}>
                    <div className="formulaVariableDefinition">
                        <p>Variable</p>
                        <input />
                        <p>refers to</p>
                        <input />
                        <p>and is correlated to</p>
                        <input />
                    </div>
                    <div className="formulaVariableDefinition">
                        <p>Variable</p>
                        <input />
                        <p>refers to</p>
                        <input />
                        <p>and is correlated to</p>
                        <input />
                    </div>
                    <div className="formulaVariableDefinition">
                        <p>Variable</p>
                        <input />
                        <p>refers to</p>
                        <input />
                        <p>and is correlated to</p>
                        <input />
                    </div>
                </div>
                <button style={{ gridColumnStart: 1, gridColumnEnd: 2, gridRow: 4 }}> + </button>
            </div>
            <div className="formulaSecondaryDetails">
                <div style={{ gridColumnStart: 1, gridColumnEnd: 2, gridRowStart: 1, gridRowEnd: 2 }}>
                    <label>Formula Description</label>
                    <textarea />
                </div>
                <div className="formulaReferences" style={{ gridColumnStart: 1, gridColumnEnd: 2, gridRowStart: 2, gridRowEnd: 3 }}>
                    <label>References</label>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    )
}
export default FailureFormula